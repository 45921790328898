import { Operator, mutate, run } from "overmind";
import { message } from "antd";
import Cookies from "js-cookie";
import { ToastType } from "helpers/types";

// General
export const setIsLoading: (bool: boolean) => Operator = (bool) =>
    mutate(function setIsLoading({ state }) {
        state.isLoading = bool;
    });

export const setIsLoading2: () => Operator<boolean> = () =>
    mutate(function setIsLoading2({ state }, bool) {
        state.isLoading2 = bool;
    });

export const setIsFetching: () => Operator<boolean> = () =>
    mutate(function setIsFetching({ state }, bool) {
        state.isFetching = bool;
    });

export const setTokenInCookies: () => Operator = () =>
    run(function setTokenInCookies({ state }) {
        Cookies.set("digitalcse", state.user.token);
    });

export const getAuthedUser: () => Operator<any> = () =>
    mutate(async function getAuthedUser({ effects, state }, token) {
        state.user = await effects.get(`auth/${token}`);
    });

export const clearCookies: () => Operator = () =>
    run(function clearCookies() {
        Cookies.remove("digitalcse");
    });

// Access
export const lookup: () => Operator<any> = () =>
    run(async function lookup({ effects }, data) {
        await effects.post({ url: "lookup", data });
    });

export const askNewPassword: () => Operator<any> = () =>
    run(async function askNewPassword({ effects }, data) {
        await effects.post({ url: "ask-new-password", data });
    });

export const login: () => Operator<any> = () =>
    mutate(async function login({ effects, state }, data) {
        const user = await effects.post({ url: "login", data });

        state.user = !user
            ? {
                  _id: "",
                  email: "",
                  firstname: "",
                  lastname: "",
                  role: "",
                  token: "",
              }
            : user;
    });

export const logout: () => Operator = () =>
    mutate(function login({ state }) {
        state.user = {
            _id: "",
            email: "",
            firstname: "",
            lastname: "",
            role: "",
            token: "",
        };
    });

// Settings
export const getSettings: () => Operator = () =>
    mutate(async function getSettings({ state, effects }) {
        state.settings = await effects.get("settings");
    });

export const getBasicSettings: () => Operator = () =>
    mutate(async function getBasicSettings({ state, effects }) {
        const res = await effects.get("basic-settings");
        state.settings = {
            ...state.settings,
            general: {
                appName: res.appName,
                backgroundImage: res.backgroundImage,
                contact: {
                    address: "",
                    availabilities: "",
                    email: res.email,
                    phoneNumber: "",
                },
                limitations: {},
                logo: res.logo,
                primaryColor: res.primaryColor,
                secondaryColor: res.secondaryColor,
                status: res.status,
            },
        };
    });

export const updateSettings: () => Operator<any> = () =>
    mutate(async function updateSettings({ state, effects }, data) {
        state.settings = await effects.put({ url: "settings/", data });
    });

// Toast
export const toast: ({ status, text }: ToastType) => Operator<any> = ({ status, text }) =>
    run(function toast(_, defaultText) {
        message[status](text || defaultText || defaultText.error);
    });

export const destroyToast: () => Operator = () =>
    run(function toast(_, defaultText) {
        message.destroy();
    });
