import React from "react";
import { Form } from "antd";

const OZForm = ({ children, form, initialValues, onSubmit }: any) => {
    const onSubmitFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Form
            form={form}
            hideRequiredMark
            initialValues={initialValues}
            layout="vertical"
            name="digitalcse"
            onFinish={onSubmit}
            onFinishFailed={onSubmitFailed}
        >
            {children}
        </Form>
    );
};

export default OZForm;
