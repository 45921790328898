import React, { useEffect, useState } from "react";
import { Avatar, Button, Card, List, Input, Popover, Row, Col } from "antd";
import {
    PhoneOutlined,
    MessageOutlined,
    SearchOutlined,
    CloseCircleOutlined,
    CrownTwoTone,
    CrownOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { useOvermind } from "store";
import { Link, useHistory } from "react-router-dom";

import OZAvatar from "components/OZAvatar";

const Users = () => {
    const { actions, state } = useOvermind();
    const history = useHistory();

    const { list } = state.users;
    const { isLoading } = state;

    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({ firstName: "", lastName: "" });
    const [results, setResults] = useState<any>([]);

    useEffect(() => {
        actions.users.getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setResults(list.filter((u) => u.isVisible.profile));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.length > 0]);

    const search = ({ firstName, lastName }: any) => {
        let newItems = [...list.filter((u) => u.isVisible.profile)];

        if (firstName) {
            newItems = newItems.filter((i: any) =>
                i.firstName.toLowerCase().includes(firstName.toLowerCase())
            );
        }

        if (lastName) {
            newItems = newItems.filter((i: any) =>
                i.lastName.toLowerCase().includes(lastName.toLowerCase())
            );
        }

        setResults(newItems);
        setCurrentPage(1);
    };

    const header = (
        <Row align="middle" gutter={[8, 8]} justify="space-around">
            <Col xs={24} sm={24} md={7} lg={4} xl={4} xxl={4}>
                {results.length} utilisateurs trouvés
            </Col>
            <Col xs={24} sm={24} md={6} lg={8} xl={8} xxl={8}>
                <Input
                    onChange={(e: any) => {
                        setFilters({ ...filters, firstName: e.target.value });
                    }}
                    placeholder="Prénom"
                    value={filters.firstName}
                    style={{ width: "100%" }}
                    suffix={
                        filters.firstName && (
                            <CloseCircleOutlined
                                onClick={(e: any) => {
                                    setFilters({ ...filters, firstName: e.target.value });
                                    search({
                                        firstName: "",
                                        lastName: filters.lastName,
                                    });
                                }}
                                style={{ color: "rgba(0,0,0,.45)" }}
                            />
                        )
                    }
                />
            </Col>
            <Col xs={24} sm={24} md={6} lg={8} xl={8} xxl={8}>
                <Input
                    onChange={(e: any) => {
                        setFilters({ ...filters, lastName: e.target.value });
                    }}
                    placeholder="Nom"
                    value={filters.lastName}
                    style={{ width: "100%" }}
                    suffix={
                        filters.lastName && (
                            <CloseCircleOutlined
                                onClick={(e: any) => {
                                    setFilters({ ...filters, lastName: e.target.value });
                                    search({
                                        firstName: filters.firstName,
                                        lastName: "",
                                    });
                                }}
                                style={{ color: "rgba(0,0,0,.45)" }}
                            />
                        )
                    }
                />
            </Col>
            <Col xs={24} sm={24} md={5} lg={4} xl={4} xxl={4}>
                <Button
                    block
                    onClick={() => {
                        search({
                            firstName: filters.firstName,
                            lastName: filters.lastName,
                        });
                    }}
                    type="primary"
                    icon={<SearchOutlined />}
                >
                    Rechercher
                </Button>
            </Col>
        </Row>
    );

    return (
        <Card>
            <StyledList
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 6,
                    xxl: 3,
                }}
                pagination={{
                    current: currentPage,
                    onChange: (page) => setCurrentPage(page),
                    pageSize: 30,
                }}
                dataSource={results.sort((a: any, b: any) => {
                    return a.role.localeCompare(b.role);
                })}
                header={header}
                loading={isLoading}
                renderItem={(item: any) => {
                    return (
                        <Item key={item.firstName + item.lastName}>
                            <Popover
                                content={
                                    <List size="small" bordered={false} split={false}>
                                        {item.role === "ADMIN" && (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={
                                                        <Avatar
                                                            icon={<CrownOutlined />}
                                                            size="small"
                                                        />
                                                    }
                                                    title="Elu"
                                                />
                                            </List.Item>
                                        )}
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar
                                                        icon={<PhoneOutlined />}
                                                        size="small"
                                                    />
                                                }
                                                title={
                                                    item.phoneNumber &&
                                                    item.isVisible.phoneNumber
                                                        ? item.phoneNumber
                                                        : "Non indiqué"
                                                }
                                            />
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar
                                                        icon={<MessageOutlined />}
                                                        size="small"
                                                    />
                                                }
                                                title={
                                                    item.email && item.isVisible.email
                                                        ? item.email
                                                        : "Non indiqué"
                                                }
                                            />
                                        </List.Item>

                                        {/*<List.Item>
                                            <List.Item.Meta
                                                title={
                                                    <Link
                                                        className="ant-btn ant-btn-primary ant-btn-block ant-btn-background-ghost"
                                                        to={{
                                                            pathname: "/messages/new",
                                                            state: { to: item.email },
                                                        }}
                                                    >
                                                        Envoyer un message
                                                    </Link>
                                                }
                                            />
                                        </List.Item>*/}
                                    </List>
                                }
                                trigger="click"
                            >
                                <StyledCard
                                    hoverable
                                    actions={[
                                        <StyledCardContent>
                                            {item.role === "ADMIN" && (
                                                <CrownTwoTone
                                                    twoToneColor={
                                                        state.settings.general
                                                            .primaryColor
                                                    }
                                                />
                                            )}{" "}
                                            {item.firstName} {item.lastName}
                                        </StyledCardContent>,
                                    ]}
                                >
                                    <OZAvatar
                                        key={item.firstName + item.lastName + "avatar"}
                                        content={`${item.firstName} ${item.lastName}`}
                                        size={96}
                                        src={item.picture}
                                    />
                                </StyledCard>
                            </Popover>
                        </Item>
                    );
                }}
            />
        </Card>
    );
};

const StyledCard = styled(Card)`
    text-align: center;
`;

const StyledList = styled(List)`
    .ant-list-header {
        margin-bottom: 13px;
    }
`;

const Grid = styled.div`
    display: grid;
    grid: auto / repeat(4, auto);
    grid-gap: 8px;
    align-items: center;
`;

const Item = styled(List.Item)`
    .ant-empty-image {
        display: grid;
        align-items: center;
        height: 100%;
    }

    .ant-empty-image span {
        height: 100%;
    }
`;

const StyledCardContent = styled.div`
    display: inline;
`;

export default Users;
