import React, { useEffect } from "react";
import { useOvermind } from "store";
import { useHistory, useParams } from "react-router-dom";
import format from "moment";

import OZInput from "components/OZInput";
import OZTextEditor from "components/OZTextEditor";

import BasicForm from "templates/BasicForm";

type ParamsType = {
    id?: string;
};

const Event = () => {
    const { actions, state } = useOvermind();
    const history = useHistory();
    const { id = "" }: ParamsType = useParams();
    const sideFields = useFields();
    const { selected } = state.calendar;

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        actions.setIsFetching(true);
        if (id && id !== "new") await actions.calendar.getEvent(id);
        await actions.categories.getCategories("event");
        actions.setIsFetching(false);
    };

    const onSubmit = async (data: any) => {
        const values = {
            ...data,
            startDate: data.startDate.format("DD/MM/YYYY"),
            endDate: data.endDate.format("DD/MM/YYYY"),
            startTime: data.startTime.format("HH:mm"),
            endTime: data.endTime.format("HH:mm"),
        };

        if (id === "new") await actions.calendar.createEvent(values);
        else await actions.calendar.updateEvent({ id, values });

        history.push("/admin/calendar");
    };

    const onDelete = async () => {
        id && (await actions.calendar.deleteEvent(id));
        history.push("/admin/calendar");
    };

    const initialValues = {
        name: id !== "new" ? selected.name : "",
        description: id !== "new" ? selected.description : "",
        category: id !== "new" && selected.category ? selected.category._id : "",
        startDate: id !== "new" ? format(selected.startDate, "DD/MM/YYYY") : "",
        endDate: id !== "new" ? format(selected.endDate, "DD/MM/YYYY") : "",
        startTime: id !== "new" ? format(selected.startTime, "HH:mm") : "",
        endTime: id !== "new" ? format(selected.endTime, "HH:mm") : "",
    };

    return (
        <BasicForm
            deleteText="cet événement"
            id={id}
            initialValues={initialValues}
            mainFields={
                <>
                    <OZInput
                        label="Nom de l'événement"
                        name="name"
                        placeholder="Nom"
                        rules={validationRules.name}
                    />

                    <OZTextEditor label="Description de l'événement" name="description" />
                </>
            }
            onDelete={onDelete}
            onSubmit={onSubmit}
            sideFields={sideFields}
        />
    );
};

const useFields = () => {
    const { state } = useOvermind();
    const { selected } = state.calendar;
    const { event } = state.categories;

    const selectCategories = (data: any) => {
        const categories: any[] = [];
        data && data.map((c: any) => categories.push({ label: c.name, value: c._id }));
        return categories;
    };

    const sideFields = [
        {
            name: "category",
            defaultValue: selected.category && selected.category._id,
            label: "Catégorie",
            values: selectCategories(event.list),
            rules: validationRules.category,
            type: "select",
        },
        {
            name: "startDate",
            rules: validationRules.date,
            label: "Date de début",
            style: { width: "100%" },
            type: "date",
        },
        {
            name: "startTime",
            rules: validationRules.time,
            label: "Heure de début",
            style: { width: "100%" },
            type: "time",
        },
        {
            name: "endDate",
            rules: validationRules.date,
            label: "Date de fin",
            style: { width: "100%" },
            type: "date",
        },
        {
            name: "endTime",
            rules: validationRules.time,
            label: "Heure de fin",
            style: { width: "100%" },
            type: "time",
        },
    ];

    return sideFields;
};

const validationRules = {
    category: [{ required: true, message: "Veuillez sélectionner une catégorie." }],
    name: [{ required: true, message: "Veuillez indiquer un nom." }],
    date: [{ required: true, message: "Veuillez indiquer une date." }],
    time: [{ required: true, message: "Veuillez indiquer une heure." }],
};

export default Event;
