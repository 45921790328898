import React from "react";
import { Drawer } from "antd";

const OZDrawer = ({ children, onClose, title, visible, width = 256, ...rest }: any) => {
    return (
        <Drawer
            title={title}
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
            width={width}
            {...rest}
        >
            {children}
        </Drawer>
    );
};

export default OZDrawer;
