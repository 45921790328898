import React from "react";
import { useOvermind } from "store";
import styled from "styled-components";
import { Descriptions, Collapse } from "antd";
import OZForm from "../components/OZForm";

const { Panel } = Collapse;

const Logo = () => {
    const { state } = useOvermind();
    const { appName } = state.settings.general;
    const {
        firstName,
        lastName,
        email,
        phoneNumber,
        addressLine1,
        addressLine2,
        postcode,
        city,
        country,
    } = state.user;

    return (
        <>
            <Top>
                <p>
                    {`Vous trouverez ci-dessous vos données personnelles renseignées par ${appName} qui, en sa qualité de responsable du traitement, collecte vos données à caractère personnel à des fins de gestion de votre espace personnalisé.`}
                </p>

                <p>
                    Vous pouvez à tout moment ajouter, modifier ou supprimer ces données
                    depuis votre profil.
                </p>

                <p>
                    Afin d'accéder et d'utiliser la plateforme vous devez accepter ces
                    conditions. En cas de refus, vous serez automatiquement déconnecté.
                </p>
            </Top>

            <Collapse accordion defaultActiveKey={["1"]}>
                <Panel header="Vos données personnelles" key="1">
                    <Content>
                        <Descriptions bordered column={1} size="small">
                            <Descriptions.Item label="Prénom">
                                {firstName}
                            </Descriptions.Item>
                            <Descriptions.Item label="Nom">{lastName}</Descriptions.Item>
                            <Descriptions.Item label="Email">{email}</Descriptions.Item>
                            <Descriptions.Item label="Téléphone">
                                {phoneNumber}
                            </Descriptions.Item>
                            <Descriptions.Item label="Adresse 1">
                                {addressLine1}
                            </Descriptions.Item>
                            <Descriptions.Item label="Adresse 2">
                                {addressLine2}
                            </Descriptions.Item>
                            <Descriptions.Item label="Code postal">
                                {postcode}
                            </Descriptions.Item>
                            <Descriptions.Item label="Ville">{city}</Descriptions.Item>
                            <Descriptions.Item label="Pays">{country}</Descriptions.Item>
                        </Descriptions>
                    </Content>
                </Panel>
                <Panel
                    header="Conditions d'utilisation de vos données personnelles"
                    key="2"
                >
                    <Content>
                        <p>
                            {`En cliquant sur le bouton "J'accepte", vous acceptez les conditions d'utilisation de
                    ${state.settings.general.appName} qui, en sa qualité de responsable du traitement,
                    collecte vos données à caractère personnel à des fins de gestion de votre
                    espace personnalisé.`}
                        </p>
                        <p>
                            {`Conformément à la loi informatique et libertés du 6 janvier 1978, vous
                    bénéficiez d'un droit d'accès, de rectification et de suppression de vos
                    données. Vous pouvez également vous opposer, pour un motif légitime, à
                    l'utilisation de vos données. Vous seul pouvez exercer ces droits sur vos
                    propres données en écrivant à ${state.settings.general.contact.email} et en signant une
                    photocopie de votre pièce d'identité.`}
                        </p>
                    </Content>
                </Panel>
            </Collapse>
        </>
    );
};

const Top = styled.div`
    text-align: justify;
    padding-bottom: 8px;
`;

const Content = styled.div`
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 12px;
    text-align: justify;
    padding-right: 8px;
`;

export default Logo;
