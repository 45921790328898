import React from "react";
import { DatePicker, Form } from "antd";

const OZDatePicker = ({
    disabled = false,
    label,
    name,
    rules,
    size = "large",
    style,
}: any) => {
    return (
        <Form.Item label={label} name={name} rules={rules}>
            <DatePicker
                disabled={disabled}
                format="DD/MM/YYYY"
                size={size}
                style={style}
            />
        </Form.Item>
    );
};

export default OZDatePicker;
