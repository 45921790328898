import { Operator, mutate, run } from "overmind";

export const getSubvention: () => Operator<any> = () =>
    mutate(async function getSubvention({ effects, state }, id) {
        state.subventions.selected = await effects.get(`subventions/${id}`);
    });

export const getSubventions: () => Operator = () =>
    mutate(async function getSubventions({ state, effects }) {
        const res = await effects.get("subventions");
        state.subventions.length = res.length;
        state.subventions.list = res.subventions;
    });

export const getSubventionsByUser: () => Operator<any> = () =>
    mutate(async function getSubventionsByUser({ state, effects }, id) {
        const res = await effects.get(`/subventions/user/${id}`);
        state.subventions.length = res.length;
        state.subventions.list = res.subventions;
    });

export const createSubvention: () => Operator<any> = () =>
    run(async function createSubvention({ effects }, props) {
        await effects.post({ url: "/subventions", data: props });
    });

export const updateSubvention: () => Operator<any> = () =>
    run(async function updateSubvention({ effects }, props) {
        await effects.put({ url: `/subventions/${props.id}`, data: props.data });
    });

export const updateAmountByUser: () => Operator<any> = () =>
    run(async function updateAmountByUser({ effects }) {
        await effects.post({ url: "subventions/update-amount", data: {} });
    });

export const resetAllWallets: () => Operator<any> = () =>
    run(async function resetAllWallets({ effects }) {
        await effects.post({ url: "subventions/reset-all-wallets", data: {} });
    });

export const resetSubventions: () => Operator = () =>
    mutate(async function resetSubventions({ state }) {
        state.subventions.length = 0;
        state.subventions.list = [];
        state.subventions.selected = {};
    });
