import React, { useEffect } from "react";
import { Button, Popconfirm } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useOvermind } from "store";
import styled from "styled-components";
import { format } from "date-fns";

// Components
import OZPageHeader from "components/OZPageHeader";
import OZTable from "components/OZTable";

import { pluralNames } from "helpers";
import { OZTableColumnType } from "helpers/types";

const Requests = () => {
    const { actions, state } = useOvermind();
    const columns = useColumns();
    const { requests } = state;

    useEffect(() => {
        actions.requests.getRequests();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const quantity = pluralNames({ length: requests.list.length, text: "requête" });

    return (
        <Container>
            <OZPageHeader title="Requêtes" quantity={quantity} />
            <OZTable columns={columns} data={requests.list} />
        </Container>
    );
};

const useColumns = () => {
    const { actions } = useOvermind();

    const columns: OZTableColumnType[] = [
        {
            title: "Date",
            width: 120,
            key: "date",
            render: (r: any) => format(new Date(r.createdAt), "dd/MM/yyyy"),
        },
        {
            title: "Utilisateur",
            key: "firstName",
            render: (r: any) => r.user && `${r.user.firstName} ${r.user.lastName}`,
        },
        {
            title: "Email",
            key: "email",
            render: (r: any) => r.user && r.user.email,
        },
        {
            title: "Type",
            key: "type",
            render: (r: any) =>
                r.type === "PASSWORD"
                    ? "Demande d'un nouveau mot de passe"
                    : "Demande d'activation du compte",
        },
        {
            title: "Actions",
            key: "actions",
            align: "right",
            fixed: "right",
            render: (r: any) => (
                <span>
                    <Popconfirm
                        title="Êtes-vous sûr(e) d'avoir répondu à cette requête ?"
                        onConfirm={() => actions.requests.deleteRequest(r._id)}
                        placement="left"
                        okText="Oui"
                        cancelText="Annuler"
                    >
                        <Button ghost icon={<CheckCircleOutlined />} type="primary" />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return columns;
};

const Container = styled.div`
    padding: 24px;
    background: #fff;
`;

export default Requests;
