import React from "react";
import { Tag } from "antd";

type ColorType = {
    type: "idea" | "message" | "survey";
};

const OZBox = ({ type }: ColorType) => {
    const color = {
        idea: "geekblue",
        message: "purple",
        survey: "magenta",
    };

    const text = {
        idea: "Boîte à idées",
        message: "Boîte à messages",
        survey: "Boîte à sondages",
    };

    return <Tag color={color[type]}>{text[type]}</Tag>;
};

export default OZBox;
