import { Operator, mutate, run } from "overmind";

export const getMedia: () => Operator<any> = () =>
    mutate(async function getMedia({ effects, state }, id) {
        state.medias.selected = await effects.get(`medias/${id}`);
    });

export const getMedias: () => Operator = () =>
    mutate(async function getMedias({ state, effects }) {
        const res = await effects.get("medias");
        state.medias.length = res.length;
        state.medias.list = res.medias;
    });

export const getMediasByCategory: () => Operator<string> = () =>
    mutate(async function getMediasByCategory({ state, effects }, category) {
        const res = await effects.get(`/medias/category/${category}`);
        state.medias.length = res.length;
        state.medias.list = res.posts;
    });

export const createMedia: () => Operator<any> = () =>
    run(async function createMedia({ effects }, props) {
        await effects.post({ url: "/medias", data: props });
    });

export const updateMedia: () => Operator<any> = () =>
    run(async function updateMedia({ effects }, props) {
        await effects.put({ url: `/medias/${props.id}`, data: props.data });
    });

export const deleteMedia: () => Operator<any> = () =>
    run(async function deleteMedia({ effects }, id) {
        await effects.del(`medias/${id}`);
    });

export const resetSelectedMedia: () => Operator = () =>
    mutate(async function resetSelectedMedia({ state }) {
        state.medias.selected = {};
    });
