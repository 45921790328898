import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Popconfirm } from "antd";
import { EditOutlined, DeleteTwoTone, InfoCircleTwoTone } from "@ant-design/icons";
import { useOvermind } from "store";
import styled from "styled-components";

// Components
import OZForm from "components/OZForm";
import OZTable from "components/OZTable";
import OZInput from "components/OZInput";

import { OZTableColumnType } from "helpers/types";

type ContainerType = {
    isEditing: string;
};

const BasicCategories = ({ categories, getElements, type }: any) => {
    const { actions } = useOvermind();
    const [form] = Form.useForm();
    const [isEditing, setIsEditing] = useState({ id: "", value: "" });
    const columns = useColumns(type, setIsEditing, form.setFieldsValue, getElements);

    useEffect(() => {
        actions.categories.getCategories(type);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createCategory = async (data: any) => {
        await actions.categories.createCategory({ type, data });
        actions.categories.getCategories(type);
        form.resetFields();
    };

    const editCategory = async (data: any) => {
        await actions.categories.updateCategory({ type, data, id: isEditing.id });
        actions.categories.getCategories(type);
        getElements();
        reset();
    };

    const reset = () => {
        setIsEditing({ id: "", value: "" });
        form.resetFields();
    };

    return (
        <OZForm form={form} onSubmit={isEditing.id ? editCategory : createCategory}>
            <Container isEditing={isEditing.id}>
                <OZInput name="name" placeholder="Nom de la catégorie" />
                <Button type="primary" size="large" htmlType="submit">
                    {isEditing.id ? "Modifier" : "Créer"}
                </Button>
                {isEditing.id ? (
                    <Button onClick={reset} size="large">
                        Annuler
                    </Button>
                ) : null}
            </Container>
            <OZTable columns={columns} data={categories} showHeader={false} />
        </OZForm>
    );
};

const useColumns = (
    type: string,
    setIsEditing: any,
    setFieldsValue: any,
    getElements: any
) => {
    const { actions } = useOvermind();

    const deleteCategory = async (id: string) => {
        await actions.categories.deleteCategory(`${type}/${id}`);
        actions.categories.getCategories(type);
        getElements();
    };

    const columns: OZTableColumnType[] = [
        {
            title: "Nom",
            dataIndex: "name",
            ellipsis: true,
        },
        {
            title: "Actions",
            key: "actions",
            align: "right",
            fixed: "right",
            width: 150,
            render: (c: any, i: any) => (
                <span key={i}>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                            setIsEditing({ id: c._id, value: c.name });
                            setFieldsValue({ name: c.name });
                        }}
                    />
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Êtes-vous sûr(e) de vouloir supprimer cette catégorie ?"
                        onConfirm={() => deleteCategory(c._id)}
                        icon={<InfoCircleTwoTone twoToneColor="red" />}
                        placement="left"
                        okText="Supprimer"
                        okType="danger"
                        cancelText="Annuler"
                    >
                        <Button
                            ghost
                            icon={<DeleteTwoTone twoToneColor="red" />}
                            type="danger"
                        />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return columns;
};

const Container = styled.div<ContainerType>`
    display: grid;
    grid: auto / ${p => (p.isEditing ? "1fr 90px 90px" : "1fr 120px")};
    grid-gap: 8px;
`;

export default BasicCategories;
