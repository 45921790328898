import React from "react";
import { Form, Select } from "antd";
import { OZSelectSearchType } from "helpers/types";

const { Option } = Select;

const OZSelectSearch = ({
    defaultValue,
    disabled,
    label,
    name,
    placeholder,
    size = "large",
    rules,
    values = [],
}: OZSelectSearchType) => {
    return (
        <Form.Item hasFeedback label={label} name={name} rules={rules}>
            <Select
                showSearch
                defaultValue={defaultValue}
                disabled={disabled}
                size={size}
                placeholder={placeholder}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {values.map((v: any, i: number) => (
                    <Option key={i} value={v.value}>
                        {v.label}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default OZSelectSearch;
