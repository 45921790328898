type StateType = {
    length: number;
    list: any[];
    selected: any;
};

export const state: StateType = {
    length: 0,
    list: [],
    selected: {},
};
