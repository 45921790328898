import React, { useEffect, useState } from "react";
import { useOvermind } from "store";
import { Button, Card, Col, List, Row, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import format from "date-fns/format";

const { Option } = Select;

const Documents = () => {
    const { actions, state } = useOvermind();
    const { list } = state.documents;
    const { isLoading } = state;
    const categories = state.categories.document.list;

    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({ category: "" });
    const [results, setResults] = useState<any>([]);

    useEffect(() => {
        actions.documents.getDocuments();
        actions.categories.getCategories("document");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setResults(list);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.length > 0]);

    const search = ({ category }: any) => {
        let newItems = [...list];

        if (category) {
            newItems = newItems.filter((i: any) => i.category._id.includes(category));
        }

        setResults(newItems);
        setCurrentPage(1);
    };

    const header = (
        <Row align="middle" gutter={[8, 8]} justify="end">
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                {results.length} documents trouvés
            </Col>
            <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                <Select
                    defaultValue=""
                    onChange={(e: any) => setFilters({ ...filters, category: e })}
                    placeholder="Catégories"
                    style={{ width: "100%" }}
                >
                    <Option value="">Toutes les catégories</Option>
                    {categories.map((c: any) => (
                        <Option value={c._id}>{c.name}</Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <Button
                    block
                    onClick={() => {
                        search({
                            category: filters.category,
                        });
                    }}
                    type="primary"
                    icon={<SearchOutlined />}
                >
                    Rechercher
                </Button>
            </Col>
        </Row>
    );

    return (
        <Card>
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    current: currentPage,
                    onChange: (page) => setCurrentPage(page),
                    pageSize: 10,
                }}
                dataSource={results}
                header={header}
                loading={isLoading}
                renderItem={(item: any) => {
                    return (
                        <List.Item
                            key={item._id}
                            actions={[
                                `Publié le ${format(
                                    new Date(item.createdAt),
                                    "dd/MM/yyyy"
                                )}`,
                                item.category && item.category.name,
                            ]}
                            extra={
                                item.file ? (
                                    <Button
                                        href={item.file.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        type="primary"
                                    >
                                        Télécharger
                                    </Button>
                                ) : (
                                    <Button disabled type="primary">
                                        Aucun fichier
                                    </Button>
                                )
                            }
                        >
                            <List.Item.Meta
                                title={item.name}
                                description={
                                    <Content
                                        dangerouslySetInnerHTML={{
                                            __html: item.description,
                                        }}
                                    />
                                }
                            />
                        </List.Item>
                    );
                }}
            />
        </Card>
    );
};

// Styles
const Content = styled.div`
    text-align: justify;
    padding-bottom: 8px;
`;

export default Documents;
