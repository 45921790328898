import { Operator, mutate, run } from "overmind";

export const getEvent: () => Operator<any> = () =>
    mutate(async function getEvent({ effects, state }, id) {
        state.calendar.selected = await effects.get(`events/${id}`);
    });

export const getEvents: () => Operator = () =>
    mutate(async function getEvents({ state, effects }) {
        const res = await effects.get("events");
        state.calendar.length = res.length;
        state.calendar.list = res.events;
    });

export const getEventsByCategory: () => Operator<string> = () =>
    mutate(async function getEventsByCategory({ state, effects }, category) {
        const res = await effects.get(`/events/category/${category}`);
        state.calendar.length = res.length;
        state.calendar.list = res.events;
    });

export const createEvent: () => Operator<any> = () =>
    run(async function createEvent({ effects }, props) {
        await effects.post({ url: "/events", data: props });
    });

export const updateEvent: () => Operator<any> = () =>
    run(async function updateEvent({ effects }, props) {
        await effects.put({ url: `/events/${props.id}`, data: props.values });
    });

export const deleteEvent: () => Operator<any> = () =>
    run(async function deleteEvent({ effects }, id) {
        await effects.del(`events/${id}`);
    });

export const resetSelectedEvent: () => Operator = () =>
    mutate(async function resetSelectedEvent({ state }) {
        state.calendar.selected = {};
    });
