import React, { useEffect, useState } from "react";
import { Button, Divider, Popconfirm } from "antd";
import { EditOutlined, DeleteTwoTone, InfoCircleTwoTone } from "@ant-design/icons";
import { useOvermind } from "store";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";

// Components
import OZPageHeader from "components/OZPageHeader";
import OZTable from "components/OZTable";
import OZDrawer from "components/OZDrawer";
import OZUploadFile from "components/OZUploadFile";

import { pluralNames } from "helpers";
import { OZTableColumnType } from "helpers/types";
import useSearch from "helpers/useSearch";

import BasicCategories from "templates/BasicCategories";

const Documents = () => {
    const { actions, state } = useOvermind();
    const [visible, setVisible] = useState(false);
    const history = useHistory();
    const columns = useColumns();
    const { documents } = state;
    const { document } = state.categories;

    useEffect(() => {
        actions.documents.getDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const quantity = pluralNames({ length: documents.list.length, text: "document" });

    return (
        <Container>
            <OZPageHeader
                title="Documents"
                quantity={quantity}
                primaryAction={() => history.push("/admin/documents/new")}
                primaryText="Ajouter un document"
                secondaryAction={() => setVisible(true)}
            />
            <OZTable columns={columns} data={documents.list} />
            <OZDrawer
                title="Catégories des documents"
                onClose={() => setVisible(false)}
                visible={visible}
                width={400}
            >
                <BasicCategories
                    categories={document.list}
                    getElements={actions.documents.getDocuments}
                    type="document"
                />
            </OZDrawer>
        </Container>
    );
};

const useColumns = () => {
    const { actions } = useOvermind();
    const history = useHistory();

    const columns: OZTableColumnType[] = [
        {
            title: "Document",
            width: 80,
            key: "document",
            render: (d: any) => (
                <OZUploadFile
                    action={actions.documents.updateDocument}
                    id={d._id}
                    initialFile={d.file}
                    pathName="documents"
                    sizeLimit={8}
                    type="file"
                />
            ),
        },
        {
            title: "Date",
            width: 120,
            key: "date",
            render: (d: any) => format(new Date(d.createdAt), "dd/MM/yyyy"),
        },
        {
            title: "Nom",
            dataIndex: "name",
            ...useSearch("name"),
        },
        {
            title: "Catégorie",
            key: "category",
            render: (d: any) => d.category && d.category.name,
        },
        {
            title: "Actions",
            key: "actions",
            align: "right",
            fixed: "right",
            render: (d: any) => (
                <span>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => history.push(`/admin/documents/${d._id}`)}
                    />
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Êtes-vous sûr(e) de vouloir supprimer ce document ?"
                        onConfirm={async () => {
                            await actions.documents.deleteDocument(d._id);
                            d.file &&
                                d.file.fileName &&
                                (await actions.deleteFiles({
                                    fileName: d.file.fileName,
                                }));
                        }}
                        icon={<InfoCircleTwoTone twoToneColor="red" />}
                        placement="left"
                        okText="Supprimer"
                        okType="danger"
                        cancelText="Annuler"
                    >
                        <Button
                            ghost
                            icon={<DeleteTwoTone twoToneColor="red" />}
                            type="danger"
                        />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return columns;
};

const Container = styled.div`
    padding: 24px;
    background: #fff;
`;

export default Documents;
