import { Operator, pipe, tryCatch } from "overmind";
import * as o from "./operators";
import * as oCommon from "store/operators";

const COMMON_ERROR_MESSAGE = "Une erreur est survenue.";

export const getMedia: Operator<string> = tryCatch({
    try: o.getMedia(),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const getMedias: Operator = pipe(
    oCommon.setIsLoading(true),
    o.getMedias(),
    oCommon.setIsLoading(false)
);

export const getMediasByCategory: Operator<string> = o.getMediasByCategory();

export const createMedia: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.createMedia(),
        o.getMedias(),
        oCommon.toast({ status: "success", text: "Galerie créée." }),
        oCommon.setIsLoading(false)
    ),
    catch: pipe(
        oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
        oCommon.setIsLoading(false)
    ),
});

export const updateMedia: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.updateMedia(),
        oCommon.destroyToast(),
        oCommon.toast({ status: "success", text: "Galerie mise à jour." }),
        oCommon.setIsLoading(false)
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const deleteMedia: Operator<string> = tryCatch({
    try: pipe(
        o.deleteMedia(),
        o.getMedias(),
        oCommon.toast({ status: "success", text: "Galerie supprimée." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const resetSelectedMedia: Operator = o.resetSelectedMedia();
