import React from "react";
import { Tag } from "antd";

type ColorType = {
    type: "canceled" | "completed" | "pending" | "processing" | "refunded";
};

const OZStatus = ({ type }: ColorType) => {
    const color = {
        accepted: "blue",
        canceled: "",
        completed: "blue",
        pending: "orange",
        processing: "green",
        refunded: "",
        rejected: "red",
    };

    const text = {
        accepted: "Remboursement accepté",
        canceled: "Annulée",
        completed: "Complétée",
        pending: "En attente",
        processing: "En cours",
        refunded: "Remboursée",
        rejected: "Non subventionnable",
    };

    return <Tag color={color[type]}>{text[type]}</Tag>;
};

export default OZStatus;
