import React, { useState } from "react";
import { Button, Card, Col, Collapse, List, Row } from "antd";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import styled from "styled-components";

// COMPONENTS
import OZTextArea from "components/OZTextArea";
import OZForm from "components/OZForm";
import OZAvatar from "components/OZAvatar";

// HELPERS
import { truncateText } from "helpers";

import Sidebar from "./Sidebar";
const { Panel } = Collapse;

const Message = () => {
    const [messagesOpened, setMessagesOpened] = useState<string | string[]>([
        (messages.length - 1).toString(),
    ]);

    return (
        <Card>
            <Row align="top" gutter={[32, 8]} justify="center">
                <Sidebar />
                <Col xs={24} sm={24} md={19} lg={19} xl={19} xxl={19}>
                    <Card bordered>
                        <h3 style={{ textAlign: "justify" }}>
                            Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone
                            Kitty Paw Artisan Keycaps
                        </h3>
                        <Collapse
                            bordered={false}
                            defaultActiveKey={[messages.length - 1]}
                            expandIconPosition="right"
                            onChange={(keys) => setMessagesOpened(keys)}
                        >
                            {messages.map((m, i) => (
                                <Panel
                                    header={
                                        <StyledMessageHeader>
                                            <StyledUser>
                                                <OZAvatar
                                                    size="medium"
                                                    src={m.user.picture}
                                                    content={`${m.user.firstName} ${m.user.lastName}`}
                                                />
                                                <div>
                                                    {m.user.firstName} {m.user.lastName}
                                                    {!messagesOpened.includes(
                                                        i.toString()
                                                    ) && (
                                                        <StyledSummary>
                                                            {truncateText(m.text, 70)}
                                                        </StyledSummary>
                                                    )}
                                                </div>
                                            </StyledUser>
                                            {format(
                                                new Date(m.createdAt),
                                                "dd MMM yyyy - HH:mm",
                                                { locale: fr }
                                            )}
                                        </StyledMessageHeader>
                                    }
                                    key={i}
                                >
                                    <p>{text}</p>
                                </Panel>
                            ))}
                        </Collapse>
                    </Card>
                    <br />
                    <OZForm>
                        <OZTextArea name="text" rows={8} />
                        <Button type="primary">Répondre</Button>
                    </OZForm>
                </Col>
            </Row>
        </Card>
    );
};

const text = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit.
In vel ante est. Nulla a neque vel odio vulputate rutrum a eu velit. Proin auctor nisi lorem, ac consectetur orci dictum sit amet. Duis eget convallis metus. Morbi vulputate dignissim ipsum, non lobortis lectus eleifend eu. Vestibulum tincidunt tempor ligula, vel varius lacus eleifend ut. Donec vel facilisis nunc. Pellentesque a condimentum quam.
Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla non elementum lacus, a tristique ligula. Pellentesque ut neque a leo fringilla iaculis. Curabitur condimentum ultricies dictum. Donec egestas massa accumsan ligula iaculis lacinia. Aliquam dapibus odio vitae nisi efficitur vulputate. Sed lectus arcu, ultrices vitae orci sed, consequat aliquam mi. Quisque at est ac purus scelerisque consequat. Vivamus vitae malesuada mauris. Aenean interdum risus mauris, ultricies egestas leo pulvinar non. Proin non felis quis quam vestibulum faucibus sed ut nisi. Vivamus rhoncus risus id mauris sodales scelerisque. Phasellus dignissim urna dictum pellentesque eleifend. Ut egestas hendrerit augue, in consectetur nisi tempus et.
`;

const messages = [
    {
        user: {
            firstName: "Steven",
            lastName: "PERSIA",
            picture: {
                fileName: "OK",
                url:
                    "https://avatars.githubusercontent.com/u/24541365?s=460&u=1acb8c49db648ad532229b979483d3b097bcc1db&v=4",
            },
        },
        text: text,
        createdAt: "2021-02-08T18:25:29+01:00",
    },
    {
        user: { firstName: "Samuel", lastName: "EDOM", picture: "" },
        text: text,
        createdAt: "2020-11-08T18:25:29+01:00",
    },
];

// Styles
const StyledUser = styled.div`
    display: grid;
    grid: auto / auto 1fr;
    grid-gap: 8px;
    align-items: center;
`;

const StyledSummary = styled.div`
    color: rgba(0, 0, 0, 0.65);
`;

const StyledMessageHeader = styled.div`
    display: grid;
    grid: auto / 1fr auto;
    grid-gap: 8px;
    align-items: center;
`;

export default Message;
