import React from "react";
import { Avatar, Button, Divider, Tag, Popconfirm } from "antd";
import { EditOutlined, DeleteTwoTone, InfoCircleTwoTone } from "@ant-design/icons";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import OZPageHeader from "components/OZPageHeader";
import OZTable from "components/OZTable";
import { OZTableColumnType } from "helpers/types";

const Products = () => {
    const history = useHistory();

    const columns: OZTableColumnType[] = [
        {
            title: "Photo",
            dataIndex: "photo",
            key: "photo",
            render: () => (
                <Avatar shape="square" size={48}>
                    <i className="ri-image-line"></i>
                </Avatar>
            ),
        },
        {
            title: "Titre",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            ellipsis: true,
        },
        {
            title: "Prix",
            dataIndex: "price",
            key: "price",
            align: "center",
            render: (text: string) => <Tag color="purple"> {`${text} €`}</Tag>,
        },
        {
            title: "Actions",
            key: "actions",
            align: "right",
            fixed: "right",
            render: (p: any) => (
                <span>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() =>
                            history.push({
                                pathname: `/admin/shop/products/${p.key}`,
                                state: { ...p },
                            })
                        }
                    />
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Êtes-vous sûr(e) de vouloir supprimer ce produit ?"
                        // onConfirm={confirm}
                        icon={<InfoCircleTwoTone twoToneColor="red" />}
                        placement="left"
                        okText="Supprimer"
                        okType="danger"
                        cancelText="Annuler"
                    >
                        <Button
                            ghost
                            icon={<DeleteTwoTone twoToneColor="red" />}
                            type="danger"
                        />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    const data = [
        {
            key: "1",
            photo: "",
            title: "Nulla faucibus",
            description:
                "Sed porttitor convallis felis, euismod dapibus sem aliquam at. Nulla faucibus ultrices urna, sit amet eleifend nisi convallis sed. Vivamus nulla augue, tincidunt eu aliquet quis, tristique interdum elit. Cras lacinia, eros nec aliquet accumsan, mauris libero fringilla felis, ac pretium nisl turpis vitae leo. Aenean eu tellus lorem.",
            price: 39,
        },
        {
            key: "2",
            photo: "",
            title: "Vivamus nulla",
            description:
                "Sed porttitor convallis felis, euismod dapibus sem aliquam at. Nulla faucibus ultrices urna, sit amet eleifend nisi convallis sed. Vivamus nulla augue, tincidunt eu aliquet quis, tristique interdum elit. Cras lacinia, eros nec aliquet accumsan, mauris libero fringilla felis, ac pretium nisl turpis vitae leo. Aenean eu tellus lorem.",
            price: 19,
        },
    ];

    return (
        <Container>
            <OZPageHeader
                title="Boutique"
                quantity="2 produits"
                primaryAction={() => console.log("Clicked!")}
                primaryText="Ajouter un produit"
            />
            <OZTable columns={columns} data={data} />
        </Container>
    );
};

const Container = styled.div`
    padding: 24px;
    background: #fff;
`;

export default Products;
