import { Operator, mutate, run } from "overmind";

export const getBox: () => Operator<any> = () =>
    mutate(async function getBox({ effects, state }, id) {
        state.boxes.selected = await effects.get(`boxes/${id}`);
    });

export const getBoxes: () => Operator = () =>
    mutate(async function getBoxes({ state, effects }) {
        const res = await effects.get("boxes");
        state.boxes.length = res.length;
        state.boxes.list = res.boxes;
    });

export const createBox: () => Operator<any> = () =>
    run(async function createBox({ effects }, props) {
        await effects.post({ url: "/boxes", data: props });
    });

export const updateBox: () => Operator<any> = () =>
    run(async function updatBox({ effects }, props) {
        await effects.put({ url: `/boxes/${props.id}`, data: props.data });
    });

export const answerBox: () => Operator<any> = () =>
    run(async function answerBox({ effects }, props) {
        await effects.put({ url: `/boxes/answer/${props.id}`, data: props.data });
    });

export const deleteBox: () => Operator<any> = () =>
    run(async function deleteBox({ effects }, id) {
        await effects.del(`boxes/${id}`);
    });

export const resetSelectedBox: () => Operator = () =>
    mutate(async function resetSelectedBox({ state }) {
        state.boxes.selected = {};
    });
