import { IConfig } from "overmind";
import { merge, namespaced } from "overmind/config";
import onInitialize from "./onInitialize";
import * as actions from "./actions";
import * as effects from "./effects";
import { state } from "./state";
import { createHook } from "overmind-react";

import * as boxes from "./namespaces/boxes";
import * as calendar from "./namespaces/calendar";
import * as categories from "./namespaces/categories";
import * as documents from "./namespaces/documents";
import * as medias from "./namespaces/medias";
import * as posts from "./namespaces/posts";
import * as users from "./namespaces/users";
import * as subventions from "./namespaces/subventions";
import * as requests from "./namespaces/requests";

export const config = merge(
    {
        onInitialize,
        actions,
        effects,
        state,
    },
    namespaced({
        boxes,
        calendar,
        categories,
        documents,
        medias,
        posts,
        users,
        subventions,
        requests,
    })
);

declare module "overmind" {
    interface Config extends IConfig<typeof config> {}
}

export const useOvermind = createHook<typeof config>();
