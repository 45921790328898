import React from "react";
import { Table } from "antd";
import locale from "locale/table.json";
import { OZTableType } from "helpers/types";
import styled from "styled-components";

const OZTable = ({
    bordered,
    columns,
    data,
    footer,
    onChange,
    onRow,
    pagination,
    summary,
    showHeader = true,
    size = "default",
    title,
}: OZTableType) => {
    return (
        <StyledTable
            bordered={bordered}
            columns={columns}
            dataSource={data}
            footer={footer}
            locale={locale}
            onChange={onChange}
            onRow={onRow}
            pagination={pagination}
            summary={summary}
            scroll={{ x: true }}
            showHeader={showHeader}
            rowKey={(record: any) => record._id}
            size={size}
            title={() => title}
        />
    );
};

const StyledTable = styled(Table)`
    .ant-table-content {
        scrollbar-width: none;

        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

export default OZTable;
