import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    List,
    Select,
    Empty,
    Row,
    Col,
    PageHeader,
    Tabs,
    Descriptions,
} from "antd";
import {
    FolderTwoTone,
    EnvironmentTwoTone,
    ShopTwoTone,
    SearchOutlined,
    PictureTwoTone,
    TagTwoTone,
    SafetyCertificateTwoTone,
    CalendarTwoTone,
} from "@ant-design/icons";
import styled from "styled-components";
import { useOvermind } from "store";

import "antd/dist/antd.css";
import OZModal from "components/OZModal";
import { truncateText } from "helpers";
import { Link, useHistory } from "react-router-dom";
import { format } from "date-fns";

const { Option } = Select;
const { TabPane } = Tabs;

const emptyItem = {
    id: null,
    title: "",
    category: { id: null, name: "" },
    description: "",
    picture: "",
    user: { avatar: "", email: "", phone: "" },
    custom: [],
};

const Offers = () => {
    const { state } = useOvermind();
    const history = useHistory();

    const [items, setItems] = useState<any>([]);
    const [categories, setCategories] = useState<any>([]);

    const [visible, setVisible] = useState(false);
    const [results, setResults] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [selectedItem, setSelectedItem] = useState(emptyItem);

    const [filters, setFilters] = useState({
        category: "",
    });

    const search = ({ category }: any) => {
        let newItems = [...items];
        if (category) {
            newItems = newItems.filter((i: any) => i.category.id === category);
        }
        setResults(newItems);
        setCurrentPage(1);
    };

    const IconText = ({ icon, text }: any) => (
        <div
            style={{
                display: "grid",
                grid: "auto / auto auto",
                gridGap: 8,
                alignItems: "center",
            }}
        >
            {React.createElement(icon, {
                twoToneColor: state.settings.general.primaryColor,
            })}
            {text}
        </div>
    );

    const fetch = () => {
        setResults(data);
        setItems(data);
        setCategories(cat);
    };

    useEffect(() => {
        fetch();
    }, []);

    const header = (
        <Row align="middle" gutter={[8, 8]} justify="end">
            <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                {results.length} petites annonces trouvées
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                <Select
                    defaultValue=""
                    onChange={(e: any) => setFilters({ ...filters, category: e })}
                    placeholder="Catégories"
                    style={{ width: "100%" }}
                >
                    <Option value="">Toutes les catégories</Option>
                    {categories.map((c: any) => (
                        <Option value={c.id}>{c.name}</Option>
                    ))}
                </Select>
            </Col>

            <Col xs={24} sm={24} md={24} lg={1} xl={1} xxl={1}>
                <Button
                    block
                    onClick={() => {
                        search({
                            category: filters.category,
                        });
                    }}
                    icon={<SearchOutlined />}
                />
            </Col>

            <Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>
                <Link className="ant-btn ant-btn-primary ant-btn-block" to="/offers/new">
                    Ajouter une annonce
                </Link>
            </Col>
        </Row>
    );

    const descriptions = (item: any) => (
        <Descriptions
            bordered
            size="small"
            column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
        >
            <Descriptions.Item label="Publié par">
                {`${item.user.firstName} ${item.user.lastName}`}
            </Descriptions.Item>
            <Descriptions.Item label="Téléphone">
                {item.user.isVisible.phoneNumber && item.user.phoneNumber
                    ? item.user.phoneNumber
                    : "Non indiqué"}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
                {item.user.isVisible.email && item.user.email
                    ? item.user.email
                    : "Non indiqué"}
            </Descriptions.Item>
            <Descriptions.Item label="Catégorie">{item.category.name}</Descriptions.Item>
            {item.custom.map((c: any) => (
                <Descriptions.Item label={c.label}>{c.value}</Descriptions.Item>
            ))}
            <Descriptions.Item label="Date de publication">
                {`${format(new Date(item.createdAt), "dd/MM/yyyy")}`}
            </Descriptions.Item>
        </Descriptions>
    );

    return (
        <Card>
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    current: currentPage,
                    onChange: (page) => setCurrentPage(page),
                    pageSize: 5,
                }}
                dataSource={results}
                header={header}
                loading={state.isFetching}
                renderItem={(item: any) => (
                    <Item
                        key={item.id}
                        onClick={() => {
                            setSelectedItem(item);
                            setVisible(true);
                        }}
                        actions={[
                            <IconText
                                icon={SafetyCertificateTwoTone}
                                text={`${item.user.firstName} ${item.user.lastName}`}
                                key="user"
                            />,
                            <IconText
                                icon={FolderTwoTone}
                                text={item.category.name}
                                key="category"
                            />,
                            <IconText
                                icon={CalendarTwoTone}
                                text={format(new Date(item.createdAt), "dd/MM/yyyy")}
                                key="category"
                            />,
                        ]}
                        extra={
                            item.photo ? (
                                <Thumb img={item.picture} />
                            ) : (
                                <Empty
                                    image={<PictureTwoTone twoToneColor="#DDD" />}
                                    description=""
                                    style={{
                                        background: "#F2F2F2",
                                        fontSize: 50,
                                        width: 150,
                                        height: 150,
                                        margin: 0,
                                        alignSelf: "center",
                                    }}
                                />
                            )
                        }
                    >
                        <List.Item.Meta
                            title={item.title}
                            description={truncateText(item.description, 500)}
                        />
                    </Item>
                )}
            />
            <OZModal
                visible={visible}
                setVisible={setVisible}
                width={800}
                reset={() => setSelectedItem(emptyItem)}
            >
                <PageHeader
                    title={selectedItem.title}
                    extra={[
                        <Link
                            className="ant-btn ant-btn-primary ant-btn-block"
                            to={{
                                pathname: "/messages/new",
                                state: {
                                    to: selectedItem.user.email,
                                    subject: `${selectedItem.category.name} - ${selectedItem.title}`,
                                },
                            }}
                        >
                            Envoyer un message
                        </Link>,
                    ]}
                    footer={
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Description" key="1">
                                <Container
                                    dangerouslySetInnerHTML={{
                                        __html: selectedItem.description,
                                    }}
                                />
                            </TabPane>
                        </Tabs>
                    }
                >
                    <div>{selectedItem.id && descriptions(selectedItem)}</div>
                </PageHeader>
            </OZModal>
        </Card>
    );
};

// DATA
const cat = [
    { id: "c1", name: "Covoiturage" },
    { id: "c2", name: "Annonce" },
];

const data = [
    {
        id: 1,
        createdAt: "2020-10-12T11:15:09.956Z",
        title: "Aller-retour pour la web-conférence 2021 sur Nantes",
        category: { id: "c1", name: "Covoiturage" },
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at placerat augue, id tempus orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse scelerisque elit nec augue convallis volutpat. Aliquam aliquam, ipsum sit amet tincidunt volutpat, felis augue venenatis augue, nec efficitur nibh felis consequat arcu. Vivamus vitae erat justo. Sed velit tortor, scelerisque sed nibh eu, semper bibendum diam. Aenean sed nisl sem. Aliquam ultrices convallis massa a eleifend. Aliquam congue sapien non commodo consectetur. Duis ac sem a nibh fringilla tincidunt et porttitor magna. Integer auctor velit lorem, et ultrices quam commodo non. Morbi mollis nunc eros, id malesuada mauris consectetur a. In vitae leo vitae ipsum faucibus scelerisque eget nec sapien.
Nulla ac nisl dui. Praesent eu mi ut nisl sollicitudin laoreet sit amet id libero. Duis quis ipsum at erat eleifend consectetur sit amet quis nisl. Curabitur egestas mattis ligula id malesuada. Pellentesque lacus dui, ullamcorper sit amet felis nec, consectetur convallis nunc. Sed feugiat ultricies quam, tincidunt rhoncus sapien pulvinar et. Maecenas ac lectus suscipit, elementum lectus eu, maximus velit. Duis eget magna varius justo ultricies facilisis in sed orci. Cras faucibus leo a enim fringilla, eget tincidunt metus mollis. Aenean hendrerit tristique sem ac pretium. In luctus, turpis quis accumsan iaculis, turpis libero rhoncus turpis, eget fermentum enim quam a mauris. Pellentesque non consectetur felis, vitae pharetra augue. Cras porta urna nec imperdiet finibus. `,
        picture: "",
        user: {
            firstName: "Samuel",
            lastName: "EDOM",
            avatar: "",
            email: "samuel@digitalcse.fr",
            phoneNumber: "01 23 45 67 89",
            isVisible: {
                email: false,
                phoneNumber: true,
            },
        },
        custom: [
            {
                label: "Départ",
                value: "Paris",
            },
            {
                label: "Destination",
                value: "Marseille",
            },
            {
                label: "Date",
                value: "22/02/2021 à 08h30",
            },
            {
                label: "Nombre de passagers",
                value: 3,
            },
        ],
    },
    {
        id: 2,
        createdAt: "2020-10-12T11:15:09.956Z",
        title: "Duis quis ipsum at erat eleifend consectetur",
        category: { id: "c2", name: "Annonce" },
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at placerat augue, id tempus orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse scelerisque elit nec augue convallis volutpat. Aliquam aliquam, ipsum sit amet tincidunt volutpat, felis augue venenatis augue, nec efficitur nibh felis consequat arcu. Vivamus vitae erat justo. Sed velit tortor, scelerisque sed nibh eu, semper bibendum diam. Aenean sed nisl sem. Aliquam ultrices convallis massa a eleifend. Aliquam congue sapien non commodo consectetur. Duis ac sem a nibh fringilla tincidunt et porttitor magna. Integer auctor velit lorem, et ultrices quam commodo non. Morbi mollis nunc eros, id malesuada mauris consectetur a. In vitae leo vitae ipsum faucibus scelerisque eget nec sapien.
Nulla ac nisl dui. Praesent eu mi ut nisl sollicitudin laoreet sit amet id libero. Duis quis ipsum at erat eleifend consectetur sit amet quis nisl. Curabitur egestas mattis ligula id malesuada. Pellentesque lacus dui, ullamcorper sit amet felis nec, consectetur convallis nunc. Sed feugiat ultricies quam, tincidunt rhoncus sapien pulvinar et. Maecenas ac lectus suscipit, elementum lectus eu, maximus velit. Duis eget magna varius justo ultricies facilisis in sed orci. Cras faucibus leo a enim fringilla, eget tincidunt metus mollis. Aenean hendrerit tristique sem ac pretium. In luctus, turpis quis accumsan iaculis, turpis libero rhoncus turpis, eget fermentum enim quam a mauris. Pellentesque non consectetur felis, vitae pharetra augue. Cras porta urna nec imperdiet finibus. `,
        picture: "",
        user: {
            firstName: "Steven",
            lastName: "Persia",
            avatar: "",
            email: "steven@digitalcse.fr",
            phoneNumber: "01 23 45 67 89",
            isVisible: {
                email: false,
                phoneNumber: true,
            },
        },
        custom: [
            {
                label: "Prix",
                value: "15 €",
            },
        ],
    },
];

// Styles
const Container = styled.div`
    margin-top: 24px;
    text-align: justify;
`;

const Item = styled(List.Item)`
    cursor: pointer;

    &:hover {
        background: #fafafa;
    }

    .ant-empty-image {
        display: grid;
        align-items: center;
        height: 100%;
    }

    .ant-empty-image span {
        height: 100%;
    }
`;

const Thumb = styled.div<{ img: string }>`
    width: 150px;
    height: 150px;
    background-image: url(${(p) => p.img});
    background-position: center;
    background-size: cover;
`;

export default Offers;
