import React, { useEffect } from "react";
import { Button, Divider, message } from "antd";
import { useOvermind } from "store";
import styled from "styled-components";

// Components
import OZInput from "components/OZInput";
import OZForm from "components/OZForm";

// Blocks
import Logo from "blocks/Logo";

const Register = ({ email, setPanel }: { email: string; setPanel: any }) => {
    const { actions, state } = useOvermind();
    const initialValues = { email };

    useEffect(() => {
        const input: any = document.getElementById("digitalcse_firstName");
        input.focus();
    }, []);

    const onSubmit = async (data: any) => {
        try {
            await actions.signup(data);
            await actions.requests.createRequest({ email: data.email, type: "STATUS" });
            setPanel("account-created");
        } catch (e) {
            message.error(e);
        }
    };

    return (
        <OZForm initialValues={initialValues} onSubmit={onSubmit}>
            <Logo />

            <Top>
                <h2>Inscription</h2>
                <p>Veuillez compléter le formulaire ci-dessous.</p>
            </Top>

            {fields.map((f, i) => (
                <OZInput
                    key={i}
                    icon={f.icon}
                    name={f.name}
                    placeholder={f.placeholder}
                    rules={f.rules}
                    size="large"
                    type={f.type && f.type}
                />
            ))}

            <GroupButton>
                <Button
                    ghost
                    onClick={() => setPanel("lookup")}
                    size="large"
                    type="primary"
                >
                    Retour
                </Button>

                <Button
                    block
                    htmlType="submit"
                    loading={state.isLoading}
                    size="large"
                    type="primary"
                >
                    S'inscrire
                </Button>
            </GroupButton>
            <br />
            <SmallText>
                {`En cliquant sur s'inscrire, vous acceptez les conditions d'utilisation de
                    ${state.settings.general.appName} qui, en sa qualité de responsable du traitement,
                    collecte vos données à caractère personnel à des fins de gestion de votre
                    espace personnalisé.`}
            </SmallText>
            <SmallText>
                {`Conformément à la loi informatique et libertés du 6 janvier 1978, vous
                    bénéficiez d'un droit d'accès, de rectification et de suppression de vos
                    données. Vous pouvez également vous opposer, pour un motif légitime, à
                    l'utilisation de vos données. Vous seul pouvez exercer ces droits sur vos
                    propres données en écrivant à ${state.settings.general.contact.email} et en signant une
                    photocopie de votre pièce d'identité.`}
            </SmallText>
        </OZForm>
    );
};

const validationRules = {
    firstName: [{ required: true, message: " " }],
    lastName: [{ required: true, message: " " }],
    email: [
        { type: "email", message: " " },
        { required: true, message: " " },
    ],
    password: [
        {
            required: true,
            pattern: new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"),
            message:
                "Le mot de passe doit faire plus de 8 caractères, contenir un chiffre et une majuscule.",
        },
    ],
    rgpd: [{ required: true, message: " " }],
};

const fields = [
    {
        name: "firstName",
        icon: <i className="ri-user-line" />,
        rules: validationRules.firstName,
        placeholder: "Prénom",
    },
    {
        name: "lastName",
        icon: <i className="ri-user-line" />,
        rules: validationRules.lastName,
        placeholder: "Nom",
    },
    {
        name: "email",
        icon: <i className="ri-mail-line" />,
        rules: validationRules.email,
        placeholder: "Adresse email",
    },
    {
        name: "password",
        icon: <i className="ri-lock-line" />,
        rules: validationRules.password,
        placeholder: "Mot de passe",
        type: "password",
    },
];

const Top = styled.div`
    text-align: center;
    margin: 24px 0 !important;

    h2 {
        font-weight: 300;
        margin-bottom: 0;
    }

    p {
        font-size: 15px;
        font-weight: 200;
        margin-bottom: 0;
    }
`;

const GroupButton = styled.div`
    display: grid;
    grid: auto / auto 1fr;
    align-items: center;
    grid-gap: 8px;
`;

const SmallText = styled.p`
    font-size: 12px;
    text-align: justify;
`;

export default Register;
