import React from "react";
import { Button, Divider } from "antd";
import { useOvermind } from "store";
import styled from "styled-components";

// Components
import OZInput from "components/OZInput";
import OZForm from "components/OZForm";

// Blocks
import Logo from "blocks/Logo";

const Login = ({ setToken, setPanel }: { setToken: any; setPanel: any }) => {
    const { actions, state } = useOvermind();

    const onSubmit = async (data: any) => {
        const res: any = await actions.login(data);

        if (res && res.message === "Veuillez modifier votre mot de passe.") {
            await setToken(res.token);
            setPanel("reset-pwd");
        }
    };

    return (
        <OZForm onSubmit={onSubmit}>
            <Logo />

            <Top>
                <h2>Connexion</h2>
                <p>Heureux de vous revoir !</p>
            </Top>

            {fields.map((f, i) => (
                <OZInput
                    key={i}
                    icon={f.icon}
                    name={f.name}
                    placeholder={f.placeholder}
                    rules={f.rules}
                    size="large"
                    type={f.type && f.type}
                />
            ))}

            <GroupButton>
                <Button
                    ghost
                    onClick={() => setPanel("lookup")}
                    size="large"
                    type="primary"
                >
                    Retour
                </Button>

                <Button
                    block
                    htmlType="submit"
                    loading={state.isLoading}
                    size="large"
                    type="primary"
                >
                    Se connecter
                </Button>
            </GroupButton>

            <Button
                block
                onClick={() => setPanel("forgot-pwd")}
                size="small"
                style={{ marginTop: 32 }}
                type="link"
            >
                Mot de passe oublié ?
            </Button>
        </OZForm>
    );
};

const validationRules = {
    email: [
        { type: "email", message: " " },
        { required: true, message: " " },
    ],
    password: [{ required: true, message: " " }],
};

const fields = [
    {
        name: "email",
        icon: <i className="ri-mail-line" />,
        rules: validationRules.email,
        placeholder: "Adresse email",
    },
    {
        name: "password",
        icon: <i className="ri-lock-line" />,
        rules: validationRules.password,
        placeholder: "Mot de passe",
        type: "password",
    },
];

const Top = styled.div`
    text-align: center;
    margin: 24px 0 !important;

    h2 {
        font-weight: 300;
        margin-bottom: 0;
    }

    p {
        font-size: 15px;
        font-weight: 200;
        margin-bottom: 0;
    }
`;

const GroupButton = styled.div`
    display: grid;
    grid: auto / auto 1fr;
    align-items: center;
    grid-gap: 8px;
`;

export default Login;
