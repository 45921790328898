import React from "react";
import { Button, Divider } from "antd";
import { useOvermind } from "store";
import styled from "styled-components";

// Blocks
import Logo from "blocks/Logo";

const Lookup = ({ setPanel }: any) => {
    const { state } = useOvermind();
    const login = () => setPanel("login");
    const register = () => setPanel("register");

    return (
        <>
            <Logo />

            <Top>
                <h2>Bienvenue sur notre plateforme</h2>
                <p>Veuillez vous connecter ou vous inscrire.</p>
            </Top>

            <StyledButtons>
                <Button
                    block
                    loading={state.isLoading}
                    onClick={login}
                    size="large"
                    type="primary"
                >
                    Connexion
                </Button>

                <Button
                    block
                    ghost
                    loading={state.isLoading}
                    onClick={register}
                    size="large"
                    type="primary"
                >
                    Inscription
                </Button>
            </StyledButtons>
        </>
    );
};

const Top = styled.div`
    text-align: center;
    margin: 24px 0 !important;

    h2 {
        font-weight: 300;
        margin-bottom: 0;
    }

    p {
        font-size: 15px;
        font-weight: 200;
        margin-bottom: 0;
    }
`;

const StyledButtons = styled.div`
    display: grid;
    grid-gap: 16px;
`;

export default Lookup;
