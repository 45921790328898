import React, { useState } from "react";
import { Button, Card } from "antd";
import { useOvermind } from "store";
import OZTextArea from "../../../components/OZTextArea";
import OZForm from "../../../components/OZForm";
import OZRadio from "../../../components/OZRadio";
import OZCheckbox from "../../../components/OZCheckbox";

type BoxT = {
    id: string;
    options?: string[];
    title: string;
    type: string;
};

const Box = ({ id, options, title, type }: BoxT) => {
    const [hasSent, setHasSent] = useState(false);
    const { actions } = useOvermind();

    const onSubmit = async (data: any) => {
        await actions.boxes.answerBox({ id, data });
        setHasSent(true);
    };

    return (
        <Card title={type} bordered>
            <OZForm onSubmit={onSubmit}>
                <p>{title}</p>

                {options && options.length > 0 ? (
                    <OZRadio
                        disabled={hasSent}
                        name="answer"
                        options={options}
                        rules={validationRules.answer}
                    />
                ) : (
                    <OZTextArea
                        disabled={hasSent}
                        name="answer"
                        rules={validationRules.answer}
                    />
                )}

                <OZCheckbox
                    disabled={hasSent}
                    name="isAnon"
                    text="Envoyer ma réponse anonymement."
                />
                <Button
                    block
                    disabled={hasSent}
                    ghost
                    size="large"
                    type="primary"
                    htmlType="submit"
                >
                    Envoyer
                </Button>
            </OZForm>
        </Card>
    );
};

const validationRules = {
    answer: [{ required: true, message: "Veuillez indiquer une réponse." }],
};

export default Box;
