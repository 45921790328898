import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    List,
    Select,
    Empty,
    Row,
    Col,
    PageHeader,
    Tabs,
    Tooltip,
    Descriptions,
} from "antd";
import {
    FolderTwoTone,
    EnvironmentTwoTone,
    ShopTwoTone,
    SearchOutlined,
    PictureTwoTone,
} from "@ant-design/icons";
import styled from "styled-components";
import axios from "axios";
import { useOvermind } from "store";
import Map from "pigeon-maps";
import Marker from "pigeon-marker";

import "antd/dist/antd.css";
import OZModal from "components/OZModal";

const { Option } = Select;
const { TabPane } = Tabs;

const Partners = () => {
    const { actions, state } = useOvermind();

    const [items, setItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [files, setFiles] = useState([]);
    const [partners, setPartners] = useState([]);
    const [departments, setDepartments] = useState([]);

    const [visible, setVisible] = useState(false);
    const [results, setResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [selectedItem, setSelectedItem] = useState(emptyItem);

    const [filters, setFilters] = useState({
        category: "",
        location: "",
        partner: "",
        department: "",
    });

    const BASE_URL = "https://partenaires.digitalcse.dev/digital-cse-partenaires/";
    const ITEMS_QUERIES = "?limit=-1&filter[status][eq]=published&sort=-created_on";
    const CAT_QUERIES = "?fields=id,title,color";
    const FILES_QUERIES = "?fields=id,data.full_url";

    const get = async (url: string) => {
        return axios
            .get(url)
            .then((res) => {
                return res.data.data;
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetch = async () => {
        actions.setIsFetching(true);
        const items = await get(`${BASE_URL}items/benefits${ITEMS_QUERIES}`);
        const categories = await get(`${BASE_URL}items/categories${CAT_QUERIES}`);
        const files = await get(`${BASE_URL}files${FILES_QUERIES}`);
        const partners = await get(`${BASE_URL}users?fields=id,company`);
        const departments = await get(`${BASE_URL}items/departments?fields=id,title`);

        setItems(items);
        setResults(items);
        setCategories(categories);
        setFiles(files);
        setPartners(partners);
        setDepartments(departments);
        actions.setIsFetching(false);
    };

    const search = ({ category, department, partner }: any) => {
        let newItems = [...items];

        if (category) newItems = newItems.filter((i: any) => i.category === category);
        if (partner) newItems = newItems.filter((i: any) => i.owner === partner);
        if (department) {
            newItems = newItems.filter((i: any) => i.department === department);
        }

        setResults(newItems);
        setCurrentPage(1);
    };

    const IconText = ({ icon, text }: any) => (
        <div
            style={{
                display: "grid",
                grid: "auto / auto auto",
                gridGap: 8,
                alignItems: "center",
            }}
        >
            {React.createElement(icon, {
                twoToneColor: state.settings.general.primaryColor,
            })}
            {text}
        </div>
    );

    const header = (
        <Row align="middle" gutter={[8, 8]} justify="end">
            <Col xs={24} sm={24} md={24} lg={4} xl={4} xxl={4}>
                {results.length} bons plans trouvés
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                <Select
                    defaultValue=""
                    onChange={(e: any) => setFilters({ ...filters, category: e })}
                    placeholder="Catégories"
                    style={{ width: "100%" }}
                >
                    <Option value="">Toutes les catégories</Option>
                    {categories.map((c: any) => (
                        <Option value={c.id}>{c.title}</Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
                <Select
                    defaultValue=""
                    filterOption={(input, option: any) => {
                        return (
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                        );
                    }}
                    onChange={(e: any) => setFilters({ ...filters, partner: e })}
                    optionFilterProp="children"
                    placeholder="Partenaires"
                    showSearch
                    style={{ width: "100%" }}
                >
                    <Option value="">Tout les partenaires</Option>
                    {partners.map((p: any) => (
                        <Option value={p.id}>{p.company}</Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} sm={24} md={12} lg={5} xl={5} xxl={5}>
                <Select
                    defaultValue=""
                    filterOption={(input, option: any) => {
                        return (
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                        );
                    }}
                    onChange={(e: any) => setFilters({ ...filters, department: e })}
                    optionFilterProp="children"
                    showSearch
                    style={{ width: "100%" }}
                >
                    <Option value="">France entière</Option>
                    {departments.map((d: any) => (
                        <Option value={d.id}>{d.title}</Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} sm={24} md={12} lg={1} xl={1} xxl={1}>
                <Button
                    onClick={() => {
                        search({
                            category: filters.category,
                            partner: filters.partner,
                            department: filters.department,
                        });
                    }}
                    type="primary"
                    icon={<SearchOutlined />}
                />
            </Col>
        </Row>
    );

    const descriptions = (item: any) => {
        const partner: any = partners.find((p: any) => p.id === item.owner);
        const category: any = categories.find((c: any) => c.id === item.category);
        const department: any = departments.find((d: any) => d.id === item.department);

        return (
            <Descriptions
                bordered
                size="small"
                column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
            >
                <Descriptions.Item label="Partenaire">
                    {partner.company}
                </Descriptions.Item>
                <Descriptions.Item label="Téléphone">
                    {item.phone ? item.phone : "Non indiqué"}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                    {item.email ? item.email : "Non indiqué"}
                </Descriptions.Item>
                <Descriptions.Item label="Site internet">
                    {item.website ? (
                        <a href={item.website} target="_blank" rel="noopener noreferrer">
                            Voir le site internet
                        </a>
                    ) : (
                        "Non indiqué"
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Lieu">
                    {department ? department.title : "France entière"}
                </Descriptions.Item>
                <Descriptions.Item label="Catégorie">{category.title}</Descriptions.Item>
            </Descriptions>
        );
    };

    const extraPlaquette = (item: any) => {
        const file: any = files.find((f: any) => f.id === item.file);

        return (
            <Tooltip placement="bottom" title="Télécharger la plaquette">
                <Button key="1" type="primary" href={file.data.full_url} target="_blank">
                    Plaquette
                </Button>
            </Tooltip>
        );
    };

    return (
        <Card>
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    current: currentPage,
                    onChange: (page) => setCurrentPage(page),
                    pageSize: 5,
                }}
                dataSource={results}
                header={header}
                loading={state.isFetching}
                renderItem={(item: any) => {
                    const category: any = categories.find(
                        (c: any) => c.id === item.category
                    );
                    const partner: any = partners.find((p: any) => p.id === item.owner);
                    const photo: any = files.find((f: any) => f.id === item.picture);
                    const department: any = departments.find(
                        (d: any) => d.id === item.department
                    );

                    return (
                        <Item
                            key={item.id}
                            onClick={() => {
                                setSelectedItem(item);
                                setVisible(true);
                            }}
                            actions={[
                                <IconText
                                    icon={ShopTwoTone}
                                    text={partner && partner.company}
                                    key="partner"
                                />,
                                <IconText
                                    icon={FolderTwoTone}
                                    text={category && category.title}
                                    key="category"
                                />,
                                <IconText
                                    icon={EnvironmentTwoTone}
                                    text={
                                        department ? department.title : "France entière"
                                    }
                                    key="list-vertical-message"
                                />,
                            ]}
                            extra={
                                photo ? (
                                    <Thumb img={photo.data.full_url} />
                                ) : (
                                    <Empty
                                        image={<PictureTwoTone twoToneColor="#DDD" />}
                                        description=""
                                        style={{
                                            background: "#F2F2F2",
                                            fontSize: 50,
                                            width: 150,
                                            height: 150,
                                            margin: 0,
                                            alignSelf: "center",
                                        }}
                                    />
                                )
                            }
                        >
                            <List.Item.Meta
                                title={item.title}
                                description={item.short_description}
                            />
                        </Item>
                    );
                }}
            />
            <OZModal
                visible={visible}
                setVisible={setVisible}
                width={800}
                reset={() => setSelectedItem(emptyItem)}
            >
                <PageHeader
                    title={selectedItem.title}
                    extra={[
                        selectedItem.promo_code && (
                            <Tooltip placement="bottom" title="Code promo">
                                <Button key="2" type="dashed">
                                    {selectedItem.promo_code}
                                </Button>
                            </Tooltip>
                        ),
                        selectedItem.file && extraPlaquette(selectedItem),
                    ]}
                    footer={
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Description" key="1">
                                <Container
                                    dangerouslySetInnerHTML={{
                                        __html: selectedItem.description,
                                    }}
                                />
                            </TabPane>
                            {selectedItem.location && (
                                <TabPane tab="Voir sur la carte" key="2">
                                    <Container>
                                        <Map
                                            key={selectedItem.id}
                                            provider={mapTilerProvider}
                                            center={[
                                                selectedItem.location.lat,
                                                selectedItem.location.lng,
                                            ]}
                                            zoom={12}
                                            width={752}
                                            height={500}
                                        >
                                            <Marker
                                                anchor={[
                                                    selectedItem.location.lat,
                                                    selectedItem.location.lng,
                                                ]}
                                                payload={1}
                                            />
                                        </Map>
                                    </Container>
                                </TabPane>
                            )}
                        </Tabs>
                    }
                >
                    <Content>{selectedItem.id && descriptions(selectedItem)}</Content>
                </PageHeader>
            </OZModal>
        </Card>
    );
};

const mapTilerProvider = (x: any, y: any, z: any) => {
    return `https://a.tile.openstreetmap.fr/hot/${z}/${x}/${y}.png `;
};

const emptyItem = {
    id: null,
    title: "",
    owner: 0,
    category: 0,
    description: "",
    picture: "",
    phone: "",
    email: "",
    website: "",
    department: "",
    promo_code: "",
    file: null,
    location: { lat: 0, lng: 0 },
};

const Content = ({ children, extra }: any) => {
    return (
        <div className="content">
            <div className="main">{children}</div>
            <div className="extra">{extra}</div>
        </div>
    );
};

// Styles
const Container = styled.div`
    margin-top: 24px;
`;

const Item = styled(List.Item)`
    cursor: pointer;

    &:hover {
        background: #fafafa;
    }

    .ant-empty-image {
        display: grid;
        align-items: center;
        height: 100%;
    }

    .ant-empty-image span {
        height: 100%;
    }
`;

const Thumb = styled.div<{ img: string }>`
    width: 150px;
    height: 150px;
    background-image: url(${(p) => p.img});
    background-position: center;
    background-size: cover;
`;

export default Partners;
