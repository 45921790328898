import React, { useState } from "react";
import { Button, Card } from "antd";
import { useOvermind } from "store";
import { useHistory } from "react-router-dom";

import AddSubvention from "blocks/AddSubvention";
import OZModal from "components/OZModal";

const Subventions = () => {
    const { actions, state } = useOvermind();
    const history = useHistory();
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Card
                title="Subventions"
                extra={
                    <>
                        <Button
                            ghost
                            onClick={() => history.push(`/user/${state.user._id}`)}
                            style={{ margin: 8 }}
                            type="primary"
                        >
                            Voir mes demandes
                        </Button>
                        <Button
                            onClick={async () => {
                                await actions.getAuthedUser(state.user.token);
                                setVisible(true);
                            }}
                            type="primary"
                        >
                            Demander une subvention
                        </Button>
                    </>
                }
            >
                <h3>Conditions de subventionnement</h3>
                <div
                    dangerouslySetInnerHTML={{
                        __html: state.settings.subventions.rules,
                    }}
                />
            </Card>
            <OZModal
                centered
                visible={visible}
                onOk={() => {}}
                onCancel={() => setVisible(false)}
                destroyOnClose
                title="Demande de subvention"
            >
                <AddSubvention setVisible={setVisible} />
            </OZModal>
        </>
    );
};

export default Subventions;
