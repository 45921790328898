import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { createOvermind } from "overmind";
import { Provider } from "overmind-react";
import { config } from "./store";
import * as serviceWorker from "./serviceWorker";
import { ConfigProvider } from "antd";
import frFR from "antd/es/locale/fr_FR";
import "./index.css";

const overmind = createOvermind(config, { devtools: true });

Sentry.init({
    dsn: "https://8a1bd03d0e214994b23f7e0851abc8f7@o460889.ingest.sentry.io/5461879",
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: [
        "ResizeObserver loop limit exceeded",
        "ResizeObserver loop completed with undelivered notifications",
    ],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <Provider value={overmind}>
        <BrowserRouter>
            <ConfigProvider locale={frFR}>
                <App />
            </ConfigProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
