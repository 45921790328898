import React, { useState } from "react";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { format } from "date-fns";

const useSearch = (dataIndex: any) => {
    const [, setSearchText] = useState();
    const [, setSearchedColumn] = useState();
    const [value, setValue] = useState("");

    const getColumnSearchProps = () => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder="Rechercher"
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<i className="ri-search-line" />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                />
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Réinitialiser
                </Button>
            </div>
        ),
        filterIcon: (filtered: any) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value: any, record: any) =>
            // format(new Date(record[dataIndex]), "dd/MM/yyyy")
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    });

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchedColumn(dataIndex);
        setSearchText(selectedKeys[0]);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText("");
        setValue("");
    };

    return getColumnSearchProps();
};

export default useSearch;
