import React from "react";
import { Card, Descriptions, Divider, Tag, Typography } from "antd";
import {
    PhoneTwoTone,
    MailTwoTone,
    MessageTwoTone,
    IdcardTwoTone,
    WalletTwoTone,
} from "@ant-design/icons";
import styled from "styled-components";
import { useOvermind } from "store";

import OZAvatar from "components/OZAvatar";

const { Title } = Typography;

const UserCard = ({ canChange, user }: any) => {
    const { state } = useOvermind();

    return (
        <Card bordered={false}>
            <StyledUser>
                <OZAvatar
                    content={`${user.firstName} ${user.lastName}`}
                    edit={canChange}
                    size={104}
                    src={user.picture}
                />
                <Title level={4}>{`${user.firstName} ${user.lastName}`}</Title>
                <Tag
                    color={
                        user.role === "ADMIN" ? state.settings.general.primaryColor : ""
                    }
                >
                    {user.role === "ADMIN" ? "Admin" : "Membre"}
                </Tag>
            </StyledUser>

            <Divider dashed />

            <Descriptions colon={false} column={1} layout="horizontal" size="small">
                <Descriptions.Item
                    label={
                        <IdcardTwoTone
                            twoToneColor={state.settings.general.primaryColor}
                        />
                    }
                >
                    {user.status ? "Compte activé" : "Compte désactivé"}
                </Descriptions.Item>
                <Descriptions.Item
                    label={
                        <PhoneTwoTone
                            twoToneColor={state.settings.general.primaryColor}
                        />
                    }
                >
                    {user.phoneNumber ? user.phoneNumber : "Téléphone non indiqué"}
                </Descriptions.Item>
                <Descriptions.Item
                    label={
                        <MessageTwoTone
                            twoToneColor={state.settings.general.primaryColor}
                        />
                    }
                >
                    {user.email}
                </Descriptions.Item>

                {state.settings.modules.subventions.status && (
                    <Descriptions.Item
                        label={
                            <WalletTwoTone
                                twoToneColor={state.settings.general.primaryColor}
                            />
                        }
                    >
                        {user.wallet} € de subventions restants
                    </Descriptions.Item>
                )}
            </Descriptions>
        </Card>
    );
};

// Styles
const StyledUser = styled.div`
    display: grid;
    grid: auto / auto;
    grid-gap: 4px;
    justify-content: center;
    text-align: center;

    .ant-avatar {
        margin: 0 auto;
    }

    .ant-tag {
        margin: 0;
    }
`;

export default UserCard;
