import { Operator, pipe, tryCatch } from "overmind";
import * as o from "./operators";
import * as oCommon from "store/operators";

const COMMON_ERROR_MESSAGE = "Une erreur est survenue.";

export const getSubvention: Operator<string> = tryCatch({
    try: o.getSubvention(),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const getSubventions: Operator = pipe(
    oCommon.setIsLoading(true),
    o.getSubventions(),
    oCommon.setIsLoading(false)
);

export const getSubventionsByUser: Operator<string> = o.getSubventionsByUser();

export const createSubvention: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.createSubvention(),
        o.getSubventions(),
        oCommon.toast({ status: "success", text: "Subvention créée." }),
        oCommon.setIsLoading(false)
    ),
    catch: pipe(oCommon.toast({ status: "error" }), oCommon.setIsLoading(false)),
});

export const updateSubvention: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.updateSubvention(),
        oCommon.destroyToast(),
        oCommon.toast({ status: "success", text: "Subvention mise à jour." }),
        oCommon.setIsLoading(false)
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const updateAmountByUser: Operator = o.updateAmountByUser();

export const resetAllWallets: Operator = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.resetAllWallets(),
        oCommon.toast({ status: "success", text: "Portefeuilles réinitialisés." }),
        oCommon.setIsLoading(false)
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const resetSubventions: Operator = o.resetSubventions();
