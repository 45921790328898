import React, { useEffect, useState } from "react";
import { Avatar, Card, Col, Row, Statistic } from "antd";
import { useOvermind } from "store";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import OZAlert from "components/OZAlert";
import OZAvatar from "components/OZAvatar";
import OZTable from "components/OZTable";

import { OZTableColumnType } from "helpers/types";

const Dashboard = () => {
    const { actions, state } = useOvermind();
    const [announcement, setAnnouncement] = useState({
        content: "",
        status: "",
        type: "",
    });

    const { documents, calendar, posts, users } = state;
    const { modules } = state.settings;
    const { limitations } = state.settings.general;
    const { documentColumns, eventColumns, postColumns, userColumns } = useColumns();

    useEffect(() => {
        actions.calendar.getEvents();
        actions.documents.getDocuments();
        actions.posts.getPosts();
        actions.users.getUsers();

        getAnnouncement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const LIMIT = 5;

    const getAnnouncement = async () => {
        return axios
            .get(
                "https://partenaires.digitalcse.dev/digital-cse-partenaires/items/announcement/1"
            )
            .then((res) => setAnnouncement(res.data.data))
            .catch((err) => console.log(err));
    };

    const stats = [
        {
            bloc: (
                <Col xs={24} sm={12} md={12} lg={6}>
                    <Card>
                        <Statistic
                            title="Utilisateurs"
                            value={users.length}
                            suffix={`/ ${limitations.users}`}
                        />
                    </Card>
                </Col>
            ),
            status: true,
        },
        {
            bloc: (
                <Col xs={24} sm={12} md={12} lg={6}>
                    <Card>
                        <Statistic
                            title="Articles"
                            value={posts.length}
                            suffix="/ Illimité"
                        />
                    </Card>
                </Col>
            ),
            status: modules.posts.status,
        },
        {
            bloc: (
                <Col xs={24} sm={12} md={12} lg={6}>
                    <Card>
                        <Statistic
                            title="Evénements"
                            value={calendar.length}
                            suffix="/ Illimité"
                        />
                    </Card>
                </Col>
            ),
            status: modules.calendar.status,
        },
        {
            bloc: (
                <Col xs={24} sm={12} md={12} lg={6}>
                    <Card>
                        <Statistic
                            title="Documents"
                            value={documents.length}
                            suffix="/ Illimité"
                        />
                    </Card>
                </Col>
            ),
            status: modules.documents.status,
        },
    ];

    return (
        <>
            {announcement && announcement.status === "published" && (
                <Col span={24}>
                    <OZAlert
                        banner={false}
                        closable={false}
                        message={
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: announcement.content,
                                }}
                            />
                        }
                        type={announcement.type}
                    />
                </Col>
            )}

            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                {stats.filter((s) => s.status).map((s) => s.bloc)}
            </Row>

            <Row gutter={[16, 16]}>
                {modules.posts.status && (
                    <Col xs={24} md={12}>
                        <Card bordered={false} title={`${LIMIT} derniers articles`}>
                            <OZTable
                                columns={postColumns}
                                data={posts.list.slice(0, LIMIT)}
                                pagination={false}
                                showHeader={false}
                                size="small"
                            />
                        </Card>
                    </Col>
                )}

                <Col xs={24} md={12}>
                    <Card bordered={false} title={`${LIMIT} derniers utilisateurs`}>
                        <OZTable
                            columns={userColumns}
                            data={users.list.slice(0, LIMIT)}
                            pagination={false}
                            showHeader={false}
                            size="small"
                        />
                    </Card>
                </Col>

                {modules.calendar.status && (
                    <Col xs={24} md={12}>
                        <Card bordered={false} title={`${LIMIT} derniers événements`}>
                            <OZTable
                                columns={eventColumns}
                                data={calendar.list.slice(0, LIMIT)}
                                pagination={false}
                                showHeader={false}
                                size="small"
                            />
                        </Card>
                    </Col>
                )}

                {modules.documents.status && (
                    <Col xs={24} md={12}>
                        <Card bordered={false} title={`${LIMIT} derniers documents`}>
                            <OZTable
                                columns={documentColumns}
                                data={documents.list.slice(0, LIMIT)}
                                pagination={false}
                                showHeader={false}
                                size="small"
                            />
                        </Card>
                    </Col>
                )}
            </Row>
        </>
    );
};

const useColumns = () => {
    const documentColumns: OZTableColumnType[] = [
        {
            key: "name",
            render: (d: any) => d.name,
        },
        {
            align: "right",
            fixed: "right",
            key: "actions",
            render: (d: any) => <Link to={`/admin/documents/${d._id}`}>Modifier</Link>,
        },
    ];

    const eventColumns: OZTableColumnType[] = [
        {
            key: "name",
            render: (e: any) => e.name,
        },
        {
            align: "right",
            fixed: "right",
            key: "actions",
            render: (e: any) => <Link to={`/admin/calendar/${e._id}`}>Modifier</Link>,
        },
    ];

    const postColumns: OZTableColumnType[] = [
        {
            key: "name",
            render: (p: any) => (
                <ColumnContent>
                    <Avatar
                        src={p.picture && p.picture.url}
                        shape="square"
                        size="small"
                    />
                    {p.name.length > 40 ? p.name.slice(0, 40) + "..." : p.name}
                </ColumnContent>
            ),
        },
        {
            align: "right",
            fixed: "right",
            key: "actions",
            render: (p: any) => <Link to={`/admin/posts/${p._id}`}>Modifier</Link>,
        },
    ];

    const userColumns: OZTableColumnType[] = [
        {
            key: "name",
            render: (u: any) => (
                <ColumnContent>
                    <OZAvatar content={`${u.firstName} ${u.lastName}`} src={u.picture} />
                    {`${u.firstName} ${u.lastName}`}
                </ColumnContent>
            ),
        },
        {
            align: "right",
            fixed: "right",
            key: "actions",
            render: (u: any) => <Link to={`/admin/users/${u._id}`}>Modifier</Link>,
        },
    ];

    return { documentColumns, eventColumns, postColumns, userColumns };
};

const ColumnContent = styled.div`
    display: grid;
    grid: auto / 24px auto;
    grid-gap: 8px;
    align-items: center;
`;

export default Dashboard;
