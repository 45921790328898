import React from "react";
import { Button, Divider } from "antd";
import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
// import { useOvermind } from "store";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import OZPageHeader from "components/OZPageHeader";
import OZTable from "components/OZTable";
import OZStatus from "components/OZStatus";

import { pluralNames } from "helpers";
import { OZTableColumnType } from "helpers/types";
import useSearch from "helpers/useSearch";

const Orders = () => {
    // const { actions, state } = useOvermind();
    const columns = useColumns();
    // const { orders } = state;

    // useEffect(() => {
    //     actions.getOrders();
    // }, []);

    const quantity = pluralNames({ length: data.length, text: "commandes" });

    return (
        <Container>
            <OZPageHeader title="Commandes" quantity={quantity} />
            <OZTable columns={columns} data={data} />
        </Container>
    );
};

const useColumns = () => {
    const history = useHistory();

    const columns: OZTableColumnType[] = [
        {
            title: "#",
            key: "order",
            sorter: (a: any, b: any) => a.orderNumber - b.orderNumber,
            defaultSortOrder: "descend",
            sortDirections: ["descend", "ascend"],
            render: (p: any) => `# ${p.orderNumber}`,
        },
        {
            title: "Produit",
            dataIndex: "product",
            ...useSearch("product"),
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Statut",
            key: "status",
            filters: [
                { text: "Annulée", value: "canceled" },
                { text: "Complétée", value: "completed" },
                { text: "En attente", value: "pending" },
                { text: "En cours", value: "processing" },
                { text: "Remboursée", value: "refunded" },
            ],
            onFilter: (value: any, p: any) => p.status === value,
            render: (p: any) => <OZStatus type={p.status} />,
        },
        {
            title: "Commandé par",
            dataIndex: "purchasedBy",
            ...useSearch("purchasedBy"),
        },
        {
            title: "Total",
            key: "total",
            render: (p: any) => `${p.total}€`,
        },
        {
            title: "Actions",
            key: "actions",
            align: "right",
            fixed: "right",
            render: (p: any, i: any) => (
                <span key={i}>
                    <Button
                        icon={<EyeOutlined />}
                        onClick={() =>
                            history.push({
                                pathname: `/admin/shop/orders/${p.key}`,
                                state: { ...p },
                            })
                        }
                    />
                    <Divider type="vertical" />
                    <Button icon={<MoreOutlined />} onClick={() => {}} />
                </span>
            ),
        },
    ];

    return columns;
};

const data = [
    {
        key: "id01",
        orderNumber: "001",
        product: "Place pour Disneyland Paris",
        date: "21/10/2019",
        status: "canceled",
        purchasedBy: "Steven Persia",
        total: 45.3,
    },
    {
        key: "id02",
        orderNumber: "002",
        product: "Place pour Disneyland Paris",
        date: "22/10/2019",
        status: "completed",
        purchasedBy: "Steven Persia",
        total: 23,
    },
    {
        key: "id03",
        orderNumber: "003",
        product: "Place pour Disneyland Paris",
        date: "23/10/2019",
        status: "pending",
        purchasedBy: "Steven Persia",
        total: 98,
    },
    {
        key: "id04",
        orderNumber: "004",
        product: "Place pour Disneyland Paris",
        date: "24/10/2019",
        status: "processing",
        purchasedBy: "Steven Persia",
        total: 23,
    },
    {
        key: "id05",
        orderNumber: "005",
        product: "Place pour Disneyland Paris",
        date: "25/10/2019",
        status: "refunded",
        purchasedBy: "Steven Persia",
        total: 93,
    },
];

const Container = styled.div`
    padding: 24px;
    background: #fff;
`;

export default Orders;
