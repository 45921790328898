import React, { useEffect, useState } from "react";
import { Avatar, Button, Divider, Dropdown, Menu } from "antd";
import { EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { useOvermind } from "store";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { jsonToCSV } from "react-papaparse";

// Components
import OZPageHeader from "components/OZPageHeader";
import OZTable from "components/OZTable";
import OZStatus from "components/OZStatus";

import { pluralNames } from "helpers";
import { OZTableColumnType } from "helpers/types";

const Subventions = () => {
    const { actions, state } = useOvermind();

    const columns = useColumns();
    const { subventions } = state;

    useEffect(() => {
        firstRender();

        return () => {
            actions.subventions.resetSubventions();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const firstRender = async () => {
        await actions.subventions.getSubventions();
    };

    const quantity = pluralNames({ length: subventions.list.length, text: "subvention" });

    return (
        <Container>
            <OZPageHeader
                title="Subventions"
                quantity={quantity}
                primaryText="Exporter"
                primaryAction={async () => {
                    const sub = await actions.getSubventionsCSV();
                    const parse = await jsonToCSV(sub);

                    const csvData = new Blob([parse], { type: "text/csv" });
                    const csvURL = window.URL.createObjectURL(csvData);
                    const tempLink = document.createElement("a");
                    tempLink.href = csvURL;
                    tempLink.setAttribute("download", "subventions.csv");
                    tempLink.click();
                }}
                secondaryAction={() => actions.subventions.resetAllWallets()}
                secondaryText="Réinitialiser les portefeuilles"
                confirmSecondary
            />
            <OZTable
                columns={columns}
                data={subventions.list}
                size="small"
                pagination={{ pageSize: 10 }}
            />
        </Container>
    );
};

const useColumns = () => {
    const { actions } = useOvermind();
    const history = useHistory();

    const update = async (id: string, status: string) => {
        actions.setIsFetching(true);
        await actions.subventions.updateSubvention({ id, data: { status } });
        await actions.subventions.getSubventions();
        actions.setIsFetching(false);
    };

    const columns: OZTableColumnType[] = [
        {
            title: "#",
            key: "number",
            render: (s: any) => `#${s.number}`,
            defaultSortOrder: "descend",
            sorter: (a: any, b: any) => a.number - b.number,
        },
        {
            title: "Date",
            key: "createdAt",
            render: (s: any) => format(new Date(s.createdAt), "dd/MM/yyyy"),
        },
        {
            title: "Salarié",
            key: "user",
            render: (s: any) =>
                s.user && (
                    <StyledUser>{`${s.user.firstName} ${s.user.lastName}`}</StyledUser>
                ),
        },
        {
            title: "Montant",
            key: "amount",
            render: (s: any) => `${s.amount}€`,
        },
        {
            title: "Statut",
            key: "status",
            filters: [
                { text: "Remboursement accepté", value: "ACCEPTED" },
                { text: "Non subventionnable", value: "REJECTED" },
                { text: "En attente", value: "PENDING" },
            ],
            onFilter: (value: any, s: any) => s.status === value,
            render: (s: any) => <OZStatus type={s.status.toLowerCase()} />,
        },
        {
            title: "Type",
            key: "type",
            filters: [
                { text: "Chèque", value: "CHECK" },
                { text: "Virement bancaire", value: "BANKTRANSFER" },
            ],
            onFilter: (value: any, s: any) => s.type === value,
            render: (s: any) => (s.type === "CHECK" ? "Chèque" : "Virement bancaire"),
        },
        {
            title: "Géré par",
            key: "managedBy",
            render: (s: any) =>
                s.managedBy && (
                    <StyledUser>
                        {`${s.managedBy.firstName} ${s.managedBy.lastName}`}
                    </StyledUser>
                ),
        },
        {
            title: "Actions",
            key: "actions",
            align: "right",
            fixed: "right",
            render: (s: any) => (
                <span key={s._id}>
                    <Button
                        icon={<EyeOutlined />}
                        onClick={() =>
                            history.push({
                                pathname: `/admin/subventions/${s._id}`,
                            })
                        }
                    />
                    <Divider type="vertical" />
                    <Dropdown
                        disabled={s.status.toLowerCase() !== "pending"}
                        overlay={
                            <Menu>
                                <Menu.Item onClick={() => update(s._id, "ACCEPTED")}>
                                    Accepter la demande
                                </Menu.Item>
                                <Menu.Item onClick={() => update(s._id, "REJECTED")}>
                                    Refuser la demande
                                </Menu.Item>
                            </Menu>
                        }
                        placement="bottomRight"
                        trigger={["click"]}
                    >
                        <Button icon={<MoreOutlined />} />
                    </Dropdown>
                </span>
            ),
        },
    ];

    return columns;
};

const Container = styled.div`
    padding: 24px;
    background: #fff;
`;

const StyledUser = styled.div`
    display: grid;
    grid: auto / auto 1fr;
    grid-gap: 4px;
    align-items: center;
`;

export default Subventions;
