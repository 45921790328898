import React, { useEffect, useState } from "react";
import { Button, Divider, Popconfirm } from "antd";
import { EditOutlined, DeleteTwoTone, InfoCircleTwoTone } from "@ant-design/icons";
import { useOvermind } from "store";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import OZPageHeader from "components/OZPageHeader";
import OZTable from "components/OZTable";
import { pluralNames, truncateText } from "helpers";
import { OZTableColumnType } from "helpers/types";
import BasicCategories from "templates/BasicCategories";
import OZDrawer from "components/OZDrawer";
import useSearch from "helpers/useSearch";

const Medias = () => {
    const { actions, state } = useOvermind();
    const [visible, setVisible] = useState(false);
    const history = useHistory();
    const columns = useColumns();
    const { medias } = state;
    const { media } = state.categories;

    useEffect(() => {
        actions.medias.getMedias();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const quantity = pluralNames({ length: medias.list.length, text: "media" });

    return (
        <Container>
            <OZPageHeader
                title="Médias"
                quantity={quantity}
                primaryAction={() => history.push("/admin/medias/new")}
                primaryText="Ajouter une galerie"
                secondaryAction={() => setVisible(true)}
            />
            <OZTable columns={columns} data={medias.list} />
            <OZDrawer
                title="Catégories des galeries"
                onClose={() => setVisible(false)}
                visible={visible}
                width={400}
            >
                <BasicCategories
                    categories={media.list}
                    getElements={actions.medias.getMedias}
                    type="media"
                />
            </OZDrawer>
        </Container>
    );
};

const useColumns = () => {
    const { actions } = useOvermind();
    const history = useHistory();

    const columns: OZTableColumnType[] = [
        {
            title: "Titre",
            dataIndex: "name",
            key: "name",
            render: (t: any) => truncateText(t, 70),
            ...useSearch("name"),
        },
        {
            title: "Catégorie",
            key: "category",
            render: (m: any) => m.category && m.category.name,
        },
        {
            title: "Actions",
            key: "actions",
            align: "right",
            fixed: "right",
            render: (m: any) => (
                <span>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => history.push(`/admin/medias/${m._id}`)}
                    />
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Êtes-vous sûr(e) de vouloir supprimer cette galerie ?"
                        onConfirm={() => actions.medias.deleteMedia(m._id)}
                        placement="left"
                        icon={<InfoCircleTwoTone twoToneColor="red" />}
                        okText="Supprimer"
                        okType="danger"
                        cancelText="Annuler"
                    >
                        <Button
                            ghost
                            icon={<DeleteTwoTone twoToneColor="red" />}
                            type="danger"
                        />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return columns;
};

const Container = styled.div`
    padding: 24px;
    background: #fff;
`;

export default Medias;
