import { Operator, pipe, tryCatch } from "overmind";
import * as o from "./operators";
import * as oCommon from "store/operators";

const COMMON_ERROR_MESSAGE = "Une erreur est survenue.";

export const getEvent: Operator<string> = tryCatch({
    try: o.getEvent(),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const getEvents: Operator = pipe(
    oCommon.setIsLoading(true),
    o.getEvents(),
    oCommon.setIsLoading(false)
);

export const getEventsByCategory: Operator<string> = o.getEventsByCategory();

export const createEvent: Operator<string> = tryCatch({
    try: pipe(
        o.createEvent(),
        o.getEvents(),
        oCommon.toast({ status: "success", text: "Evénement créé." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const updateEvent: Operator<any> = tryCatch({
    try: pipe(
        o.updateEvent(),
        o.getEvents(),
        oCommon.toast({ status: "success", text: "Evénement mis à jour." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const deleteEvent: Operator<string> = tryCatch({
    try: pipe(
        o.deleteEvent(),
        o.getEvents(),
        oCommon.toast({ status: "success", text: "Evénement supprimé." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const resetSelectedEvent: Operator = o.resetSelectedEvent();
