import React, { useState } from "react";

import { Card, Typography } from "antd";
import styled from "styled-components";
import format from "date-fns/format";
import OZModal from "components/OZModal";

const { Text, Title } = Typography;

const Post = ({ content }: any) => {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Card hoverable onClick={() => setVisible(true)}>
                <Image
                    style={{
                        backgroundImage: `url(${content.picture && content.picture.url})`,
                    }}
                ></Image>
                <Head>
                    <StyledTitle level={4}>{content.name}</StyledTitle>
                    <Text type="secondary">
                        {`Publié le ${format(new Date(content.createdAt), "dd/MM/yyyy")}`}
                        {content.category && ` - ${content.category.name}`}
                    </Text>
                </Head>
            </Card>
            <OZModal visible={visible} setVisible={setVisible}>
                <Image
                    style={{
                        backgroundImage: `url(${content.picture && content.picture.url})`,
                    }}
                ></Image>
                <Head>
                    <StyledTitle level={4}>{content.name}</StyledTitle>
                    <Text type="secondary">
                        {`Publié le ${format(new Date(content.createdAt), "dd/MM/yyyy")}`}
                        {content.category && ` - ${content.category.name}`}
                    </Text>
                </Head>
                <Content
                    dangerouslySetInnerHTML={{
                        __html: content.description,
                    }}
                />
            </OZModal>
        </>
    );
};

const Image = styled.div`
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 250px;
    margin-bottom: 16px;
`;

const Head = styled.div`
    margin-bottom: 16px;
`;

const StyledTitle = styled(Title)`
    margin-bottom: 0 !important;
`;

const Content = styled.div`
    text-align: justify;
    padding-bottom: 8px;
`;

export default Post;
