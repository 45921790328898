type StateType = {
    document: any;
    event: any;
    media: any;
    post: any;
};

export const state: any = {
    document: { list: [], length: 0 },
    event: { list: [], length: 0 },
    media: { list: [], length: 0 },
    post: { list: [], length: 0 },
};
