import React from "react";
import { useOvermind } from "store";
import styled from "styled-components";

const Logo = () => {
    const { state } = useOvermind();
    const { appName, logo } = state.settings.general;

    return (
        <StyledLogo>
            {logo && logo.url && <img alt={appName} src={logo.url} width="150" />}
            <h1>{appName}</h1>
        </StyledLogo>
    );
};

const StyledLogo = styled.div`
    text-align: center;
    margin: 0 auto 20px auto;

    img {
        margin-bottom: 10px;
    }
`;

export default Logo;
