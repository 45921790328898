import React, { useEffect } from "react";
import { useOvermind } from "store";
import { useHistory, useParams } from "react-router-dom";
import format from "moment";

// Components
import OZInput from "components/OZInput";
import OZTextEditor from "components/OZTextEditor";

// Templates
import BasicForm from "templates/BasicForm";
import OZTable from "../../../components/OZTable";
import OZSelectTag from "../../../components/OZSelectTag";

type ParamsType = {
    id?: string;
};

const Box = () => {
    const { actions, state } = useOvermind();
    const history = useHistory();
    const { id = "" }: ParamsType = useParams();
    const sideFields = useFields();
    const { selected } = state.boxes;

    useEffect(() => {
        fetchData();

        return () => {
            actions.boxes.resetSelectedBox();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        actions.setIsFetching(true);
        if (id && id !== "new") await actions.boxes.getBox(id);
        actions.setIsFetching(false);
    };

    const onSubmit = async (data: any) => {
        if (id === "new") await actions.boxes.createBox(data);
        else await actions.boxes.updateBox({ id, data });
        history.push("/admin/boxes");
    };

    const onDelete = async () => {
        id && (await actions.boxes.deleteBox(id));
        history.push("/admin/boxes");
    };

    const initialValues = {
        title: id !== "new" ? selected.title : "",
        createdAt: id !== "new" ? format(selected.createdAt) : format(new Date()),
        type: id !== "new" && selected.type ? selected.type : "",
        options: id !== "new" ? selected.options : null,
    };

    const columns = [
        {
            title: "Utilisateurs",
            key: "user",
            render: (b: any) => (b.user && b.user._id ? b.user.name : "Anonyme"),
        },
        {
            title: "Réponses",
            key: "answer",
            render: (b: any) => b.answer,
        },
    ];

    const statsColumns = [
        {
            title: "Options",
            key: "option",
            render: (s: any) => s.option,
        },
        {
            title: "Nombre de réponses",
            key: "count",
            render: (s: any) => s.count,
        },
    ];

    const renderStats = () => {
        const arr: any = [];
        const count: any = {};

        for (let i = 0; i < selected.answers.length; i++) {
            const answer = selected.answers[i];
            count[answer.answer] = count[answer.answer] ? count[answer.answer] + 1 : 1;
        }

        const objectArr = Object.entries(count);
        objectArr.forEach(([key, value]) => arr.push({ option: key, count: value }));

        return arr;
    };

    return (
        <BasicForm
            deleteText="cette boîte"
            id={id}
            initialValues={initialValues}
            mainFields={
                <>
                    <OZInput
                        label="Titre"
                        name="title"
                        placeholder="Titre"
                        rules={validationRules.title}
                    />
                    <OZSelectTag label="Options (non obligatoire)" name="options" />
                    <OZTable
                        bordered
                        columns={columns}
                        data={selected.answers}
                        size="small"
                        title="Réponses"
                        pagination={{ pageSize: 10 }}
                    />

                    {selected.options && selected.options.length > 0 && (
                        <OZTable
                            bordered
                            columns={statsColumns}
                            data={renderStats()}
                            size="small"
                            title="Statistiques (fonctionne uniquement si il y a des options)"
                            pagination={{ pageSize: 10 }}
                        />
                    )}
                </>
            }
            onDelete={onDelete}
            onSubmit={onSubmit}
            sideFields={sideFields}
        />
    );
};

const useFields = () => {
    const types = [
        { label: "Boîte à idées", value: "IDEA" },
        { label: "Boîte à messages", value: "MESSAGE" },
        { label: "Boîte à sondages", value: "SURVEY" },
    ];

    const sideFields = [
        {
            name: "type",
            label: "Type",
            values: types,
            rules: validationRules.type,
            type: "select",
        },
        {
            name: "createdAt",
            disabled: true,
            label: "Date de publication",
            style: { width: "100%" },
            type: "date",
        },
    ];

    return sideFields;
};

const validationRules = {
    title: [{ required: true, message: "Veuillez indiquer un titre." }],
    type: [{ required: true, message: "Veuillez sélectionner un type." }],
};

export default Box;
