import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";
import { useOvermind } from "store";
import { Helmet } from "react-helmet";

import { getThemeColor, changeAntdTheme } from "helpers/theme/util";

import "antd/dist/antd.css";
import "remixicon/fonts/remixicon.css";
import "moment/locale/fr";

import Access from "./views/common/Access";
import Admin from "./views/admin";
import Site from "./views/site";
import ErrorBoundary from "views/common/ErrorBoundary";

const App: React.FC = () => {
    const { state } = useOvermind();
    const { status, role } = state.user;
    const { appName, primaryColor, secondaryColor } = state.settings.general;

    useEffect(() => {
        changeAntdTheme(getThemeColor(primaryColor, secondaryColor));
        document.documentElement.style.setProperty("--base-blend", primaryColor);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primaryColor, secondaryColor]);

    return state.isLoading2 ? (
        <div />
    ) : (
        <ErrorBoundary>
            <Main>
                <Helmet>
                    <title>{state.settings.general ? appName : "Digital CSE"}</title>
                </Helmet>
                <Switch>
                    <Route path="/admin">
                        {status && role === "ADMIN" ? <Admin /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/access">
                        {!status ? <Access /> : <Redirect to="/" />}
                    </Route>

                    <Route path="/:page">
                        {status ? <Site /> : <Redirect to="/access" />}
                    </Route>

                    <Route exact path="/">
                        {status ? <Site /> : <Redirect to="/access" />}
                    </Route>
                </Switch>
            </Main>
        </ErrorBoundary>
    );
};

const Main = styled.div`
    height: 100%;
`;

export default App;
