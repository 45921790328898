import React from "react";
import { Form, InputNumber } from "antd";

import { OZInputNumberType } from "helpers/types";

const OZInputNumber = ({
    disabled = false,
    icon,
    label,
    min,
    max,
    name,
    placeholder,
    rules,
    size = "large",
    type = "text",
}: OZInputNumberType) => {
    return (
        <Form.Item label={label} name={name} rules={rules}>
            <InputNumber
                disabled={disabled}
                min={min}
                max={max}
                prefix={icon}
                placeholder={placeholder}
                size={size}
                style={{ width: "100%" }}
                type={type}
            />
        </Form.Item>
    );
};

export default OZInputNumber;
