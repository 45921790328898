import axios from "axios";
import { AxiosPostType } from "../helpers/types";
import Cookies from "js-cookie";

axios.interceptors.request.use(config => {
    let token = Cookies.get("digitalcse") || "";

    config.headers.Authorization = token;
    config.baseURL = process.env.REACT_APP_BACK_URI;

    return config;
});

// GENERIC GET METHOD FOR AXIOS
export const get = async (url: string) => {
    return axios
        .get(url)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
};

// GENERIC POST METHOD FOR AXIOS
export const post = async ({ url, data }: AxiosPostType) => {
    return axios
        .post(url, data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            throw err.response && err.response.data;
        });
};

// GENERIC PUT METHOD FOR AXIOS
export const put = async ({ url, data }: AxiosPostType) => {
    return axios
        .put(url, data)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            throw err.response && err.response.data;
        });
};

// GENERIC DELETE METHOD FOR AXIOS
export const del = async (url: string) => {
    return axios
        .delete(url)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            throw err.response && err.response.data;
        });
};
