import React from "react";
import { Button, Card, Col, Row } from "antd";
import { useLocation } from "react-router-dom";

// COMPONENTS
import OZForm from "components/OZForm";
import OZSelectSearch from "components/OZSelectSearch";
import OZInput from "components/OZInput";
import OZTextArea from "components/OZTextArea";
import Sidebar from "./Sidebar";
import format from "moment";
import OZTextEditor from "../../../components/OZTextEditor";

const New = () => {
    const location = useLocation();

    const initialValues = {
        to: location.state.to || null,
        subject: location.state.subject || "",
    };

    return (
        <Card>
            <Row align="top" gutter={[32, 8]} justify="center">
                <Sidebar />
                <Col xs={24} sm={24} md={19} lg={19} xl={19} xxl={19}>
                    <Card bordered>
                        <OZForm initialValues={initialValues}>
                            <OZSelectSearch
                                placeholder="Destinataire"
                                name="to"
                                values={users}
                            />
                            <OZInput placeholder="Sujet" name="subject" />
                            <OZTextEditor name="text" />
                            <Button type="primary">Envoyer</Button>{" "}
                            <Button>Enregistrer en tant que brouillon</Button>
                        </OZForm>
                    </Card>
                </Col>
            </Row>
        </Card>
    );
};

const users = [
    { label: "Samuel EDOM", value: "samuel@digitalcse.fr" },
    { label: "Steven PERSIA", value: "steven@digitalcse.fr" },
];

export default New;
