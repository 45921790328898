import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Row } from "antd";
import { PhoneTwoTone, MessageTwoTone, PushpinTwoTone } from "@ant-design/icons";
import { useOvermind } from "store";
import styled from "styled-components";

// Components
import Event from "blocks/Event";
import Post from "blocks/Post";
import GDPRConsent from "blocks/GDPRConsent";

// Medias
import Hour from "assets/images/hour.jpg";
import Contact from "assets/images/contact.jpg";
import OZModal from "../../components/OZModal";
import Box from "../../modules/Boxes/site";

const { Meta } = Card;

const Home = () => {
    const { actions, state } = useOvermind();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const history = useHistory();

    const { calendar, posts } = state;
    const { primaryColor } = state.settings.general;
    const {
        address,
        availabilities,
        email,
        phoneNumber,
    } = state.settings.general.contact;

    useEffect(() => {
        actions.posts.getPosts();
        actions.calendar.getEvents();
        actions.boxes.getBoxes();

        state.user && !state.user.gdprConsent && setIsModalVisible(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const boxTypes: any = {
        IDEA: "Boîte à idées",
        MESSAGE: "Boîte à messages",
        SURVEY: "Boîte à sondages",
    };

    const hasBoxIdea =
        state.settings.modules.boxes.status &&
        state.boxes.length > 0 &&
        state.boxes.list.find((b) => b.type === "IDEA");

    const hasBoxMessage =
        state.settings.modules.boxes.status &&
        state.boxes.length > 0 &&
        state.boxes.list.find((b) => b.type === "MESSAGE");

    const hasBoxSurvey =
        state.settings.modules.boxes.status &&
        state.boxes.length > 0 &&
        state.boxes.list.find((b) => b.type === "SURVEY");

    return (
        <Row gutter={[16, 16]}>
            {isModalVisible && (
                <OZModal
                    closable={false}
                    maskClosable={false}
                    visible={isModalVisible}
                    title="Données personnelles"
                    footer={
                        <>
                            <Button
                                onClick={() => actions.logout()}
                                ghost
                                size="large"
                                type="primary"
                            >
                                Je refuse
                            </Button>
                            <Button
                                onClick={async () => {
                                    await actions.users.updateMyself({
                                        data: { gdprConsent: true },
                                    });
                                    await actions.getAuthedUser(state.user.token);
                                    setIsModalVisible(false);
                                }}
                                size="large"
                                type="primary"
                            >
                                J'accepte
                            </Button>
                        </>
                    }
                >
                    <GDPRConsent />
                </OZModal>
            )}
            <Col xs={24} md={17}>
                <Row gutter={[16, 16]}>
                    {state.settings.modules.posts.status && posts.list.length > 0 && (
                        <Col span={24}>
                            <Post content={posts.list.length > 0 && posts.list[0]} />
                        </Col>
                    )}

                    <Col xs={24} md={24} lg={12}>
                        <Card
                            cover={
                                <div className="img-wrapper">
                                    <img alt="horaires" src={Hour} />
                                </div>
                            }
                            title="Horaires d'ouvertures"
                        >
                            <Meta
                                description={
                                    <p style={{ textAlign: "center" }}>
                                        {availabilities
                                            ? availabilities
                                            : "Non renseigné."}
                                    </p>
                                }
                            />
                        </Card>
                    </Col>
                    <Col xs={24} md={24} lg={12}>
                        <Card
                            cover={
                                <div className="img-wrapper">
                                    <img alt="contact" src={Contact} />
                                </div>
                            }
                            title="Coordonnées du CSE"
                        >
                            <Meta
                                description={
                                    <div>
                                        {!phoneNumber && !email && <p>Non renseigné.</p>}

                                        <p
                                            style={{
                                                display: "flex",
                                                alignContent: "center",
                                                justifyContent: "space-around",
                                            }}
                                        >
                                            {phoneNumber && (
                                                <div>
                                                    <PhoneTwoTone
                                                        twoToneColor={primaryColor}
                                                    />{" "}
                                                    {phoneNumber}
                                                </div>
                                            )}

                                            {email && (
                                                <div>
                                                    <MessageTwoTone
                                                        twoToneColor={primaryColor}
                                                    />{" "}
                                                    {email}
                                                </div>
                                            )}
                                        </p>
                                    </div>
                                }
                            />
                        </Card>
                    </Col>

                    {state.settings.modules.calendar.status && calendar.list.length > 0 && (
                        <Col span={24}>
                            <Card title="Dernier événement ajouté">
                                <Event
                                    content={calendar.list.length > 0 && calendar.list[0]}
                                ></Event>
                            </Card>
                        </Col>
                    )}
                </Row>
            </Col>
            <Col xs={24} md={7}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <StyledCardAccess title="Accès rapide">
                            {/*<Button block size="large" type="primary">
                                <a
                                    href="https://www.ticketmaster.fr/fr/pro/cpn/2/idtier/14443880"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    Billetterie
                                </a>
                            </Button>*/}
                            {state.settings.modules.subventions.status && (
                                <Button
                                    block
                                    ghost
                                    size="large"
                                    type="primary"
                                    onClick={() => history.push(`/subventions`)}
                                >
                                    Demander une subvention
                                </Button>
                            )}
                            <Button
                                block
                                ghost
                                size="large"
                                type="primary"
                                onClick={() => history.push(`/user/${state.user._id}`)}
                            >
                                Mon profil
                            </Button>
                        </StyledCardAccess>
                    </Col>

                    {hasBoxIdea && (
                        <Col span={24}>
                            <Box
                                id={hasBoxIdea._id}
                                options={hasBoxIdea.options}
                                title={hasBoxIdea.title}
                                type={boxTypes[hasBoxIdea.type]}
                            />
                        </Col>
                    )}

                    {hasBoxSurvey && (
                        <Col span={24}>
                            <Box
                                id={hasBoxSurvey._id}
                                options={hasBoxSurvey.options}
                                title={hasBoxSurvey.title}
                                type={boxTypes[hasBoxSurvey.type]}
                            />
                        </Col>
                    )}

                    {hasBoxMessage && (
                        <Col span={24}>
                            <Box
                                id={hasBoxMessage._id}
                                options={hasBoxMessage.options}
                                title={hasBoxMessage.title}
                                type={boxTypes[hasBoxMessage.type]}
                            />
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

const StyledCardAccess = styled(Card)`
    .ant-card-body {
        display: grid;
        grid-gap: 8px;
    }
`;

export default Home;
