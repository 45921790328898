import React from "react";
import { Modal } from "antd";

const OZModal = ({ children, title, visible, setVisible, reset, ...props }: any) => {
    return (
        <Modal
            closable
            visible={visible}
            title={title}
            afterClose={() => reset && reset()}
            onCancel={() => setVisible(false)}
            footer={null}
            {...props}
        >
            {children}
        </Modal>
    );
};

export default OZModal;
