import React from "react";
import { Button } from "antd";
import { useOvermind } from "store";

import OZTextEditor from "components/OZTextEditor";
import OZForm from "components/OZForm";
import OZInputNumber from "components/OZInputNumber";

const Subventions = () => {
    const { actions, state } = useOvermind();
    const initialValues = {
        subventions: {
            amountByUser: state.settings.subventions.amountByUser,
            rules: state.settings.subventions.rules,
        },
    };

    const onSubmit = (data: any) => actions.updateSettings(data);

    return (
        <OZForm initialValues={initialValues} onSubmit={onSubmit}>
            <OZInputNumber
                label="Montant par utilisateur"
                name={["subventions", "amountByUser"]}
            />
            <OZTextEditor
                label="Conditions de subventionnement"
                name={["subventions", "rules"]}
            />
            <div style={{ textAlign: "right", marginTop: 30 }}>
                <Button type="primary" size="large" htmlType="submit">
                    Enregistrer
                </Button>
            </div>
        </OZForm>
    );
};

export default Subventions;
