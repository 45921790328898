import React from "react";
import { Checkbox, Form } from "antd";

const OZCheckbox = ({ disabled, name, text }: any) => {
    return (
        <Form.Item name={name} valuePropName="checked">
            <Checkbox disabled={disabled}>{text}</Checkbox>
        </Form.Item>
    );
};

export default OZCheckbox;
