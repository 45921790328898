import React from "react";
import { Calendar } from "antd";
import { OZCalendarType } from "helpers/types";

const OZCalendar = ({
    dateCellRender,
    dateFullCellRender,
    fullscreen = false,
    onSelect,
}: OZCalendarType) => {
    return (
        <Calendar
            dateCellRender={dateCellRender}
            dateFullCellRender={dateFullCellRender}
            fullscreen={fullscreen}
            onSelect={onSelect}
        />
    );
};

export default OZCalendar;
