import React, { useEffect, useState } from "react";
import { useOvermind } from "store";
import { Avatar, Badge, Button, Card, Col, List, Popover, Row, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";
import format from "date-fns/format";

// Components
import OZCalendar from "components/OZCalendar";

const { Option } = Select;

const Calendar = () => {
    const { actions, state } = useOvermind();

    const { list } = state.calendar;
    const { isLoading } = state;
    const categories = state.categories.event.list;
    const { primaryColor } = state.settings.general;

    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({ category: "" });
    const [results, setResults] = useState<any>([]);

    useEffect(() => {
        actions.calendar.getEvents();
        actions.categories.getCategories("event");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const dates = [...list];
        setResults(dates.sort(sortByStartDate));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.length > 0]);

    const sortByStartDate = (a: any, b: any) => {
        const dateA = moment(a.startDate, "DD/MM/YYYY").valueOf();
        const dateB = moment(b.startDate, "DD/MM/YYYY").valueOf();

        let comparison = 0;

        if (dateA > dateB) comparison = 1;
        else if (dateA < dateB) comparison = -1;

        return comparison * -1;
    };

    const dateFullCellRender = (value: any) => {
        let calendarDate = moment(value, "DD/MM/YYYY");
        calendarDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        calendarDate.toISOString();
        calendarDate.format();

        return state.calendar.list.map((e: any) => {
            const startDate = moment(e.startDate, "DD/MM/YYYY");
            const endDate = moment(e.endDate, "DD/MM/YYYY");

            if (
                startDate.valueOf() <= calendarDate.valueOf() &&
                endDate.valueOf() >= calendarDate.valueOf()
            ) {
                return <Badge dot />;
            } else {
                return null;
            }
        });
    };

    const onSelect = (value: any) => {
        const foundEvents: any[] = [];

        let date = moment(value, "DD/MM/YYYY");
        date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        date.toISOString();
        date.format();

        for (let i = 0; i < state.calendar.list.length; i++) {
            const event = state.calendar.list[i];
            const startDate = moment(event.startDate, "DD/MM/YYYY");
            const endDate = moment(event.endDate, "DD/MM/YYYY");

            if (
                startDate.valueOf() <= date.valueOf() &&
                endDate.valueOf() >= date.valueOf()
            ) {
                foundEvents.push(event);
            }
        }

        return setResults(foundEvents);
    };

    const search = ({ category }: any) => {
        let newItems = [...list];

        if (category) {
            newItems = newItems.filter((i: any) => i.category._id.includes(category));
        }

        setResults(newItems.sort(sortByStartDate));
        setCurrentPage(1);
    };

    const header = (
        <Row align="middle" gutter={[8, 8]} justify="end">
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                {results.length} événements trouvés
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <Popover
                    content={
                        <div style={{ width: 300 }}>
                            <OZCalendar
                                dateCellRender={dateFullCellRender}
                                onSelect={onSelect}
                            />
                        </div>
                    }
                    trigger="click"
                >
                    <Button block>Choisir une date</Button>
                </Popover>
            </Col>
            <Col xs={24} sm={24} md={2} lg={2} xl={2} xxl={2}>
                <Button block disabled>
                    OU
                </Button>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <Select
                    defaultValue=""
                    onChange={(e: any) => setFilters({ ...filters, category: e })}
                    placeholder="Catégories"
                    style={{ width: "100%" }}
                >
                    <Option value="">Toutes les catégories</Option>
                    {categories.map((c: any) => (
                        <Option value={c._id}>{c.name}</Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <Button
                    block
                    onClick={() => {
                        search({
                            category: filters.category,
                        });
                    }}
                    type="primary"
                    icon={<SearchOutlined />}
                >
                    Rechercher
                </Button>
            </Col>
        </Row>
    );

    return (
        <Card>
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    current: currentPage,
                    onChange: (page) => setCurrentPage(page),
                    pageSize: 10,
                }}
                dataSource={results}
                header={header}
                loading={isLoading}
                renderItem={(item: any) => {
                    return (
                        <List.Item
                            key={item._id}
                            actions={[
                                `Publié le ${format(
                                    new Date(item.createdAt),
                                    "dd/MM/yyyy"
                                )}`,
                                item.category && item.category.name,
                            ]}
                            extra={
                                <Row
                                    gutter={[8, 8]}
                                    justify="start"
                                    align="middle"
                                    style={{ textAlign: "center" }}
                                >
                                    <Col md={24} lg={24}>
                                        <Avatar
                                            shape="square"
                                            size={64}
                                            style={{ backgroundColor: primaryColor }}
                                        >
                                            {item.startDate.slice(0, 5)}
                                        </Avatar>
                                    </Col>

                                    <Col md={24} lg={24}>
                                        <span>AU</span>
                                    </Col>

                                    <Col md={24} lg={24}>
                                        <Avatar
                                            shape="square"
                                            size={64}
                                            style={{ backgroundColor: primaryColor }}
                                        >
                                            {item.endDate.slice(0, 5)}
                                        </Avatar>
                                    </Col>
                                </Row>
                            }
                        >
                            <List.Item.Meta
                                title={item.name}
                                description={
                                    <Content
                                        dangerouslySetInnerHTML={{
                                            __html: item.description,
                                        }}
                                    />
                                }
                            />
                        </List.Item>
                    );
                }}
            />
        </Card>
    );
};

// Styles
const Content = styled.div`
    text-align: justify;
    padding-bottom: 8px;
`;

const StyledCard = styled(Card)`
    display: grid;
    grid: auto / 1fr;
    grid-gap: 8px;
`;

const StyledSideCard = styled(Card)`
    display: grid;
    grid: auto / 1fr;
    grid-gap: 8px;

    .ant-divider-horizontal {
        margin: 0;
    }

    .ant-card-body,
    .ant-picker-calendar .ant-picker-panel .ant-picker-body {
        padding: 0;
    }

    .ant-picker-calendar-mode-switch {
        display: none;
    }

    .ant-picker-calendar-date-content {
        position: absolute;
        top: -4px;
        right: 1px;
    }

    .ant-picker-calendar-date-content .ant-badge:not(:first-child) {
        display: none;
    }
`;

export default Calendar;
