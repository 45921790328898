import React from "react";
import { Form, Input } from "antd";

import { OZInputTextAreaType } from "helpers/types";

const OZTextArea = ({
    disabled = false,
    label,
    name,
    placeholder,
    rows,
    rules,
}: OZInputTextAreaType) => {
    return (
        <Form.Item label={label} name={name} rules={rules}>
            <Input.TextArea disabled={disabled} placeholder={placeholder} rows={rows} />
        </Form.Item>
    );
};

export default OZTextArea;
