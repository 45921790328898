import React, { useEffect } from "react";
import OZPageHeader from "../../../components/OZPageHeader";
import OZTable from "../../../components/OZTable";
import { useOvermind } from "../../../store";
import { useHistory } from "react-router-dom";
import { OZTableColumnType } from "../../../helpers/types";
import { Button, Divider, Popconfirm } from "antd";
import { DeleteTwoTone, EyeOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import styled from "styled-components";
import { pluralNames } from "../../../helpers";
import OZBox from "./OZBox";

const Boxes = () => {
    const { actions, state } = useOvermind();

    const history = useHistory();
    const columns = useColumns();
    const { boxes } = state;

    useEffect(() => {
        firstRender();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const firstRender = async () => {
        await actions.boxes.getBoxes();
    };

    const quantity = pluralNames({ length: boxes.list.length, text: "boîte" });

    return (
        <Container>
            <OZPageHeader
                title="Boîtes"
                quantity={quantity}
                primaryText="Ajouter une boîte"
                primaryAction={() => history.push("/admin/boxes/new")}
            />
            <OZTable
                columns={columns}
                data={boxes.list}
                size="small"
                pagination={{ pageSize: 10 }}
            />
        </Container>
    );
};

const useColumns = () => {
    const { actions } = useOvermind();
    const history = useHistory();

    const update = async (id: string, status: string) => {
        actions.setIsFetching(true);
        await actions.subventions.updateSubvention({ id, data: { status } });
        await actions.subventions.getSubventions();
        actions.setIsFetching(false);
    };

    const columns: OZTableColumnType[] = [
        {
            title: "Titre",
            key: "title",
            render: (b: any) => b.title,
        },
        {
            title: "Type",
            key: "type",
            filters: [
                { text: "Boîte à idées", value: "IDEA" },
                { text: "Boîte à sondages", value: "SURVEY" },
                { text: "Boîte à messages", value: "MESSAGE" },
            ],
            onFilter: (value: any, b: any) => b.type === value,
            render: (b: any) => <OZBox type={b.type.toLowerCase()} />,
        },
        {
            title: "Nombre de réponses",
            key: "answers",
            render: (b: any) =>
                `${b.answers.length} réponse${b.answers.length === 1 ? "" : "s"}`,
        },
        {
            title: "Actions",
            key: "actions",
            align: "right",
            fixed: "right",
            render: (b: any) => (
                <span key={b._id}>
                    <Button
                        icon={<EyeOutlined />}
                        onClick={() =>
                            history.push({
                                pathname: `/admin/boxes/${b._id}`,
                            })
                        }
                    />
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Êtes-vous sûr(e) de vouloir supprimer cette boîte ?"
                        onConfirm={async () => await actions.boxes.deleteBox(b._id)}
                        icon={<InfoCircleTwoTone twoToneColor="red" />}
                        placement="left"
                        okText="Supprimer"
                        okType="danger"
                        cancelText="Annuler"
                    >
                        <Button
                            ghost
                            icon={<DeleteTwoTone twoToneColor="red" />}
                            type="danger"
                        />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return columns;
};

const Container = styled.div`
    padding: 24px;
    background: #fff;
`;

export default Boxes;
