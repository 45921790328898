import React, { useEffect, useState } from "react";
import { useHistory, Route, Switch, useLocation } from "react-router-dom";
import { BackTop, Col, Layout, Menu, Row, Tooltip } from "antd";
import { CaretDownOutlined, CloseOutlined, MenuOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useOvermind } from "store";

// Modules
import useModules from "modules";
import {
    SCalendar,
    SDocuments,
    SMedias,
    SNewOffer,
    SMyOffers,
    SOffers,
    SPosts,
    SSubventions,
} from "modules";

// Views
import Home from "./Home";
import Benefits from "./Benefits";
import Users from "./Users";
import UserAccount from "./UserAccount";
import Messages from "./Messages";
import Message from "./Messages/Message";
import MessagesNew from "./Messages/New";

// Components
import OZAvatar from "components/OZAvatar";

// Blocks
import PageFooter from "blocks/PageFooter";
import TempReduc from "../../components/TempReduc";
import Tickets from "./Tickets";

const { Header, Content } = Layout;
const { SubMenu } = Menu;

type StyledLayoutType = {
    image: string;
};

const Site = () => {
    const { actions, state } = useOvermind();
    const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);
    const modules = useModules();
    const history = useHistory();
    let { pathname } = useLocation();

    const { appName, backgroundImage, logo } = state.settings.general;
    const { user } = state;

    (window as any).OneSignal = (window as any).OneSignal || [];
    const OneSignal = (window as any).OneSignal;

    useEffect(() => {
        OneSignal.push(() => {
            OneSignal.init(
                {
                    appId: process.env.REACT_APP_ONE_SIGNAL_APP_ID,
                    promptOptions: {
                        slidedown: {
                            enabled: true,
                            actionMessage: `Souhaitez-vous recevoir des notifications de la plateforme ${appName} ?`,
                            acceptButtonText: "Oui",
                            cancelButtonText: "Non",
                        },
                    },
                    welcomeNotification: {
                        title: appName,
                        message: "Vous venez de vous abonner aux notifications !",
                    },
                    allowLocalhostAsSecureOrigin: true,
                },
                OneSignal.sendTags({ email: user.email, role: user.role })
            );
        });
    }, []);

    return (
        <StyledLayout image={backgroundImage.url}>
            <BackTop />
            <StyledHeader>
                <Tooltip title="Accueil">
                    <Logo onClick={() => history.push("/")}>
                        {logo.url ? (
                            <>
                                <img alt={appName} src={logo.url} height={50} /> {appName}
                            </>
                        ) : (
                            appName
                        )}
                    </Logo>
                </Tooltip>

                <Col xs={24} md={0}>
                    <StyledHamb onClick={() => setIsOpenMenuMobile(true)}>
                        <MenuOutlined />
                    </StyledHamb>
                </Col>
                <Col xs={0} md={24}>
                    <Row>
                        <Col span={22}>
                            <Menu
                                theme="dark"
                                mode="horizontal"
                                defaultSelectedKeys={[pathname]}
                                style={{ lineHeight: "64px" }}
                                overflowedIndicator={
                                    <>
                                        Plus <CaretDownOutlined />
                                    </>
                                }
                            >
                                <Item
                                    key="/benefits"
                                    onClick={() => history.push("/benefits")}
                                >
                                    <span className="nav-text">Bons plans</span>
                                </Item>

                                <Item
                                    key="/tickets"
                                    onClick={() => history.push("/tickets")}
                                >
                                    <span className="nav-text">Billetterie</span>
                                </Item>

                                {modules
                                    .filter((m) => m.status && m.name !== "boxes")
                                    .map((m) => (
                                        <Item
                                            key={m.path}
                                            onClick={() => history.push(m.path)}
                                        >
                                            <span className="nav-text">{m.label}</span>
                                        </Item>
                                    ))}

                                <Item key="/users" onClick={() => history.push("/users")}>
                                    <span className="nav-text">Utilisateurs</span>
                                </Item>
                            </Menu>
                        </Col>
                        <Col span={2}>
                            <Menu
                                theme="dark"
                                mode="horizontal"
                                style={{ lineHeight: "64px" }}
                                overflowedIndicator={
                                    <OZAvatar
                                        content={`${user.firstName} ${user.lastName}`}
                                        src={user.picture}
                                    />
                                }
                            >
                                <Menu.Item
                                    onClick={() =>
                                        history.push(`/user/${state.user._id}`)
                                    }
                                >
                                    Mon profil
                                </Menu.Item>

                                {/*
                        <Menu.Item onClick={() => history.push(`/messages`)}>
                            <Badge dot offset={[4, 2]}>
                                Mes messages
                            </Badge>
                        </Menu.Item>

                        {state.settings.modules.offers.status && (
                            <Menu.Item onClick={() => history.push(`/offers/dashboard`)}>
                                Mes annonces
                            </Menu.Item>
                        )}
                        */}

                                {user.role === "ADMIN" && (
                                    <Menu.Item onClick={() => history.push("/admin")}>
                                        Aller à l'administration
                                    </Menu.Item>
                                )}

                                <Menu.Item onClick={() => actions.logout()}>
                                    Se déconnecter
                                </Menu.Item>
                            </Menu>
                        </Col>
                    </Row>
                </Col>
            </StyledHeader>
            <StyledContent>
                <div>
                    <Switch>
                        <Route path="/benefits">
                            <Benefits />
                        </Route>

                        <Route path="/tickets">
                            <Tickets />
                        </Route>

                        <Route path="/posts">
                            <SPosts />
                        </Route>

                        <Route path="/calendar">
                            <SCalendar />
                        </Route>

                        <Route path="/documents">
                            <SDocuments />
                        </Route>

                        <Route path="/medias">
                            <SMedias />
                        </Route>

                        <Route path="/messages/new">
                            <MessagesNew />
                        </Route>

                        <Route path="/messages/:id">
                            <Message />
                        </Route>

                        <Route path="/messages">
                            <Messages />
                        </Route>

                        <Route path="/offers/new">
                            <SNewOffer />
                        </Route>

                        <Route path="/offers/dashboard">
                            <SMyOffers />
                        </Route>

                        <Route path="/offers">
                            <SOffers />
                        </Route>

                        <Route path="/subventions">
                            <SSubventions />
                        </Route>

                        <Route path="/user/:id">
                            <UserAccount />
                        </Route>

                        <Route path="/users">
                            <Users />
                        </Route>

                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </div>
                <PageFooter />
            </StyledContent>
            {isOpenMenuMobile && (
                <StyledMobileMenu>
                    <StyledMenuMobileContent>
                        <div>
                            <StyledMenuMobileLogo
                                onClick={async () => {
                                    await history.push("/");
                                    setIsOpenMenuMobile(false);
                                }}
                            >
                                {logo.url ? (
                                    <>
                                        <img alt={appName} src={logo.url} height={50} />{" "}
                                        <span>{appName}</span>
                                    </>
                                ) : (
                                    appName
                                )}
                            </StyledMenuMobileLogo>
                            <ul>
                                <li
                                    key="/benefits"
                                    onClick={async () => {
                                        await history.push("/benefits");
                                        setIsOpenMenuMobile(false);
                                    }}
                                >
                                    <span className="nav-text">Bons plans</span>
                                </li>

                                <li
                                    key="/tickets"
                                    onClick={async () => {
                                        await history.push("/tickets");
                                        setIsOpenMenuMobile(false);
                                    }}
                                >
                                    <span className="nav-text">Billetterie</span>
                                </li>

                                {modules
                                    .filter((m) => m.status && m.name !== "boxes")
                                    .map((m, i) => (
                                        <li
                                            key={m.path}
                                            onClick={async () => {
                                                await history.push(m.path);
                                                setIsOpenMenuMobile(false);
                                            }}
                                        >
                                            <span className="nav-text">{m.label}</span>
                                        </li>
                                    ))}

                                <li
                                    key="/users"
                                    onClick={async () => {
                                        await history.push("/users");
                                        setIsOpenMenuMobile(false);
                                    }}
                                >
                                    <span className="nav-text">Utilisateurs</span>
                                </li>

                                <li
                                    key={`/user/${state.user._id}`}
                                    onClick={async () => {
                                        await history.push(`/user/${state.user._id}`);
                                        setIsOpenMenuMobile(false);
                                    }}
                                >
                                    Mon profil
                                </li>

                                {user.role === "ADMIN" && (
                                    <li
                                        key={`/admin`}
                                        onClick={() => history.push("/admin")}
                                    >
                                        <span className="nav-text">
                                            Aller à l'administration
                                        </span>
                                    </li>
                                )}

                                <li
                                    onClick={async () => {
                                        await actions.logout();
                                        setIsOpenMenuMobile(false);
                                    }}
                                >
                                    Se déconnecter
                                </li>
                            </ul>
                        </div>
                    </StyledMenuMobileContent>
                    <StyledMenuMobileCloseBtn onClick={() => setIsOpenMenuMobile(false)}>
                        <CloseOutlined />
                    </StyledMenuMobileCloseBtn>
                </StyledMobileMenu>
            )}
        </StyledLayout>
    );
};

const StyledLayout = styled(Layout)<StyledLayoutType>`
    min-height: 100%;
    background-image: url(${(p) => p.image});
    background-size: cover;
    background-position-y: bottom;
    background-position-x: center;
    background-repeat: no-repeat;
`;

const StyledHeader = styled(Header)`
    display: grid;
    grid: auto / minmax(200px, auto) 1fr;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    z-index: 999;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.1);

    & ul {
        text-align: right;
    }
`;

const StyledContent = styled(Content)<{ isFullWidth?: boolean }>`
    margin: auto;
    max-width: ${(p) => (p.isFullWidth ? "auto" : "1200px")};
    padding: 8px;
    width: 100%;
    background: #f0f2f5;
`;

const Logo = styled.div`
    display: grid;
    grid: auto / auto 1fr;
    align-items: center;
    grid-gap: 10px;
    color: rgb(255, 255, 255);
    font-size: 15px;

    padding: 0 25px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: -1px;
    font-style: italic;
    height: 64px;
    cursor: pointer;
    width: 100%;
    line-height: 18px;

    &:hover {
        opacity: 0.8;
    }
`;

const Item = styled(Menu.Item)`
    a {
        display: grid;
        grid: auto / auto;
        grid-gap: 8px;
        align-items: center;
    }
`;

const StyledMobileMenu = styled.div`
    display: grid;
    align-items: center;
    position: fixed;
    background: #10161a;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    text-align: center;

    ul {
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
        font-size: 1.5em;
        display: inline-block;
    }

    li {
        margin: 24px;
        color: #f0f2f5;
        font-weight: 300;
        cursor: pointer;
    }

    button {
        color: #f0f2f5;
        font-weight: 300;
    }

    li:hover {
        opacity: 0.7;
    }
`;

const StyledMenuMobileContent = styled.div`
    display: grid;
    align-items: center;

    overflow-y: scroll;
    height: 100vh;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const StyledMenuMobileLogo = styled.div`
    color: rgb(255, 255, 255);
    font-size: 15px;

    padding: 0 25px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: -1px;
    font-style: italic;
    cursor: pointer;
    width: 100%;
    line-height: 18px;

    & span {
        display: block;
        padding-top: 8px;
    }

    &:hover {
        opacity: 0.8;
    }
`;

const StyledMenuMobileCloseBtn = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px 20px;
    color: #ffffff;
    background: #090c0e;
    cursor: pointer;
`;

const StyledHamb = styled.div`
    display: grid;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    height: 64px;
    width: 64px;
    float: right;
`;

export default Site;
