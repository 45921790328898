import React, { useEffect, useState } from "react";
import { Button, Divider, Popconfirm } from "antd";
import { EditOutlined, DeleteTwoTone, InfoCircleTwoTone } from "@ant-design/icons";
import { useOvermind } from "store";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";

// Components
import OZPageHeader from "components/OZPageHeader";
import OZTable from "components/OZTable";
import OZUploadFile from "components/OZUploadFile";

import { pluralNames, truncateText } from "helpers";
import { OZTableColumnType } from "helpers/types";
import useSearch from "helpers/useSearch";

const Offers = () => {
    // const { actions, state } = useOvermind();
    // const history = useHistory();
    const columns = useColumns();
    // const { offers } = state;

    useEffect(() => {
        // actions.offers.getOffers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const quantity = pluralNames({ length: offers.list.length, text: "annonce" });

    return (
        <Container>
            <OZPageHeader title="Annonces" quantity={quantity} />
            <OZTable columns={columns} data={offers.list} />
        </Container>
    );
};

const useColumns = () => {
    // const { actions } = useOvermind();
    const history = useHistory();

    const columns: OZTableColumnType[] = [
        {
            title: "Image",
            width: 80,
            key: "picture",
            render: (o: any) => (
                <OZUploadFile
                    // action={actions.offers.updateOffer}
                    id={o._id}
                    initialFile={o.picture}
                    pathName="offers"
                    sizeLimit={3}
                    type="picture"
                />
            ),
        },
        {
            title: "Date",
            width: 120,
            key: "date",
            render: (o: any) => format(new Date(o.createdAt), "dd/MM/yyyy"),
        },
        {
            title: "Titre",
            dataIndex: "name",
            render: (t: any) => truncateText(t, 70),
            ...useSearch("name"),
        },
        {
            title: "Publié par",
            key: "user",
            render: (o: any) => `${o.user.firstName} ${o.user.lastName}`,
        },
        {
            title: "Catégorie",
            key: "category",
            render: (o: any) => o.category && o.category.name,
        },
        {
            title: "Actions",
            key: "actions",
            align: "right",
            fixed: "right",
            render: (o: any, i: any) => (
                <span key={i}>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => history.push(`/admin/offers/${o._id}`)}
                    />
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Êtes-vous sûr(e) de vouloir supprimer cette annonce ?"
                        /*onConfirm={async () => {
                            await actions.offers.deleteOffer(o._id);
                            o.picture &&
                                o.picture.fileName &&
                                (await actions.deleteFiles({
                                    fileName: o.picture.fileName,
                                }));
                        }}*/
                        icon={<InfoCircleTwoTone twoToneColor="red" />}
                        placement="left"
                        okText="Supprimer"
                        okType="danger"
                        cancelText="Annuler"
                    >
                        <Button
                            ghost
                            icon={<DeleteTwoTone twoToneColor="red" />}
                            type="danger"
                        />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return columns;
};

const offers = {
    list: [
        {
            user: {
                firstName: "Samuel",
                lastName: "EDOM",
            },
            createdAt: "2020-10-12T11:15:09.956Z",
            name: "Aller-retour pour la web-conférence 2021 sur Nantes",
            category: { name: "Covoiturage" },
        },
        {
            user: {
                firstName: "Samuel",
                lastName: "EDOM",
            },
            createdAt: "2020-10-12T11:15:09.956Z",
            name: "Duis quis ipsum at erat eleifend consectetur",
            category: { name: "Annonce" },
        },
    ],
};

const Container = styled.div`
    padding: 24px;
    background: #fff;
`;

export default Offers;
