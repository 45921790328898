import React, { useRef } from "react";
import { Carousel } from "antd";
import { LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import styled from "styled-components";

const OZCarousel = ({ autoplay, children }: any) => {
    const ref: any = useRef();

    return (
        <StyledContainer>
            <StyledLeftNav onClick={() => ref.current.prev()}>
                <LeftCircleFilled style={{ color: "#FFFFFF", fontSize: 26 }} />
            </StyledLeftNav>
            <Carousel autoplay={autoplay} ref={ref}>
                {children}
            </Carousel>
            <StyledRightNav onClick={() => ref.current.next()}>
                <RightCircleFilled style={{ color: "#FFFFFF", fontSize: 26 }} />
            </StyledRightNav>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    position: relative;
`;

const StyledLeftNav = styled.div`
    position: absolute;
    padding: 8px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 300;

    & .anticon:hover {
        cursor: pointer;
        opacity: 0.7;
    }
`;

const StyledRightNav = styled.div`
    position: absolute;
    padding: 8px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 300;

    & .anticon:hover {
        cursor: pointer;
        opacity: 0.7;
    }
`;

export default OZCarousel;
