import React from "react";
import ReactQuill from "react-quill";
import { Form } from "antd";

import styled from "styled-components";
import { OZTextEditorType } from "helpers/types";

import "react-quill/dist/quill.snow.css";

const OZTextEditor = ({ label, name }: OZTextEditorType) => {
    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            ["clean"],
        ],
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
    ];

    return (
        <StyledEditor>
            <Form.Item label={label} name={name}>
                <ReactQuill modules={modules} formats={formats} />
            </Form.Item>
        </StyledEditor>
    );
};

const StyledEditor = styled.div`
    .ql-editor {
        min-height: 300px;
        margin-bottom: 24px;
    }
`;

export default OZTextEditor;
