import React from "react";
import { pluralNamesType } from "./types";
import OZInput from "components/OZInput";
import OZInputNumber from "components/OZInputNumber";
import OZTextEditor from "components/OZTextEditor";
import OZDatePicker from "components/OZDatePicker";
import OZTimePicker from "components/OZTimePicker";
import OZSelect from "components/OZSelect";

export const pluralNames = ({ length, text }: pluralNamesType) => {
    const number = length;
    const content = length > 1 || length === 0 ? text + "s" : text;
    return `${number} ${content}`;
};

export const truncateText = (str: string = "", limit: number = 25) => {
    if (str.length > limit) return str.substring(0, limit) + "...";
    else return str;
};

export const initials = (str: string) => {
    return str
        .split(" ")
        .map((s) => s[0])
        .join("")
        .toUpperCase();
};

export const formatBytes = (bytes: number, decimals = 0) => {
    if (bytes === 0) return "0 octets";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["o", "ko", "Mo", "Go", "To", "Po", "Eo", "Zo", "Yo"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const renderFields = ({ f, i }: any) => {
    if (f.type === "input") {
        return (
            <OZInput
                key={i}
                disabled={f.disabled}
                icon={f.icon}
                label={f.label}
                name={f.name}
                placeholder={f.placeholder}
                rules={f.rules}
                size={f.size}
            />
        );
    } else if (f.type === "number") {
        return (
            <OZInputNumber
                key={i}
                disabled={f.disabled}
                icon={f.icon}
                label={f.label}
                name={f.name}
                min={f.min}
                max={f.max}
                placeholder={f.placeholder}
                rules={f.rules}
                size={f.size}
            />
        );
    } else if (f.type === "texteditor") {
        return <OZTextEditor key={i} label={f.label} name="content" />;
    } else if (f.type === "custom") {
        return f.component;
    } else if (f.type === "date") {
        return (
            <OZDatePicker
                key={i}
                defaultValue={f.defaultValue}
                disabled={f.disabled}
                label={f.label}
                name={f.name}
                rules={f.rules}
                style={f.style}
            />
        );
    } else if (f.type === "time") {
        return (
            <OZTimePicker
                key={i}
                defaultValue={f.defaultValue}
                label={f.label}
                name={f.name}
                rules={f.rules}
                style={f.style}
            />
        );
    } else if (f.type === "select") {
        return (
            <OZSelect
                key={i}
                disabled={f.disabled}
                defaultValue={f.defaultValue}
                label={f.label}
                name={f.name}
                rules={f.rules}
                values={f.values}
            />
        );
    } else return "";
};
