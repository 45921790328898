import React from "react";
import { useOvermind } from "store";

import OZColorPicker from "components/OZColorPicker";
import OZUploadFile from "components/OZUploadFile";

const Design = () => {
    const { actions, state } = useOvermind();

    const {
        logo,
        backgroundImage,
        primaryColor,
        secondaryColor,
    } = state.settings.general;

    return (
        <>
            <h4>Logo</h4>
            <div style={{ width: 50 }}>
                <OZUploadFile
                    action={actions.updateSettings}
                    initialFile={logo || ""}
                    pathName="settings"
                    type="logo"
                />
            </div>
            <br />
            <h4>Image de fond</h4>
            <div style={{ width: 50 }}>
                <OZUploadFile
                    action={actions.updateSettings}
                    initialFile={backgroundImage || ""}
                    pathName="settings"
                    type="backgroundImage"
                />
            </div>
            <br />
            <h4>Couleur principale</h4>
            <OZColorPicker
                action={actions.updateSettings}
                color={primaryColor}
                type="primaryColor"
            />
            <h4>Couleur secondaire</h4>
            <OZColorPicker
                action={actions.updateSettings}
                color={secondaryColor}
                type="secondaryColor"
            />
        </>
    );
};

export default Design;
