import React from "react";
import { Form, Switch } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { OZSwitchType } from "helpers/types";

const OZSwitch = ({
    checkedChildren = <CheckOutlined />,
    unCheckedChildren = <CloseOutlined />,
    disabled = false,
    defaultChecked,
    name,
    size = "default",
}: OZSwitchType) => {
    return (
        <Form.Item name={name}>
            <Switch
                defaultChecked={defaultChecked}
                disabled={disabled}
                checkedChildren={checkedChildren}
                unCheckedChildren={unCheckedChildren}
                size={size}
            />
        </Form.Item>
    );
};

export default OZSwitch;
