import React from "react";
import { Button, Card, Col, Menu, Row } from "antd";
import styled from "styled-components";

const { Meta } = Card;
const ButtonGroup = Button.Group;

const Shop = () => {
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <Card>
                        <StyledMenu defaultSelectedKeys={["1"]} mode="vertical">
                            <Menu.Item key="1">Catégorie 1</Menu.Item>
                            <Menu.Item key="2">Catégorie 2</Menu.Item>
                            <Menu.Item key="3">Catégorie 3</Menu.Item>
                            <Menu.Item key="4">Catégorie 4</Menu.Item>
                            <Menu.Item key="5">Catégorie 5</Menu.Item>
                        </StyledMenu>
                    </Card>
                </Col>
                <Col span={18}>
                    <Card>
                        <Col span={12}>
                            <Card
                                cover={
                                    <img
                                        alt="example"
                                        src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                                    />
                                }
                                actions={[
                                    <ButtonGroup key="cart">
                                        <Button type="primary" icon="shopping-cart">
                                            Ajouter
                                        </Button>
                                        <Button icon="eye">Voir le produit</Button>
                                    </ButtonGroup>,
                                ]}
                            >
                                <Meta
                                    title="Nom du produit"
                                    description="Sed porttitor convallis felis, euismod dapibus sem aliquam at..."
                                />
                            </Card>
                        </Col>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

const StyledMenu = styled(Menu)`
    border-right: 0;
`;

export default Shop;
