import React from "react";
import { PageHeader, Button, Popconfirm, Tag, Divider } from "antd";
import styled from "styled-components";
import { useOvermind } from "store";

import { OZPageHeaderType } from "helpers/types";

const OZPageHeader = ({
    custom,
    primaryAction,
    primaryText,
    primaryDisabled = false,
    secondaryAction,
    secondaryText = "Voir les catégories",
    confirmSecondary = false,
    quantity,
    title,
}: OZPageHeaderType) => {
    const { state } = useOvermind();

    const confirmButton = (
        <Popconfirm
            key="1"
            title={`Êtes-vous sûr(e) de vouloir réinitialiser les portefeuilles des utilisateurs à ${state.settings.subventions.amountByUser}€ ?`}
            onConfirm={secondaryAction}
            okText="Réinitialiser"
            cancelText="Annuler"
        >
            <Button disabled={primaryDisabled}>{secondaryText}</Button>
        </Popconfirm>
    );

    return (
        <StyledPageHeader
            title={title}
            tags={<Tag color={state.settings.general.primaryColor}>{quantity}</Tag>}
            extra={[
                secondaryAction && confirmSecondary && confirmButton,
                secondaryAction && !confirmSecondary && (
                    <Button key="1" onClick={secondaryAction} disabled={primaryDisabled}>
                        {secondaryText}
                    </Button>
                ),
                primaryText && (
                    <Button
                        key="2"
                        type="primary"
                        onClick={primaryAction}
                        disabled={primaryDisabled}
                    >
                        {primaryText}
                    </Button>
                ),
                custom && custom,
            ]}
            footer={<Divider />}
        />
    );
};

const StyledPageHeader = styled(PageHeader)`
    padding: 0;
`;

export default OZPageHeader;
