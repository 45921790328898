import React from "react";
import { Alert, Button, Card, Col, Row } from "antd";
import LOGO_REDUCCE from "assets/images/logo-reducce.png";
import LOGO_TICKETMASTER from "assets/images/logo-ticketmaster.png";

const Tickets = () => {
    return (
        <Card title="Billetterie">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Card hoverable={false} bodyStyle={{ textAlign: "center" }}>
                        <img
                            alt="REDUCCE"
                            src={LOGO_REDUCCE}
                            style={{ maxWidth: 160, width: "100%", margin: "55px 0" }}
                        />
                        <p>
                            Plus de 15 000 offres à prix CE. Cinéma, E-Cartes Cadeaux,
                            Spectacles, Concert, Parcs, Forfaits de ski et Locations de
                            matériel à prix exceptionnels.
                        </p>

                        <Alert
                            message="Pour l'accès à la nouvelle billetterie de ReducCE, vous devez suivre les instructions ci-dessous."
                            showIcon
                            type="info"
                        />
                        <Alert
                            message={
                                <p>
                                    J'ai un compte créé <b>avant le 1er juin 2022</b>: je
                                    clique sur <b>Mot de passe oublié?</b>
                                </p>
                            }
                            showIcon
                            type="error"
                        />

                        <Alert
                            message={
                                <p>
                                    J'ai un compte créé <b>après le 1er juin 2022</b>: je
                                    clique sur <b>Créer un compte</b> puis sur{" "}
                                    <b>J'ai un code organisation</b> et je renseigne le
                                    code <b>DIGITAL-CSE</b>. Il ne me reste plus qu'à
                                    renseigner mes informations pour créer mon compte.
                                </p>
                            }
                            showIcon
                            type="error"
                        />

                        <a
                            href="https://app.reducce.fr/login"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button type="primary" block style={{ marginTop: 16 }}>
                                Accéder à la billetterie
                            </Button>
                        </a>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Card hoverable={false} bodyStyle={{ textAlign: "center" }}>
                        <img
                            alt="TICKETMASTER"
                            src={LOGO_TICKETMASTER}
                            style={{ maxWidth: 400, width: "100%" }}
                        />
                        <p>
                            Billets et places pour tous événements en France. Plan de
                            salle interactif, avis, video. Livraison postale, e-ticket,
                            m-ticket ou gratuit dans 1600 points.
                        </p>

                        <Alert
                            message="TicketMaster vous demandera de créer un nouveau compte afin de profiter de leur catalogue."
                            showIcon
                            type="info"
                        />

                        <Button
                            target="_blank"
                            href="https://www.ticketmaster.fr/fr/pro/cpn/2/idtier/26188982"
                            type="primary"
                            block
                            style={{ marginTop: 16 }}
                        >
                            Accéder à la billetterie
                        </Button>
                    </Card>
                </Col>
            </Row>
        </Card>
    );
};

export default Tickets;
