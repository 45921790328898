import React, { useEffect, useState } from "react";
import { Button, Divider, Popconfirm } from "antd";
import { EditOutlined, DeleteTwoTone, InfoCircleTwoTone } from "@ant-design/icons";
import { useOvermind } from "store";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import OZPageHeader from "components/OZPageHeader";
import OZTable from "components/OZTable";
import OZDrawer from "components/OZDrawer";

import { pluralNames, truncateText } from "helpers";
import { OZTableColumnType } from "helpers/types";
import useSearch from "helpers/useSearch";

import BasicCategories from "templates/BasicCategories";

const Calendar = () => {
    const { actions, state } = useOvermind();
    const [visible, setVisible] = useState(false);
    const history = useHistory();
    const columns = useColumns();
    const { calendar } = state;
    const { event } = state.categories;

    useEffect(() => {
        actions.calendar.getEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const quantity = pluralNames({ length: calendar.list.length, text: "événement" });

    return (
        <Container>
            <OZPageHeader
                title="Calendrier"
                quantity={quantity}
                primaryAction={() => history.push("/admin/calendar/new")}
                primaryText="Ajouter un événement"
                secondaryAction={() => setVisible(true)}
            />
            <OZTable columns={columns} data={calendar.list} />
            <OZDrawer
                title="Catégories des événements"
                onClose={() => setVisible(false)}
                visible={visible}
                width={400}
            >
                <BasicCategories
                    categories={event.list}
                    getElements={actions.calendar.getEvents}
                    type="event"
                />
            </OZDrawer>
        </Container>
    );
};

const useColumns = () => {
    const { actions } = useOvermind();
    const history = useHistory();

    const columns: OZTableColumnType[] = [
        {
            title: "Nom",
            dataIndex: "name",
            render: (n: any) => truncateText(n, 50),
            ...useSearch("name"),
        },
        {
            title: "Date de début",
            key: "startDate",
            render: (e: any) => e.startDate + " à " + e.startTime,
        },
        {
            title: "Date de fin",
            key: "endDate",
            render: (e: any) => e.endDate + " à " + e.endTime,
        },
        {
            title: "Catégorie",
            key: "category",
            render: (e: any) => e.category && e.category.name,
        },
        {
            title: "Actions",
            key: "actions",
            align: "right",
            fixed: "right",
            render: (e: any) => (
                <span>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => history.push(`/admin/calendar/${e._id}`)}
                    />
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Êtes-vous sûr(e) de vouloir supprimer cet événement ?"
                        onConfirm={() => actions.calendar.deleteEvent(e._id)}
                        icon={<InfoCircleTwoTone twoToneColor="red" />}
                        placement="left"
                        okText="Supprimer"
                        okType="danger"
                        cancelText="Annuler"
                    >
                        <Button
                            ghost
                            icon={<DeleteTwoTone twoToneColor="red" />}
                            type="danger"
                        />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return columns;
};

const Container = styled.div`
    padding: 24px;
    background: #fff;
`;

export default Calendar;
