import React from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";
import { useOvermind } from "store";

// Components
import OZInput from "components/OZInput";
import OZForm from "components/OZForm";

type ParamsType = {
    id?: string;
};

const AccountPassword = () => {
    const { actions, state } = useOvermind();
    const { id = "" }: ParamsType = useParams();
    const [form] = Form.useForm();

    const onSubmit = async (data: any) => {
        await actions.users.changePassword(data);
        form.resetFields();
    };

    const canChange = id !== state.user._id;

    return (
        <OZForm form={form} onSubmit={onSubmit}>
            <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <OZInput
                        disabled={canChange}
                        label="Mot de passe actuel"
                        icon={<i className="ri-lock-line"></i>}
                        placeholder="Mot de passe actuel"
                        name="password"
                        rules={validationRules.password}
                        type="password"
                    />
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <OZInput
                        disabled={canChange}
                        label="Nouveau mot de passe"
                        icon={<i className="ri-lock-line"></i>}
                        placeholder="Nouveau mot de passe"
                        name="newPassword"
                        rules={validationRules.newPassword}
                        type="password"
                    />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Form.Item
                        label="Confirmer le nouveau mot de passe"
                        name="confirm"
                        dependencies={["newPassword"]}
                        hasFeedback
                        rules={validationRules.passwordConfirmation}
                    >
                        <Input
                            disabled={canChange}
                            placeholder="Confirmer le mot de passe"
                            prefix={<i className="ri-lock-line"></i>}
                            size="large"
                            type="password"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col>
                    <Button
                        disabled={canChange}
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={state.isLoading}
                    >
                        Changer le mot de passe
                    </Button>
                </Col>
            </Row>
        </OZForm>
    );
};

const validationRules = {
    password: [
        {
            required: true,
            message: "Veuillez indiquer le mot de passe actuel.",
        },
    ],
    newPassword: [
        {
            required: true,
            pattern: new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"),
            message:
                "Le nouveau mot de passe doit faire plus de 8 caractères, contenir un chiffre et une majuscule.",
        },
    ],
    passwordConfirmation: [
        {
            required: true,
            message: "Veuillez confirmer le nouveau mot de passe.",
        },
        ({ getFieldValue }: { getFieldValue: any }) => ({
            validator(rule: any, value: any) {
                if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                }
                return Promise.reject(
                    "Les deux nouveaux mots de passe doivent correspondre."
                );
            },
        }),
    ],
};

export default AccountPassword;
