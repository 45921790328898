import React, { createRef, useState } from "react";
import { Avatar, message } from "antd";
import { useOvermind } from "store";
import styled from "styled-components";
import { initials } from "helpers";

const OZAvatar = ({ content, edit, size = "small", src }: any) => {
    const { actions, state } = useOvermind();
    const [selectedFile, setSelectedFile] = useState(src || { fileName: "", url: "" });
    const inputFile: any = createRef();

    const handleChange = async (e: any) => {
        message.loading("Envoi en cours...");

        const formData = new FormData();
        formData.append("pathName", "users");
        formData.append("upload", e.target.files[0]);

        if (selectedFile.url) {
            await actions.deleteFiles({ fileName: selectedFile.fileName });
        }

        const res: any = await actions.uploadFiles(formData);
        const url = res[0].location;
        const fileName = res[0].key;

        await actions.users.updateMyself({ data: { picture: { fileName, url } } });
        await actions.users.getUser(state.user._id);
        return setSelectedFile({ fileName, url });
    };

    const onDelete = async () => {
        await actions.deleteFiles({ fileName: selectedFile.fileName });
        await actions.users.updateMyself({
            data: { picture: { fileName: "", url: "" } },
        });
        setSelectedFile({ fileName: "", url: "" });
    };

    return (
        <Container>
            <input
                accept=".jpg, .jpeg, .png"
                onChange={handleChange}
                ref={inputFile}
                style={{ display: "none" }}
                type="file"
            />

            <Avatar
                size={size}
                src={selectedFile.url}
                style={{
                    backgroundColor: selectedFile.url
                        ? "transparent"
                        : state.settings.general.primaryColor,
                }}
            >
                {content && !selectedFile.url && initials(content)}
            </Avatar>

            {edit && (
                <Edit
                    color={state.settings.general.primaryColor}
                    onClick={() => (inputFile as any).current.click()}
                >
                    {selectedFile ? (
                        <i className="ri-pencil-fill" />
                    ) : (
                        <i className="ri-add-line" />
                    )}
                </Edit>
            )}

            {edit && selectedFile.url && (
                <Delete onClick={onDelete}>
                    <i className="ri-delete-bin-fill" />
                </Delete>
            )}
        </Container>
    );
};

const Action = styled.div<{ background: string }>`
    position: absolute;
    display: grid;
    grid: auto / 1fr 1fr;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    z-index: 9999;
    border-radius: 50%;
    opacity: 1;
    color: #ffffff;
    font-size: 18px;
    font-weight: 100;
    user-select: none;
`;

const Container = styled.div`
    position: relative;
    overflow: hidden;

    &:hover ${Action} {
        opacity: 1;
    }
`;

const Edit = styled.div<{ color: string }>`
    cursor: pointer;
    color: #ffffff;
    border: 2px solid #ffffff;

    position: absolute;
    bottom: 0;
    left: 5px;
    background: ${(p) => p.color};
    width: 25px;
    height: 25px;
    border-radius: 50%;

    &:hover {
        border: 2px solid ${(p) => p.color};
        background: #ffffff;
        color: ${(p) => p.color};
    }
`;

const Delete = styled.div`
    cursor: pointer;
    color: #ffffff;
    border: 2px solid #ffffff;

    position: absolute;
    bottom: 0;
    right: 5px;
    background: tomato;
    width: 25px;
    height: 25px;
    border-radius: 50%;

    &:hover {
        border: 2px solid tomato;
        background: #ffffff;
        color: tomato;
    }
`;

export default OZAvatar;
