import React, { useEffect } from "react";
import { Button, Divider, message } from "antd";
import { useOvermind } from "store";
import styled from "styled-components";

// Components
import OZInput from "components/OZInput";
import OZForm from "components/OZForm";

// Blocks
import Logo from "blocks/Logo";

const ForgotPwd = ({ email, setPanel }: { email: string; setPanel: any }) => {
    const { actions, state } = useOvermind();
    const initialValues = { email };

    useEffect(() => {
        const input: any = document.getElementById("digitalcse_email");
        input.focus();
    }, []);

    const onSubmit = async (data: any) => {
        try {
            await actions.requests.createRequest({ ...data, type: "PASSWORD" });
            setPanel("login");
            message.success("Demande envoyée. Veuillez vous rapprocher d'un élu du CSE.");
        } catch (e) {
            message.error(e);
        }
    };

    return (
        <OZForm initialValues={initialValues} onSubmit={onSubmit}>
            <Logo />

            <Top>
                <h2>Mot de passe oublié ?</h2>
                <p>Un élu du CSE traitera votre demande dans les plus brefs délais.</p>
            </Top>

            <OZInput
                icon={<i className="ri-mail-line" />}
                name="email"
                placeholder="Adresse email"
                rules={[
                    { type: "email", message: " " },
                    { required: true, message: " " },
                ]}
                size="large"
            />

            <GroupButton>
                <Button
                    ghost
                    onClick={() => setPanel("login")}
                    size="large"
                    type="primary"
                >
                    Retour
                </Button>

                <Button
                    block
                    htmlType="submit"
                    loading={state.isLoading}
                    size="large"
                    type="primary"
                >
                    Envoyer
                </Button>
            </GroupButton>
        </OZForm>
    );
};

const Top = styled.div`
    text-align: center;
    margin: 24px 0 !important;

    h2 {
        font-weight: 300;
        margin-bottom: 0;
    }

    p {
        font-size: 15px;
        font-weight: 200;
        margin-bottom: 0;
    }
`;

const GroupButton = styled.div`
    display: grid;
    grid: auto / auto 1fr;
    align-items: center;
    grid-gap: 8px;
`;

export default ForgotPwd;
