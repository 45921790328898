import { AsyncAction, Operator, pipe, tryCatch } from "overmind";
import * as o from "./operators";
import * as oCommon from "store/operators";
import Cookies from "js-cookie";
import { message } from "antd";

const COMMON_ERROR_MESSAGE = "Une erreur est survenue.";

// General
export const setIsLoading2: Operator<boolean> = o.setIsLoading2();
export const setIsFetching: Operator<boolean> = o.setIsFetching();
export const throwNotification: Operator<any> = o.toast({ status: "error" });

// Files
export const getFiles: AsyncAction = async ({ effects }) => {
    return await effects.get("files");
};

export const uploadFiles: AsyncAction<any> = async ({ effects }, data) => {
    return await effects.post({ url: "files/upload", data });
};

export const uploadManyFiles: AsyncAction<any> = async ({ effects }, data) => {
    return await effects.post({ url: "files/upload-many", data });
};

export const deleteFiles: AsyncAction<any> = async ({ effects }, data) => {
    await effects.post({ url: "files/delete", data });
};

export const signup: AsyncAction<any> = async ({ effects }, data) => {
    return await effects.post({ url: "signup", data });
};

export const resetPassword: AsyncAction<any> = async ({ effects }, data) => {
    return await effects.post({ url: "reset-password", data });
};

export const login: AsyncAction<any> = async ({ state, effects }, data) => {
    try {
        const user = await effects.post({ url: "login", data });

        state.user = user
            ? user
            : {
                  _id: "",
                  email: "",
                  firstname: "",
                  lastname: "",
                  role: "",
                  token: "",
              };

        await Cookies.set("digitalcse", state.user.token);
        state.settings = await effects.get("settings");
    } catch (err) {
        message.error(err.message);
        return err;
    }
};

export const logout: Operator = tryCatch({
    try: pipe(
        o.logout(),
        o.clearCookies(),
        o.toast({ status: "success", text: "Vous êtes déconnecté(e)." })
    ),
    catch: o.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const getAuthedUser: Operator<string> = o.getAuthedUser();
export const setTokenInCookies: Operator = o.setTokenInCookies();
export const clearCookies: Operator = o.clearCookies();

// Settings
export const getSettings: Operator = o.getSettings();
export const getBasicSettings: Operator = o.getBasicSettings();

export const updateSettings: Operator<any> = tryCatch({
    try: pipe(
        o.updateSettings(),
        o.getSettings(),
        oCommon.destroyToast(),
        o.toast({ status: "success", text: "Réglages généraux mis à jour." })
    ),
    catch: o.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

// Subventions CSV
export const getSubventionsCSV: AsyncAction = async ({ effects }) => {
    return await effects.get("subventions/csv");
};

export const getSubventionsUserCSV: AsyncAction<string> = async ({ effects }, id) => {
    return await effects.get(`subventions/csv/${id}`);
};

// Notifications
export const getNotifications: AsyncAction = async ({ effects }) => {
    return await effects.get("notifications");
};
