import React, { useEffect } from "react";
import {
    Avatar,
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Dropdown,
    Menu,
    message,
    PageHeader,
    Row,
    Upload,
} from "antd";
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";
import { useHistory, useParams } from "react-router-dom";
import { useOvermind } from "store";
import { format } from "date-fns";
import { DownOutlined } from "@ant-design/icons";

import OZStatus from "components/OZStatus";
import UserCard from "blocks/UserCard";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type ParamsType = {
    id?: string;
};

const Subvention = () => {
    const { actions, state } = useOvermind();
    const history = useHistory();
    const { id = "" }: ParamsType = useParams();

    const { selected } = state.subventions;

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        actions.setIsFetching(true);
        if (id) await actions.subventions.getSubvention(id);
        actions.setIsFetching(false);
    };

    const update = async (status: string) => {
        actions.setIsFetching(true);
        await actions.subventions.updateSubvention({ id, data: { status } });
        history.push("/admin/subventions");
        actions.setIsFetching(false);
    };

    const isPDF =
        selected.file &&
        selected.file.url &&
        selected.file.url.split(".").pop() === "pdf";

    const menu = (
        <Menu>
            <Menu.Item key="accept" onClick={() => update("ACCEPTED")}>
                Accepter la demande
            </Menu.Item>
            <Menu.Item key="reject" onClick={() => update("REJECTED")}>
                Refuser la demande
            </Menu.Item>
        </Menu>
    );

    const handleAction = async (e: any) => {
        message.loading("Envoi en cours...");

        const formData = new FormData();
        formData.append("pathName", "subventions");
        formData.append("upload", e);

        if (selected.file && selected.file.url) {
            await actions.deleteFiles({ fileName: selected.file.fileName });
        }

        const res: any = await actions.uploadFiles(formData);
        const url = res[0].location;
        const fileName = res[0].key;

        await actions.subventions.updateSubvention({
            id,
            data: { file: { fileName, url } },
        });

        history.push("/admin/subventions");

        return "";
    };

    const beforeUpload = (file: any) => {
        const isTooLarge = file.size / 1024 / 1024 > 5;
        if (isTooLarge) {
            message.error(`Fichier trop volumineux (max. 5Mo).`);
            return false;
        }

        const isFile =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "application/pdf";

        if (!isFile) {
            message.error("Formats acceptés: JPG, PNG et PDF.");
            return false;
        }

        return true;
    };

    return (
        <Row gutter={[16, 16]}>
            <Col xs={{ span: 24, order: 2 }} lg={{ span: 17, order: 1 }}>
                <Card bordered={false}>
                    <StyledPageHeader
                        title={`Subvention #${selected.number}`}
                        subTitle={
                            selected.status && (
                                <OZStatus type={selected.status.toLowerCase()} />
                            )
                        }
                        extra={[
                            <Dropdown
                                key="actions"
                                disabled={
                                    selected.status &&
                                    selected.status.toLowerCase() !== "pending"
                                }
                                overlay={menu}
                            >
                                <Button>
                                    Actions <DownOutlined />
                                </Button>
                            </Dropdown>,
                        ]}
                    >
                        <Descriptions bordered column={2}>
                            <Descriptions.Item label="Date de la demande">
                                {selected.createdAt &&
                                    format(new Date(selected.createdAt), "dd/MM/yyyy")}
                            </Descriptions.Item>
                            <Descriptions.Item label="Type">
                                {selected.type && selected.type === "BANKTRANSFER"
                                    ? "Virement bancaire"
                                    : "Chèque"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Salarié">
                                {selected.user && (
                                    <StyledUser>
                                        {`${selected.user.firstName} ${selected.user.lastName}`}
                                    </StyledUser>
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="Montant">
                                {selected.amount && `${selected.amount} €`}
                            </Descriptions.Item>
                            <Descriptions.Item label="Créé par">
                                {selected.createdBy && (
                                    <StyledUser>
                                        {`${selected.createdBy.firstName} ${selected.createdBy.lastName}`}
                                    </StyledUser>
                                )}
                            </Descriptions.Item>
                            <Descriptions.Item label="Géré par">
                                {selected.managedBy && (
                                    <StyledUser>
                                        {`${selected.managedBy.firstName} ${selected.managedBy.lastName}`}
                                    </StyledUser>
                                )}
                            </Descriptions.Item>
                        </Descriptions>
                    </StyledPageHeader>
                    <Divider />
                    {isPDF ? (
                        <Document
                            file={selected.file && selected.file.url}
                            renderMode="canvas"
                        >
                            <Page
                                pageNumber={1}
                                renderMode="canvas"
                                renderTextLayer={false}
                            />
                        </Document>
                    ) : (
                        <img
                            alt={selected.file && selected.file.fileName}
                            src={selected.file && selected.file.url}
                            width="100%"
                        />
                    )}
                </Card>
            </Col>

            <Col xs={{ span: 24, order: 1 }} lg={{ span: 7, order: 2 }}>
                <Row gutter={[16, 16]}>
                    <Col>
                        {selected.user && (
                            <UserCard canChange={false} user={selected.user} />
                        )}

                        <Card
                            bordered={false}
                            style={{ marginTop: selected.user ? 16 : 0 }}
                            title="Justificatif"
                        >
                            <Button
                                block
                                href={selected.file && selected.file.url}
                                download
                                target="_blank"
                            >
                                Télécharger le justificatif
                            </Button>

                            <StyledUpload
                                showUploadList={false}
                                action={handleAction}
                                beforeUpload={beforeUpload}
                            >
                                <Button block>Changer de justificatif</Button>
                            </StyledUpload>
                        </Card>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

// Styles
const StyledPageHeader = styled(PageHeader)`
    padding: 0;
`;

const StyledUser = styled.div`
    display: grid;
    grid: auto / auto 1fr;
    grid-gap: 4px;
    align-items: center;
`;

const StyledUpload = styled(Upload)`
    & > .ant-upload {
        margin-top: 16px;
        width: 100%;
    }
`;

export default Subvention;
