import React, { useEffect } from "react";
import { Badge, Button, Divider, message, Tag, Popconfirm, Tooltip } from "antd";
import {
    EditOutlined,
    DeleteTwoTone,
    InfoCircleTwoTone,
    WalletOutlined,
} from "@ant-design/icons";
import { useOvermind } from "store";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

// Components
import OZPageHeader from "components/OZPageHeader";
import OZTable from "components/OZTable";

import { pluralNames } from "helpers";
import { OZTableColumnType } from "helpers/types";
import useSearch from "helpers/useSearch";

import OZAvatar from "components/OZAvatar";
import { jsonToCSV } from "react-papaparse";

const Users = () => {
    const { actions, state } = useOvermind();
    const history = useHistory();
    const columns = useColumns();
    const { users } = state;
    const { limitations } = state.settings.general;

    useEffect(() => {
        actions.users.getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const quantity = pluralNames({ length: users.list.length, text: "utilisateur" });

    return state.isFetching ? (
        <div />
    ) : (
        <Container>
            <OZPageHeader
                title="Utilisateurs"
                quantity={quantity}
                primaryAction={() => history.push("/admin/users/new")}
                primaryDisabled={users.list.length >= limitations.users}
                primaryText="Ajouter un utilisateur"
            />
            <OZTable columns={columns} data={users.list} />
        </Container>
    );
};

const Container = styled.div`
    padding: 24px;
    background: #fff;
`;

const useColumns = () => {
    const { actions, state } = useOvermind();
    const history = useHistory();

    const columns: OZTableColumnType[] = [
        {
            title: "",
            key: "picture",
            render: (u: any) => (
                <OZAvatar src={u.picture} content={`${u.firstName} ${u.lastName}`} />
            ),
        },
        {
            title: "Prénom",
            dataIndex: "firstName",
            ...useSearch("firstName"),
        },
        {
            title: "Nom",
            dataIndex: "lastName",
            ...useSearch("lastName"),
        },
        {
            title: "Email",
            dataIndex: "email",
            ...useSearch("email"),
        },
        {
            title: "Rôle",
            key: "role",
            filters: [
                { text: "Admin", value: "ADMIN" },
                { text: "Membre", value: "MEMBER" },
            ],
            filterMultiple: false,
            onFilter: (value: any, u: any) => u.role === value,
            render: (u: any) => (
                <Tag
                    color={u.role === "ADMIN" ? state.settings.general.primaryColor : ""}
                >
                    {u.role === "ADMIN" ? "Admin" : "Membre"}
                </Tag>
            ),
        },
        {
            title: "Statut",
            key: "status",
            filters: [
                { text: "Activé", value: true },
                { text: "Désactivé", value: false },
            ],
            filterMultiple: false,
            onFilter: (value: any, u: any) => u.status === value,
            render: (u: any) => (
                <Badge
                    status={u.status ? "success" : "error"}
                    text={u.status ? "Activé" : "Désactivé"}
                />
            ),
        },
        {
            title: "Actions",
            key: "actions",
            align: "right",
            fixed: "right",
            render: (u: any) => (
                <span>
                    {state.settings.modules.subventions.status && (
                        <>
                            {" "}
                            <Tooltip
                                title={`Exporter l'historique des subventions de ${u.firstName} ${u.lastName}`}
                            >
                                <Button
                                    icon={<WalletOutlined />}
                                    ghost
                                    type="primary"
                                    onClick={async () => {
                                        const sub: any = await actions.getSubventionsUserCSV(
                                            u._id
                                        );

                                        if (sub) {
                                            const parse = await jsonToCSV(sub);

                                            const csvData = new Blob([parse], {
                                                type: "text/csv",
                                            });
                                            const csvURL = window.URL.createObjectURL(
                                                csvData
                                            );
                                            const tempLink = document.createElement("a");
                                            tempLink.href = csvURL;
                                            tempLink.setAttribute(
                                                "download",
                                                `subventions-${u.firstName}-${u.lastName}.csv`
                                            );
                                            tempLink.click();
                                        } else
                                            message.warning("Aucun historique trouvé.");
                                    }}
                                />
                            </Tooltip>
                            <Divider type="vertical" />
                        </>
                    )}
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => history.push(`/admin/users/${u._id}`)}
                    />
                    <Divider type="vertical" />
                    <Popconfirm
                        title={
                            <>
                                <span>
                                    Êtes-vous sûr(e) de vouloir supprimer cet utilisateur
                                    ?
                                </span>
                                {state.settings.modules.subventions.status && (
                                    <>
                                        <br />
                                        <span>
                                            <b>
                                                Pensez à télécharger son historique des
                                                subventions avant sa suppression.
                                            </b>
                                        </span>
                                    </>
                                )}
                            </>
                        }
                        onConfirm={async () => {
                            u.picture &&
                                u.picture.fileName &&
                                (await actions.deleteFiles({
                                    fileName: u.picture.fileName,
                                }));

                            actions.users.deleteUser(u._id);
                        }}
                        icon={<InfoCircleTwoTone twoToneColor="red" />}
                        placement="left"
                        okText="Supprimer"
                        okType="danger"
                        cancelText="Annuler"
                        disabled={u._id === state.user._id}
                    >
                        <Button
                            disabled={u._id === state.user._id}
                            ghost
                            icon={
                                <DeleteTwoTone
                                    twoToneColor={
                                        u._id === state.user._id ? "#CCC" : "red"
                                    }
                                />
                            }
                            type="danger"
                        />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return columns;
};

export default Users;
