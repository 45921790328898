import React from "react";

const Cgu = () => (
    <div>
        <h1>
            CONDITIONS G&Eacute;N&Eacute;RALES DE VENTE ET D&rsquo;UTILISATION DES
            SERVICES &laquo; digital CSE &raquo;
        </h1>
        <h2>
            <br />
            Article pr&eacute;liminaire : D&eacute;finitions
        </h2>
        <h2>
            <br />
            La soci&eacute;t&eacute; :
        </h2>
        <p>
            D&eacute;signe SILIA SAS, soci&eacute;t&eacute; par action simplifi&eacute;e
            au capital de 2000 &euro;, immatricul&eacute;e au Registre du Commerce et des
            Soci&eacute;t&eacute;s de Chartres sous le num&eacute;ro 889 312 625 dont le
            si&egrave;ge social est situ&eacute; au 6 rue du Chemin Neuf 28130 HANCHES.
        </p>
        <h2>La plateforme :</h2>
        <p>
            D&eacute;signe le programme d&eacute;nomm&eacute; &laquo; digital CSE &raquo;
            accessible par internet ou par WebApp.
        </p>
        <h2>Les CSE</h2>
        <p>
            Signifie Comit&eacute; Social et Economique. Le CSE est l&rsquo;instance
            unique de repr&eacute;sentation du personnel compos&eacute;e de
            l&rsquo;employeur et d&rsquo;une d&eacute;l&eacute;gation &eacute;lue du
            personnel comportant un nombre de membres fix&eacute; en fonction de
            l&rsquo;effectif de l&rsquo;entreprise.
        </p>
        <h2>L&rsquo;entreprise</h2>
        <p>
            D&eacute;signe la personne morale dans laquelle le CSE est
            d&eacute;sign&eacute;.
        </p>
        <h2>Les services :</h2>
        <p>
            D&eacute;signe les &eacute;l&eacute;ments de fonctionnalit&eacute;s de la
            plateforme.
        </p>
        <h2>Les utilisateurs :</h2>
        <p>
            D&eacute;signent les personnes physiques, salari&eacute;es ou employeur, de
            l&rsquo;entreprise.
        </p>
        <h2>La billeterie :</h2>
        <p>
            D&eacute;signe les cartes et ch&egrave;ques cadeaux, les bons d&rsquo;achat,
            les tickets de cin&eacute;ma, de spectacles, de concert, de parcs, ainsi que
            les forfaits de ski et les bons pour la location de mat&eacute;riel
            propos&eacute;s sur le site internet du partenaire de &laquo; digital CSE
            &raquo;.
        </p>
        <h2>Les avantages :</h2>
        <p>
            D&eacute;signent toutes les offres du partenaire de &laquo; Digital CSE
            &raquo;.
        </p>
        <h2>Le compte CSE :</h2>
        <p>
            D&eacute;signe l&rsquo;espace personnel du CSE accessible depuis la plateforme
            par l&rsquo;interm&eacute;diaire d&rsquo;identifiants compos&eacute;s
            d&rsquo;un nom d&rsquo;utilisateur et d&rsquo;un mot de passe.
        </p>
        <h2>Le contenu :</h2>
        <p>
            D&eacute;signe les documents, articles, dossiers et images diffus&eacute;es
            par le CSE par le biais de la plateforme et accessibles aux utilisateurs.
        </p>
        <h2>Le partenaire :</h2>
        <p>
            D&eacute;signe la soci&eacute;t&eacute; Ski Loisirs Diffusion, sous
            l&rsquo;enseigne ReducCE, acteur majeur de la commercialisation de solutions
            cadeaux innovantes.
        </p>
        <h2>&nbsp;</h2>
        <h2>
            Article 1 : Application des pr&eacute;sentes conditions
            g&eacute;n&eacute;rales
        </h2>
        <p>
            Toute utilisation de la plateforme est soumise aux pr&eacute;sentes conditions
            g&eacute;n&eacute;rales de vente et d&rsquo;utilisation.
            <br />
            Toute utilisation de la plateforme implique par cons&eacute;quent
            l&rsquo;adh&eacute;sion enti&egrave;re et sans r&eacute;serve du CSE aux
            <br />
            pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente et
            d&rsquo;utilisation.
        </p>
        <p>
            Il est express&eacute;ment pr&eacute;cis&eacute; que le CSE acc&egrave;de aux
            services ou les utilisent pour son compte. En signant les pr&eacute;sentes
            conditions g&eacute;n&eacute;rales de vente et d&rsquo;utilisation, le CSE
            d&eacute;clare et garanti qu&rsquo;il est l&rsquo;instance
            repr&eacute;sentante autoris&eacute;e &agrave; utiliser les services de la
            plateforme et ayant tout pouvoir pour &ecirc;tre li&eacute; aux
            pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente et
            d&rsquo;utilisation.
        </p>
        <h2>Article 2 : Objet</h2>
        <p>
            Les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente et
            d&rsquo;utilisation des services ont pour objet de d&eacute;finir les
            modalit&eacute;s et conditions dans lesquelles la soci&eacute;t&eacute; met
            &agrave; la disposition du CSE une plateforme permettant de diffuser et
            partager des documents, articles, images et &eacute;v&egrave;nements &agrave;
            destination des utilisateurs.
            <br />
            <br />
            Les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente et
            d&rsquo;utilisation s&rsquo;appliquent &agrave; l&rsquo;ensemble des
            fonctionnalit&eacute;s, services et avantages mises &agrave; disposition sur
            la plateforme.
        </p>
        <p>
            Toute utilisation inappropri&eacute;e, contraire ou non-conforme des CSE
            &agrave; l&rsquo;&eacute;gard des r&egrave;gles d&eacute;crites aux
            pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente et
            d&rsquo;utilisation des services implique la suppression du compte CSE et une
            interdiction d&rsquo;utilisation de la plateforme sans pr&eacute;avis
            pr&eacute;alable et sans qu&rsquo;il ne soit besoin de justifier un quelconque
            motif.
        </p>
        <h2>Article 3 : Pr&eacute;-requis techniques et fonctionnels</h2>
        <p>
            Pr&eacute;alablement &agrave; l&rsquo;utilisation de la plateforme, le CSE
            devra s&rsquo;assurer qu&rsquo;il dispose des pr&eacute;-requis techniques et
            fonctionnels n&eacute;cessaires, qui sont :
        </p>
        <p>
            - un acc&egrave;s Internet Haut-D&eacute;bit ;<br />- un ordinateur, une
            tablette num&eacute;rique ou un t&eacute;l&eacute;phone smartphone ;<br />-
            une adresse de messagerie &eacute;lectronique (e-mail) valide ;<br />- une
            adresse postale valide ;<br />- un num&eacute;ro de t&eacute;l&eacute;phone ;
        </p>
        <p>Par ailleurs, le CSE devra obligatoirement :</p>
        <p>
            - communiquer &agrave; la soci&eacute;t&eacute; les heures de permanences du
            CSE ;<br />- communiquer le nom des &eacute;lus du CSE qui sont en charge de
            la gestion de la plateforme ;<br />- transmettre une liste des salari&eacute;s
            dans un tableau excel comprenant par colonne au minima les
            &eacute;l&eacute;ments suivants : noms, pr&eacute;noms, adresses courriel.
            Cette liste pourra aussi comprendre les &eacute;l&eacute;ments optionnels
            suivants : num&eacute;ros de t&eacute;l&eacute;phone , adresses, codes
            postaux, villes.
        </p>
        <p>
            Le CSE s&rsquo;engage &agrave; transmettre &agrave; la soci&eacute;t&eacute;
            des informations exactes et &agrave; jour.
        </p>
        <p>
            Il appartient au CSE de v&eacute;rifier la compatibilit&eacute; de la
            configuration de l&rsquo;&eacute;quipement informatique avec les services.
            Toute utilisation de la plateforme implique la reconnaissance par le CSE de la
            conformit&eacute; de son
            <br />
            &eacute;quipement aux pr&eacute;-requis techniques. La soci&eacute;t&eacute;
            d&eacute;cline toute responsabilit&eacute; en cas de non fonctionnement de
            l&rsquo;&eacute;quipement informatique du CSE ou de son incompatibilit&eacute;
            avec les services. Le CSE devra prendre toutes dispositions pour que les
            configurations logicielles et r&eacute;seaux ainsi que les raccordements au
            r&eacute;seau Internet ou &eacute;lectrique et tout autre raccordement
            n&eacute;cessaire soit constamment en &eacute;tat normal de fonctionnement. Il
            devra &eacute;galement prendre toutes les mesures appropri&eacute;es de
            fa&ccedil;on &agrave; prot&eacute;ger les donn&eacute;es et/ou logiciels de la
            contamination par des &eacute;ventuels virus circulant sur les r&eacute;seaux
            de communication &eacute;lectroniques.
        </p>
        <p>
            Les &eacute;quipements (ordinateurs, smartphone, tablette, etc.) permettant
            l&rsquo;acc&egrave;s aux services sont &agrave; la charge exclusive de
            l&rsquo;entreprise ou du CSE, de m&ecirc;me que les frais de communication
            induits par leur utilisation.
        </p>
        <h2>Article 4 : Description des services et fonctionnalit&eacute;s</h2>
        <p>La plateforme permet au CSE de diffuser et/ou g&eacute;rer :</p>
        <p>
            - tous types d&rsquo;articles relatifs &agrave; la l&eacute;galit&eacute;,
            &agrave; la s&eacute;curit&eacute; et &agrave; la gestion des activit&eacute;s
            du CSE ;<br />- tous types de documentation interne &agrave;
            l&rsquo;entreprise ;<br />- les &eacute;v&egrave;nements &agrave; venir
            organis&eacute;s par le CSE ;
            <br />- les demandes de subventions prises en charge par le CSE ;
            <br />- les fiches utilisateurs comprenant les donn&eacute;es personnels des
            utilisateurs ;<br />- le media comprenant images et photos destin&eacute;s aux
            utilisateurs ;<br />- les bons plans du CSE.
        </p>
        <h2>Article 5 : Avantages</h2>
        <p>
            Outre les services et fonctionnalit&eacute;s de la plateforme, le CSE et les
            utilisateurs pourront b&eacute;n&eacute;ficier, par le biais de la plateforme,
            des avantages du partenaire de la soci&eacute;t&eacute; en mati&egrave;re de
            billetterie, voyages et autres offres destin&eacute;es aux comit&eacute;s
            d&rsquo;entreprise.
        </p>
        <p>
            Dans le cadre de ces avantages, il est express&eacute;ment
            pr&eacute;cis&eacute; que la soci&eacute;t&eacute; agit en qualit&eacute; de
            partenaire d&rsquo;h&eacute;bergement au sens de la Loi sur la confiance dans
            l&rsquo;&eacute;conomie num&eacute;rique du 21 juin 2004. Elle assure le
            fonctionnement de la plateforme et se contente de mettre &agrave; la
            disposition du CSE des fonctionnalit&eacute;s techniques permettant &agrave;
            ces derniers de g&eacute;rer et diffuser du contenu aux utilisateurs. La
            soci&eacute;t&eacute; n&rsquo;intervient en aucun cas dans la vente et
            l&rsquo;utilisation de ces avantages qui sont l&rsquo;unique
            responsabilit&eacute; du partenaire de la soci&eacute;t&eacute;.
        </p>
        <p>
            Pour toutes demandes ou r&eacute;clamations concernant ces avantages, le CSE
            ou les utilisateurs devront s&rsquo;adresser exclusivement au partenaire de la
            soci&eacute;t&eacute;:
        </p>
        <p>
            Site internet du partenaire : www.reducce.fr
            <br />
            Nom du partenaire : Ski Loisirs Diffusion SAS au capital de 400 000 &euro; RCS
            Lyon N&deg; Siren 384 600 847
            <br />
            Adresse postale: 5 All&eacute;e des S&eacute;quoias, 69760 Limonest (Lyon)
            <br />
            Courriel : serviceclient@reducce.fr
            <br />
            Contact par e-mail ou par chat (fen&ecirc;tre "aide" en bas &agrave; droite de
            l&rsquo;&eacute;cran) de 8h30 &agrave; 13h - 13h30 &agrave; 17h30
        </p>
        <h2>Article 6 : Souscription aux services</h2>
        <p>
            L&rsquo;utilisation de la plateforme est soumise &agrave; la souscription par
            le CSE d&rsquo;un abonnement de vingt-quatre (24) mois minimum.
        </p>
        <p>
            La plateforme "digital CSE" s&rsquo;adresse exclusivement au CSE de
            l&rsquo;entreprise.
        </p>
        <p>
            Pour b&eacute;n&eacute;ficier des services de diffusion et de gestion de la
            plateforme, l&rsquo;entreprise devra signer avec mention de son &laquo; bon
            pour accord &raquo; le contrat de service &laquo; digital CSE &raquo;.
        </p>
        <h2>Article 7 : Tarifs et paiement</h2>
        <p>
            La souscription &agrave; la plateforme &laquo; digital CSE &raquo; est soumise
            au tarif en vigueur mentionn&eacute; sur le contrat de service remis au CSE
            accompagn&eacute; des pr&eacute;sentes conditions g&eacute;n&eacute;rales de
            vente et d&rsquo;utilisation. Les prix sont exprim&eacute;s en euros, hors
            taxes et toutes taxes comprises. Le tarif mentionn&eacute; est ferme et non
            r&eacute;visables pendant la p&eacute;riode de validit&eacute; de
            l&rsquo;abonnement. Hors cette p&eacute;riode de validit&eacute;, la
            soci&eacute;t&eacute; se r&eacute;serve la possibilit&eacute; de modifier les
            prix &agrave; tout moment.
        </p>
        <p>
            Le paiement demand&eacute; &agrave; l&rsquo;entreprise correspondra au montant
            total de l&rsquo;abonnement souscrit. En outre, les prix indiqu&eacute;s ne
            comprennent pas les co&ucirc;ts li&eacute;s aux pr&eacute;-requis
            &eacute;voqu&eacute;s &agrave; l&rsquo;article 3 des CGVU (co&ucirc;t des
            &eacute;quipements, frais de connexion...), le CSE en faisant son affaire
            personnelle.
        </p>
        <p>
            Toute souscription aux services donnera lieu &agrave; une facture qui sera
            remise au CSE. Le prix de l&rsquo;abonnement est payable chaque ann&eacute;e
            &agrave; la date de la souscription par voie de paiement
            s&eacute;curis&eacute; selon l&rsquo;une des trois modalit&eacute;s suivantes
            :
        </p>
        <p>
            - par ch&egrave;que bancaire ou postal libell&eacute; &agrave; l&rsquo;ordre
            de SILIA SAS ;<br />- par carte bancaire : visa, MasterCard, carte bleue,
            American Express ;<br />- par pr&eacute;l&egrave;vement SEPA
        </p>
        <p>
            La soci&eacute;t&eacute; ne sera pas tenue de proc&eacute;der &agrave; la
            fourniture des services souscrits si le prix n&rsquo;a pas &eacute;t&eacute;
            pr&eacute;alablement r&eacute;gl&eacute; en totalit&eacute; dans les
            conditions ci-dessus indiqu&eacute;es.
        </p>
        <p>
            Le ou les paiements effectu&eacute;s par l&rsquo;entreprise ne seront
            consid&eacute;r&eacute;s comme d&eacute;finitifs qu&rsquo;apr&egrave;s
            encaissement effectif des sommes dues &agrave; la soci&eacute;t&eacute;. Sauf
            d&eacute;lai de paiement exceptionnellement accord&eacute; par &eacute;crit
            par la soci&eacute;t&eacute;, tout impay&eacute; &agrave; la date
            d'&eacute;ch&eacute;ance mentionn&eacute;e sur la facture entra&icirc;nera de
            plein droit, sans mise en demeure pr&eacute;alable :
        </p>
        <p>
            - l&rsquo;exigibilit&eacute; imm&eacute;diate de toutes sommes restant dues ;
            <br />- des p&eacute;nalit&eacute;s de retard ;
        </p>
        <p>
            Toute facture non r&eacute;gl&eacute;e dans le d&eacute;lai d'un mois donnera
            lieu &agrave; l'application d'int&eacute;r&ecirc;ts de retard au taux de trois
            fois l'int&eacute;r&ecirc;t l&eacute;gal (loi N&deg; 92-1442 du 31
            d&eacute;cembre 1992).
        </p>
        <p>
            - une indemnit&eacute; forfaitaire, en plus des p&eacute;nalit&eacute;s de
            retard, pour frais de recouvrement au profit de la soci&eacute;t&eacute; d'un
            montant de quarante (40) euros, de plein droit et sans formalit&eacute; par
            l&rsquo;entreprise, d&egrave;s le premier jour de retard de paiement suivant
            l'&eacute;ch&eacute;ance de la facture.
            <br />- la suspension de son abonnement, d&egrave;s r&eacute;ception par
            l&rsquo;entreprise d&rsquo;une mise en demeure en lettre recommand&eacute;e
            avec avis de r&eacute;ception constatant l&rsquo;impay&eacute; ;<br />- la
            r&eacute;siliation du contrat en cas de non-r&eacute;gularisation de
            l&rsquo;impay&eacute; dans les 15 (quinze) jours de la r&eacute;ception
            d&rsquo;une mise en demeure rest&eacute;e infructueuse, ainsi que
            l&rsquo;exercice de toute voie de droit utile &agrave; l&rsquo;indemnisation
            du pr&eacute;judice subi par la soci&eacute;t&eacute;.
        </p>
        <h2>Article 8 : Modalit&eacute;s de fonctionnement des services</h2>
        <h3>Article 8.1. Pour le CSE</h3>
        <p>
            Pour b&eacute;n&eacute;ficier des services et avantages de la plateforme, le
            CSE doit cr&eacute;er un compte CSE avec un identifiant et un mot de passe.
            Ces informations sont confidentielles et le CSE s&rsquo;engage &agrave; ne pas
            transmettre l&rsquo;identifiant et le mot de passe utilis&eacute; &agrave; un
            tiers.
        </p>
        <p>
            Pour la cr&eacute;ation du compte CSE, la soci&eacute;t&eacute; s&rsquo;engage
            &agrave; fournir apr&egrave;s v&eacute;rification et r&eacute;ception des
            pr&eacute;-requis techniques et fonctionnels pr&eacute;vus &agrave;
            l&rsquo;article 3 des pr&eacute;sentes conditions g&eacute;n&eacute;rales de
            vente et d&rsquo;utilisation, un lien permettant l&rsquo;acc&egrave;s au
            compte CSE et aux fonctionnalit&eacute;s et avantages.
        </p>
        <h3>Article 8.2. Pour les utilisateurs</h3>
        <p>
            Pour b&eacute;n&eacute;ficier de certains services, bons plans et avantages de
            la plateforme, il sera demand&eacute;, le cas &eacute;ch&eacute;ant, aux
            utilisateurs, de transmettre certaines donn&eacute;es personnelles notamment
            leurs noms, pr&eacute;noms,
            <br />
            adresses e-mail ainsi que leurs coordonn&eacute;es bancaires.
        </p>
        <h2>Article 9 : Obligations du CSE</h2>
        <p>
            L&rsquo;acc&egrave;s aux services et l&rsquo;utilisation de ceux-ci sont
            soumis aux pr&eacute;sentes conditions g&eacute;n&eacute;rales et &agrave;
            toutes les lois et r&eacute;glementations en vigueur. Aussi, le CSE ou
            l&rsquo;utilisateur n&rsquo;est pas autoris&eacute; &agrave; :
        </p>
        <p>
            - acc&eacute;der aux services ou les utiliser s&rsquo;il n&rsquo;est pas
            salari&eacute; de l&rsquo;entreprise ;<br />- cr&eacute;er plusieurs fiches
            utilisateurs (un utilisateur = une fiche utilisateur) ;<br />- r&eacute;aliser
            des copies non autoris&eacute;es, modifier, adapter, traduire,
            d&eacute;sosser, d&eacute;sassembler, d&eacute;compiler, ou cr&eacute;er des
            &oelig;uvres d&eacute;riv&eacute;es bas&eacute;es sur les Services, notamment
            des fichiers, des tableaux ou de la documentation (ou une partie de ceux-ci)
            ni identifier ou tenter d&rsquo;identifier un code source, des algorithmes,
            des m&eacute;thodes ou techniques incorpor&eacute;s &agrave; la plateforme ou
            des &oelig;uvres d&eacute;riv&eacute;es de celle-ci ;<br />- distribuer,
            conc&eacute;der sous licence, c&eacute;der ou vendre, en tout ou partie, un ou
            plusieurs des Services ou des &oelig;uvres d&eacute;riv&eacute;es de ceux-ci ;
            <br />
            - commercialiser, prendre ou donner en location les services &agrave; titre
            on&eacute;reux, ou utiliser la plateforme pour faire de la publicit&eacute; ou
            effectuer une sollicitation commerciale ;<br />- utiliser les services, sans
            consentement &eacute;crit explicite, &agrave; des fins commerciales ou non
            autoris&eacute;es, y compris de communication ou de facilitation de
            publicit&eacute;s, sollicitations commerciales ou envoi de
            <br />
            courriers ind&eacute;sirables ;<br />- entraver ou tenter d&rsquo;entraver le
            bon fonctionnement des services, perturber les fonctionnalit&eacute;s de la
            plateforme ou les r&eacute;seaux connect&eacute;s aux services, ou contourner
            des mesures que la soci&eacute;t&eacute; est susceptible d&rsquo;utiliser pour
            emp&ecirc;cher ou limiter l&rsquo;acc&egrave;s aux services ou encore, le cas
            &eacute;ch&eacute;ant, exploiter les bugs ou d&eacute;faillances de la
            plateforme ;<br />- incorporer la plateforme ou une quelconque partie de
            celle-ci &agrave; un autre programme ou produit. Dans ce cas, la
            soci&eacute;t&eacute; se r&eacute;serve le droit de refuser le service,
            r&eacute;silier des comptes ou limiter l&rsquo;acc&egrave;s aux services
            &agrave; sa seule discr&eacute;tion ;<br />- utiliser des scripts
            automatis&eacute;s pour collecter des informations issues des services ou
            interagir de toute autre fa&ccedil;on avec ces derniers ;<br />- se faire
            passer pour une autre personne ou entit&eacute;, effectuer de fausses
            d&eacute;clarations ou autres fausses pr&eacute;sentations notamment en
            donnant l&rsquo;impression qu&rsquo;un contenu transf&eacute;rer, publier,
            partager ou communiquer de toute autre fa&ccedil;on &eacute;mane de la
            soci&eacute;t&eacute; ;<br />- intimider ou harceler une autre personne,
            promouvoir des mat&eacute;riels sexuellement explicites, la violence ou une
            discrimination fond&eacute;e sur la race, le sexe, la religion, la
            nationalit&eacute;, le handicap, l&rsquo;orientation sexuelle ou
            l&rsquo;&acirc;ge ;<br />- utiliser ou tenter d&rsquo;utiliser le compte CSE,
            les services ou le syst&egrave;me sans l&rsquo;autorisation de la
            soci&eacute;t&eacute;, ou cr&eacute;er une fausse identit&eacute; sur les
            services ;<br />- utiliser les services d&rsquo;une fa&ccedil;on susceptible
            de cr&eacute;er un conflit d&rsquo;int&eacute;r&ecirc;ts ou compromettre les
            objectifs des services ;<br />- utiliser les services pour transf&eacute;rer,
            transmettre, distribuer, stocker ou communiquer de toute autre fa&ccedil;on:
        </p>
        <p style={{ paddingLeft: 30 }}>
            - des fichiers contenant des virus, chevaux de Troie, vers, bombes logiques ou
            autres mat&eacute;riels malveillants ou pr&eacute;judiciables d&rsquo;un point
            de vue technologique ;<br />- de la publicit&eacute; non sollicit&eacute;e ou
            non autoris&eacute;e, des sollicitations, des mat&eacute;riels promotionnels,
            du &laquo; courrier ind&eacute;sirable &raquo;, du &laquo; spam &raquo;, des
            &laquo; cha&icirc;nes de courriels &raquo;, des &laquo; syst&egrave;mes
            pyramidaux &raquo; ou toute autre forme de sollicitation ;<br />- des
            renseignements personnels sur des tiers ;
            <br />- un contenu qui enfreint, ou est susceptible d&rsquo;enfreindre, un
            droit d&rsquo;auteur, une marque commerciale ou d&rsquo;autres droits en
            mati&egrave;re de propri&eacute;t&eacute; intellectuelle ou de
            confidentialit&eacute; ;<br />- un contenu diffamatoire envers un utilisateur
            ou un tiers, obsc&egrave;ne, injurieux, pornographique, haineux ou incendiaire
            ;<br />- un contenu qui constituerait, encouragerait, ou fournirait des
            instructions pour, un d&eacute;lit, des activit&eacute;s dangereuses ou une
            automutilation ;<br />- un contenu d&eacute;lib&eacute;r&eacute;ment
            con&ccedil;u ou visant &agrave; harceler, nuire, blesser, effrayer tourmenter,
            g&ecirc;ner ou bouleverser un utilisateur ;<br />- un contenu
            pr&eacute;sentant une menace de quelque nature que ce soit, notamment des
            menaces de violence physique ;<br />- un contenu raciste ou discriminatoire,
            pr&eacute;sentant notamment une discrimination sur le fondement de la race, de
            la religion, de l&rsquo;&acirc;ge, du genre, du handicap ou de la
            sexualit&eacute; d&rsquo;un utilisateur ;
        </p>
        <p>
            - &agrave; diffuser un article ou une documentation qui, &agrave; la seule
            appr&eacute;ciation de "digital CSE", est inacceptable ou limite ou
            emp&ecirc;che l&rsquo;utilisation des services, ou qui peut exposer "digital
            CSE", les services ou les autres utilisateurs &agrave; un pr&eacute;judice ou
            une responsabilit&eacute; de quelque nature que ce soit ;
        </p>
        <p>
            Aussi, la Soci&eacute;t&eacute; se r&eacute;serve le droit, &agrave; tout
            moment et sans pr&eacute;avis, de supprimer ou d&eacute;sactiver
            l&rsquo;acc&egrave;s aux Services &agrave; sa discr&eacute;tion et pour
            quelque motif que ce soit. Les motifs pour lesquels la Soci&eacute;t&eacute;
            supprime ou d&eacute;sactive l&rsquo;acc&egrave;s au contenu pourront
            &ecirc;tre notamment que le contenu appara&icirc;t inacceptable, en violation
            des pr&eacute;sentes Conditions, ou pr&eacute;judiciable de toute autre
            fa&ccedil;on aux services ou aux utilisateurs.
        </p>
        <h2>Article 10 : Force majeure</h2>
        <p>
            La responsabilit&eacute; de la soci&eacute;t&eacute; ne pourra &ecirc;tre
            recherch&eacute;e si l'ex&eacute;cution des services est retard&eacute;e ou
            emp&ecirc;ch&eacute;e en raison d'un cas de force majeure ou d'un cas fortuit
            ou de causes ext&eacute;rieures telles que les conflits sociaux, intervention
            des autorit&eacute;s civiles ou militaires, catastrophes naturelles,
            incendies, d&eacute;g&acirc;ts des eaux, &eacute;pid&eacute;mies, interruption
            du r&eacute;seau de t&eacute;l&eacute;communications ou du r&eacute;seau
            &eacute;lectrique ou encore en raison de d&eacute;cisions administratives.
        </p>
        <h2>Article 11 : Responsabilit&eacute;s</h2>
        <p>
            Dans le cadre de la plateforme &laquo; digital CSE &raquo;, la
            soci&eacute;t&eacute; agit en qualit&eacute; de partenaire
            d&rsquo;h&eacute;bergement, au sens de la Loi sur la confiance dans
            l&rsquo;&eacute;conomie num&eacute;rique du 21 juin 2004. Elle assure le
            fonctionnement de la plateforme et se contente de mettre &agrave; la
            disposition des CSE des fonctionnalit&eacute;s technique permettant &agrave;
            ces derniers de g&eacute;rer et diffuser du contenu.
        </p>
        <p>
            La soci&eacute;t&eacute; n&rsquo;intervient ni dans le choix des articles ou
            de la documentation, ni dans le choix des avantages. Elle contribue uniquement
            &agrave; la diffusion et au partage du contenu, elle ne saurait donc
            &ecirc;tre responsable ou complice d&rsquo;un quelconque dommage corporels ou
            incorporels ou de pr&eacute;judices en ce qui concerne
            l&rsquo;int&eacute;grit&eacute; physique ou morale du ou des utilisateurs. En
            cons&eacute;quence, la soci&eacute;t&eacute; n&rsquo;est notamment pas tenue
            de v&eacute;rifier la conformit&eacute; ou encore la l&eacute;galit&eacute; du
            contenu diffus&eacute; par le CSE. Par cons&eacute;quent, la
            soci&eacute;t&eacute; ne saurait &ecirc;tre tenue responsable en ce qui
            concerne :
        </p>
        <p>
            la perte de profit directe ou indirecte, la perte de client&egrave;le, le
            manque &agrave; gagner, les pertes de donn&eacute;es et plus
            g&eacute;n&eacute;ralement toutes pertes ou dommages que l&rsquo;entreprise
            pourrait subir suite &agrave; l&rsquo;utilisation des services &eacute;tant
            pr&eacute;cis&eacute; que la plateforme n&rsquo;est fourni que pour un usage
            professionnel.
        </p>
        <p>
            La soci&eacute;t&eacute; s&rsquo;engage &agrave; proc&eacute;der
            r&eacute;guli&egrave;rement &agrave; des contr&ocirc;les afin de
            v&eacute;rifier le fonctionnement et l&rsquo;accessibilit&eacute; de la
            plateforme. A ce titre, la soci&eacute;t&eacute; se r&eacute;serve la
            facult&eacute; d&rsquo;interrompre momentan&eacute;ment l&rsquo;acc&egrave;s
            &agrave; la plateforme pour des raisons de maintenance. De m&ecirc;me, la
            soci&eacute;t&eacute; ne saurait &ecirc;tre tenue responsable des
            difficult&eacute;s ou impossibilit&eacute;s momentan&eacute;es
            d&rsquo;acc&egrave;s &agrave; la plateforme qui auraient pour origine des
            circonstances qui lui sont ext&eacute;rieures, la force majeure, ou encore qui
            seraient dues &agrave; des perturbations du r&eacute;seau de
            t&eacute;l&eacute;communication.
        </p>
        <p>
            En sa qualit&eacute; d&rsquo;h&eacute;bergeur de contenu, la
            soci&eacute;t&eacute; ne pourra en aucun cas &ecirc;tre tenue responsable de
            tout agissement contraire aux lois qui pourrait &ecirc;tre commis par le CSE
            ou l&rsquo;entreprise dans le cadre de l&rsquo;utilisation de la plateforme.
            Ces limites de responsabilit&eacute; s&rsquo;appliqueront quelques soient les
            dommages, directs ou indirects ou quelques soient la cause des dommages, et ce
            y compris les dommages subis par des tierces parties.
        </p>
        <h2>Article 11 : Droit &agrave; l&rsquo;image</h2>
        <p>
            L&rsquo;acceptation des pr&eacute;sentes conditions g&eacute;n&eacute;rales
            d&rsquo;utilisation vaut autorisation de reproduction et de
            repr&eacute;sentation des images et photos diffus&eacute;es par le CSE sur la
            plateforme.
        </p>
        <p>
            En cons&eacute;quence de quoi, le CSE s&rsquo;oblige express&eacute;ment
            &agrave; recueillir l&rsquo;autorisation des utilisateurs pour diffuser et
            partager leur image sur la plateforme.
        </p>
        <h2>Article 12 : Propri&eacute;t&eacute; intellectuelle</h2>
        <p>
            En signant les pr&eacute;sentes conditions g&eacute;n&eacute;rales de vente et
            d&rsquo;utilisation des services, le CSE accepte de ne pas utiliser les
            services pour enfreindre des droits de propri&eacute;t&eacute; intellectuelle.
            Le contenu transf&eacute;rer aux utilisateurs sur la plateforme doit
            imp&eacute;rativement &ecirc;tre la propri&eacute;t&eacute; du CSE ou de
            l&rsquo;entreprise.
        </p>
        <p>
            La soci&eacute;t&eacute; se r&eacute;serve le droit, avec ou sans
            pr&eacute;avis, &agrave; tout moment et &agrave; sa seule discr&eacute;tion,
            de bloquer l&rsquo;acc&egrave;s au compte CSE et/ou de r&eacute;silier
            l&rsquo;abonnement du CSE qui enfreint ou est pr&eacute;sum&eacute;e
            enfreindre des
            <br />
            droits d&rsquo;auteur ou d&rsquo;autres droits de propri&eacute;t&eacute;
            intellectuelle.
        </p>
        <p>
            Les proc&eacute;d&eacute;s intellectuels, l&rsquo;image et la marque &laquo;
            "digital CSE" &raquo; sont la propri&eacute;t&eacute; de la
            soci&eacute;t&eacute;. Les syst&egrave;mes, logiciels, structures,
            infrastructures, bases de donn&eacute;es et contenus (textes, images, visuels,
            logos, marques, etc.) mis en &oelig;uvre par la soci&eacute;t&eacute; sur la
            plateforme sont prot&eacute;g&eacute;s par tous droits de
            propri&eacute;t&eacute; intellectuelle en vigueur. Tous d&eacute;sassemblages,
            d&eacute;compilations, d&eacute;cryptages, extractions, r&eacute;utilisations,
            copies et plus g&eacute;n&eacute;ralement, tous actes de reproduction,
            repr&eacute;sentation, diffusion et utilisation de l&rsquo;un quelconque de
            ses &eacute;l&eacute;ments, en tout ou partie, sans l&rsquo;autorisation de la
            soci&eacute;t&eacute; sont strictement interdits et feront l&rsquo;objet de
            poursuites judiciaires.
        </p>
        <h2>Article 13 : Modification des conditions d&rsquo;utilisation des services</h2>
        <p>
            La soci&eacute;t&eacute; se r&eacute;serve le droit de modifier &agrave; tout
            moment les pr&eacute;sentes conditions g&eacute;n&eacute;rales.
        </p>
        <p>
            Les conditions g&eacute;n&eacute;rales mises &agrave; jour sont alors
            notifi&eacute;es au CSE au moyen de la messagerie &eacute;lectronique. Les
            conditions g&eacute;n&eacute;rales mises &agrave; jour s&rsquo;appliquent
            imm&eacute;diatement au CSE qui utilise la plateforme apr&egrave;s cette
            modification. Le CSE est donc invit&eacute; &agrave; consulter attentivement
            la derni&egrave;re version en vigueur des conditions g&eacute;n&eacute;rales
            et &agrave; en imprimer copie.
        </p>
        <h2>
            Article 14 : Dur&eacute;e de l&rsquo;abonnement et acc&egrave;s aux services
        </h2>
        <p>
            La dur&eacute;e de l&rsquo;abonnement &agrave; &laquo; digital CSE &raquo; est
            de minimum vingt-quatre (24) mois &agrave; compter de la date de
            <br />
            sa souscription.
        </p>
        <p>
            A l&rsquo;&eacute;ch&eacute;ance de l&rsquo;abonnement souscrit,
            l&rsquo;acc&egrave;s &agrave; la plateforme sera interrompu.
        </p>
        <p>
            Par ailleurs, en cas de manquement ou d&rsquo;irrespect du CSE ou de
            l&rsquo;entreprise aux dispositions des pr&eacute;sentes conditions
            g&eacute;n&eacute;rales de vente et d&rsquo;utilisation, la
            soci&eacute;t&eacute; se r&eacute;serve le droit &agrave; tout moment et sans
            pr&eacute;avis, de supprimer ou d&eacute;sactiver l&rsquo;acc&egrave;s aux
            services &agrave; sa discr&eacute;tion et pour quelque motif que ce soit sans
            qu&rsquo;aucun remboursement ne puisse &ecirc;tre exig&eacute;.
        </p>
        <h2>Article 15 : Service client et r&eacute;clamation</h2>
        <p>
            Toute demande ou r&eacute;clamation pourra &ecirc;tre adress&eacute;e au
            Service Client &agrave; l&rsquo;adresse courriel suivante :{" "}
            <a href="mailto:contact@digitalcse.fr!">contact@digitalcse.fr</a>
        </p>
        <h2>Article 16 : Traitement des donn&eacute;es personnelles</h2>
        <p>
            La soci&eacute;t&eacute; accorde une grande importance au respect de la vie
            priv&eacute;e. Dans le cadre de l&rsquo;utilisation de la plateforme "digital
            CSE", la soci&eacute;t&eacute; est amen&eacute;e &agrave; collecter et traiter
            certaines donn&eacute;es personnelles des utilisateurs. En cr&eacute;ant un
            compte, le CSE et les utilisateurs reconna&icirc;ssent et acceptent le
            traitement de leurs donn&eacute;es personnelles par la soci&eacute;t&eacute;
            conform&eacute;ment &agrave; la r&egrave;glementation applicable et aux
            dispositions de la Politique de confidentialit&eacute; des donn&eacute;es
            disponible sur le site internet :{" "}
            <a href="http://www.digitalcse.fr">www.digitalcse.fr</a>
        </p>
        <p>
            Le CSE ou l&rsquo;utilisateur peut demander &agrave; tout moment la
            modification ou la suppression de ses donn&eacute;es personnelles par
            l&rsquo;envoi d&rsquo;un courriel &agrave; contact@digitalcse.fr ou par voie
            postale &agrave; l&rsquo;adresse : SILIA SAS SAS, 6 rue du Chemin Neuf 28130
            HANCHES.
        </p>
        <h2>Article 17: M&eacute;diation</h2>
        <p>
            Les diff&eacute;rents et litiges qui viendraient &agrave; se produire relatifs
            &agrave; la validit&eacute;, &agrave; l&rsquo;interpr&eacute;tation, &agrave;
            l&rsquo;ex&eacute;cution, &agrave; l&rsquo;inex&eacute;cution, &agrave;
            l&rsquo;interruption ou &agrave; la r&eacute;siliation des pr&eacute;sentes
            conditions g&eacute;n&eacute;rales de vente et d&rsquo;utilisation seront
            soumis au r&egrave;glement d&rsquo;une m&eacute;diation conventionnelle de la
            CCI ou &agrave; d&eacute;faut, de tout autre m&eacute;diateur
            accr&eacute;dit&eacute; qui serait d&eacute;sign&eacute; par la
            soci&eacute;t&eacute; en accord avec le CSE. Toute saisie d&rsquo;une
            juridiction en m&eacute;connaissance de la pr&eacute;sente clause expose le
            demandeur &agrave; se voir opposer une fin de non-recevoir. Les constatations
            du m&eacute;diateur et les d&eacute;clarations recueillies dans le cadre de la
            m&eacute;diation ne pourront &ecirc;tre produites ou invoqu&eacute;es sans
            l&rsquo;accord pr&eacute;alable des parties dans
            l&rsquo;&eacute;ventualit&eacute; de l&rsquo;&eacute;chec de la
            m&eacute;diation.
        </p>
        <h2>Article 18 : Droit applicable et juridictions comp&eacute;tentes</h2>
        <p>
            Les pr&eacute;sentes conditions g&eacute;n&eacute;rales sont
            r&eacute;dig&eacute;es en langue fran&ccedil;aise et soumises au droit
            fran&ccedil;ais. Si elles sont traduites en langues &eacute;trang&egrave;res,
            seule la version fran&ccedil;aise ferait foi en cas de litige.
        </p>
        <p>
            Tous les litiges auxquels les pr&eacute;sentes conditions
            g&eacute;n&eacute;rales d&rsquo;utilisation pourraient donner lieu et qui
            n&rsquo;auraient pu trouver r&eacute;solution par la m&eacute;diation,
            concernant tant leur validit&eacute;, leur interpr&eacute;tation, leur
            ex&eacute;cution, leur r&eacute;siliation, leurs cons&eacute;quences et leurs
            suites seront soumis aux Tribunaux de Chartres (28).
        </p>
    </div>
);

export default Cgu;
