import { Operator, mutate, run } from "overmind";

export const getUser: () => Operator<any> = () =>
    mutate(async function getUser({ effects, state }, id) {
        state.users.selected = await effects.get(`users/${id}`);
    });

export const getUsers: () => Operator = () =>
    mutate(async function getUsers({ state, effects }) {
        const res = await effects.get("users");
        state.users.length = res.length;
        state.users.list = res.users;
    });

export const findUsers: () => Operator<any> = () =>
    mutate(async function findUsers({ state, effects }, props) {
        const res = await effects.post({ url: "/users/find", data: props });
        state.users.length = res.length;
        state.users.list = res.users;
    });

export const createUser: () => Operator<any> = () =>
    run(async function createUser({ effects }, props) {
        await effects.post({ url: "/users", data: props });
    });

export const updateUser: () => Operator<any> = () =>
    run(async function updateUser({ effects }, props) {
        await effects.put({ url: `/users/${props.id}`, data: props.data });
    });

export const updateMyself: () => Operator<any> = () =>
    run(async function updateMyself({ effects }, props) {
        await effects.put({ url: `/users`, data: props.data });
    });

export const deleteUser: () => Operator<any> = () =>
    run(async function deleteUser({ effects }, id) {
        await effects.del(`users/${id}`);
    });

export const changePassword: () => Operator<any> = () =>
    run(async function changePassword({ effects }, data) {
        await effects.post({ url: "users/change-password", data });
    });

export const changePicture: () => Operator<any> = () =>
    run(async function changePicture({ effects }, props) {
        await effects.put({ url: `/users/change-picture/${props.id}`, data: props.data });
    });

export const resetSelectedUser: () => Operator = () =>
    mutate(async function resetSelectedUser({ state }) {
        state.users.selected = {};
    });

export const createMultipleUsers: () => Operator<any> = () =>
    run(async function createMultipleUsers({ effects }, data) {
        await effects.post({ url: "/users/batch", data });
    });
