import React from "react";
import { Card, Col, Row, Tag } from "antd";
import { useOvermind } from "store";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

// COMPONENTS
import OZAvatar from "components/OZAvatar";
import OZTable from "components/OZTable";

// HELPERS
import { truncateText } from "helpers";
import { OZTableColumnType } from "helpers/types";

import Sidebar from "./Sidebar";

const Messages = () => {
    const history = useHistory();
    const columns = useColumns();

    return (
        <Card>
            <Row align="top" gutter={[32, 8]} justify="center">
                <Sidebar />
                <Col xs={24} sm={24} md={19} lg={19} xl={19} xxl={19}>
                    <StyledTable>
                        <Card bordered>
                            <OZTable
                                columns={columns}
                                data={data}
                                showHeader={false}
                                size="small"
                                onRow={(record: any, rowIndex: any) => {
                                    return {
                                        onClick: () =>
                                            history.push({
                                                pathname: `/messages/${record.id}`,
                                                state: { id: "" },
                                            }),
                                    };
                                }}
                            />
                        </Card>
                    </StyledTable>
                </Col>
            </Row>
        </Card>
    );
};

const useColumns = () => {
    const columns: OZTableColumnType[] = [
        {
            dataIndex: "isRead",
            render: (isRead: boolean) =>
                !isRead ? (
                    <StyledTag color="red">Non lu</StyledTag>
                ) : (
                    <StyledTag>Lu</StyledTag>
                ),
        },
        {
            title: "User",
            dataIndex: "user",
            render: (u: any) => (
                <StyledUser>
                    <OZAvatar src={u.picture} content={`${u.firstName} ${u.lastName}`} />{" "}
                    {u.firstName} {u.lastName}
                </StyledUser>
            ),
        },
        {
            title: "Subject",
            dataIndex: "subject",
            render: (t: any) => truncateText(t, 60),
        },
        {
            title: "Date",
            key: "date",
            render: (p: any) =>
                format(new Date(p.createdAt), "dd MMM yyyy - HH:mm", { locale: fr }),
        },
    ];

    return columns;
};

const data = [
    {
        id: 1,
        subject:
            "Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone Kitty Paw Artisan Keycaps, Suunto MC-2 Compass and more...",
        user: { firstName: "Steven", lastName: "Persia" },
        createdAt: "2020-11-08T18:25:29+01:00",
        type: "inbox",
        isRead: false,
    },
    {
        id: 2,
        subject:
            "Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone Kitty Paw Artisan Keycaps, Suunto MC-2 Compass and more...",
        user: { firstName: "Steven", lastName: "Persia" },
        createdAt: "2020-11-08T18:25:29+01:00",
        type: "inbox",
        isRead: false,
    },
    {
        id: 3,
        subject:
            "Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone Kitty Paw Artisan Keycaps, Suunto MC-2 Compass and more...",
        user: { firstName: "Steven", lastName: "Persia" },
        createdAt: "2020-11-08T18:25:29+01:00",
        type: "inbox",
        isRead: true,
    },
    {
        id: 4,
        subject:
            "Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone Kitty Paw Artisan Keycaps, Suunto MC-2 Compass and more...",
        user: { firstName: "Steven", lastName: "Persia" },
        createdAt: "2020-11-08T18:25:29+01:00",
        type: "inbox",
        isRead: true,
    },
    {
        id: 5,
        subject:
            "Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone Kitty Paw Artisan Keycaps, Suunto MC-2 Compass and more...",
        user: { firstName: "Steven", lastName: "Persia" },
        createdAt: "2020-11-08T18:25:29+01:00",
        type: "inbox",
        isRead: true,
    },
    {
        id: 6,
        subject:
            "Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone Kitty Paw Artisan Keycaps, Suunto MC-2 Compass and more...",
        user: { firstName: "Steven", lastName: "Persia" },
        createdAt: "2020-11-08T18:25:29+01:00",
        type: "inbox",
        isRead: true,
    },
    {
        id: 7,
        subject:
            "Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone Kitty Paw Artisan Keycaps, Suunto MC-2 Compass and more...",
        user: { firstName: "Steven", lastName: "Persia" },
        createdAt: "2020-11-08T18:25:29+01:00",
        type: "inbox",
        isRead: true,
    },
    {
        id: 8,
        subject:
            "Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone Kitty Paw Artisan Keycaps, Suunto MC-2 Compass and more...",
        user: { firstName: "Steven", lastName: "Persia" },
        createdAt: "2020-11-08T18:25:29+01:00",
        type: "inbox",
        isRead: true,
    },
    {
        id: 9,
        subject:
            "Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone Kitty Paw Artisan Keycaps, Suunto MC-2 Compass and more...",
        user: { firstName: "Steven", lastName: "Persia" },
        createdAt: "2020-11-08T18:25:29+01:00",
        type: "inbox",
        isRead: true,
    },
    {
        id: 10,
        subject:
            "Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone Kitty Paw Artisan Keycaps, Suunto MC-2 Compass and more...",
        user: { firstName: "Steven", lastName: "Persia" },
        createdAt: "2020-11-08T18:25:29+01:00",
        type: "inbox",
        isRead: true,
    },
    {
        id: 11,
        subject:
            "Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone Kitty Paw Artisan Keycaps, Suunto MC-2 Compass and more...",
        user: { firstName: "Steven", lastName: "Persia" },
        createdAt: "2020-11-08T18:25:29+01:00",
        type: "inbox",
        isRead: true,
    },
    {
        id: 12,
        subject:
            "Everglide PCB Mount Screw-In Stabilizer Set, ZOMO Silicone Kitty Paw Artisan Keycaps, Suunto MC-2 Compass and more...",
        user: { firstName: "Steven", lastName: "Persia" },
        createdAt: "2020-11-08T18:25:29+01:00",
        type: "inbox",
        isRead: true,
    },
];

// Styles
const StyledTable = styled.div`
    tr {
        cursor: pointer !important;
    }
`;

const StyledUser = styled.div`
    display: grid;
    grid: auto / auto 1fr;
    grid-gap: 8px;
    align-items: center;
`;

const StyledTag = styled(Tag)`
    width: 100%;
    text-align: center;
`;

export default Messages;
