import React from "react";
import { Button, Popconfirm } from "antd";
import { DeleteTwoTone, InfoCircleTwoTone, SaveOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useOvermind } from "store";

const OZActions = ({ action, id, text }: any) => {
    const { state } = useOvermind();

    return (
        <GroupButton>
            <Button
                block
                type="primary"
                size="large"
                icon={<SaveOutlined />}
                htmlType="submit"
                loading={state.isLoading}
            >
                {id !== "new" ? "Enregistrer" : "Créer"}
            </Button>
            {id !== "new" && (
                <Popconfirm
                    title={`Êtes-vous sûr(e) de vouloir supprimer ${text} ?`}
                    icon={<InfoCircleTwoTone twoToneColor="red" />}
                    onConfirm={action}
                    placement="bottomRight"
                    okText="Supprimer"
                    okType="danger"
                    cancelText="Annuler"
                >
                    <Button
                        block
                        icon={<DeleteTwoTone twoToneColor="red" />}
                        type="danger"
                        ghost
                        size="large"
                        htmlType="submit"
                    >
                        Supprimer
                    </Button>
                </Popconfirm>
            )}
        </GroupButton>
    );
};

const GroupButton = styled.div`
    display: grid;
    grid-gap: 8px;
`;

export default OZActions;
