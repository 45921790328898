import React from "react";
import { useOvermind } from "store";

// Modules
const useModules = () => {
    const { state } = useOvermind();
    const {
        boxes,
        calendar,
        documents,
        medias,
        offers,
        posts,
        shop,
        subventions,
    } = state.settings.modules;

    const modules = [
        {
            label: "Articles",
            description: "Communiquez sur l'actualité de votre CSE.",
            icon: <i className="ri-article-fill" />,
            name: "posts",
            path: "/posts",
            status: posts.status,
            order: posts.order,
        },
        {
            label: "Boîtes",
            description:
                "Créez une boîte à idées, une boîte à sondages ou une boîte à messages.",
            icon: <i className="ri-archive-fill" />,
            name: "boxes",
            path: "/boxes",
            status: boxes.status,
            order: boxes.order,
        },
        {
            label: "Boutique",
            description: "Actuellement indisponible.",
            disabled: true,
            icon: <i className="ri-shopping-bag-3-fill" />,
            name: "shop",
            path: "/shop",
            status: shop.status,
            subMenu: [
                { label: "Commandes", path: "/shop/orders" },
                { label: "Produits", path: "/shop/products" },
            ],
            order: shop.order,
        },
        {
            label: "Calendrier",
            description: "Organisez les différents événements de votre CSE.",
            icon: <i className="ri-calendar-todo-fill" />,
            name: "calendar",
            path: "/calendar",
            status: calendar.status,
            order: calendar.order,
        },
        {
            label: "Documents",
            description: "Partagez les documents importants de votre CSE.",
            icon: <i className="ri-file-text-fill" />,
            name: "documents",
            path: "/documents",
            status: documents.status,
            order: documents.order,
        },
        {
            label: "Médias",
            description: "Gérez les galeries photos de votre CSE.",
            icon: <i className="ri-gallery-fill" />,
            name: "medias",
            path: "/medias",
            status: medias.status,
            order: medias.order,
        },
        {
            label: "Petites annonces",
            description: "Actuellement indisponible.",
            disabled: true,
            icon: <i className="ri-price-tag-3-fill" />,
            name: "offers",
            path: "/offers",
            status: offers.status,
            order: offers.order,
        },
        {
            label: "Subventions",
            description: "Gérez les subventions de votre CSE.",
            icon: <i className="ri-wallet-3-fill" />,
            name: "subventions",
            path: "/subventions",
            status: subventions.status,
            order: subventions.order,
        },
    ];

    return modules;
};

export default useModules;

// Boxes
export { default as ABoxes } from "./Boxes/admin";
export { default as ABox } from "./Boxes/admin/Box";

// Calendar
export { default as ACalendar } from "./Calendar/admin";
export { default as AEvent } from "./Calendar/admin/Event";
export { default as SCalendar } from "./Calendar/site";

// Documents
export { default as ADocuments } from "./Documents/admin";
export { default as ADocument } from "./Documents/admin/Document";
export { default as SDocuments } from "./Documents/site";

// Medias
export { default as AMedias } from "./Medias/admin";
export { default as AMedia } from "./Medias/admin/Media";
export { default as SMedias } from "./Medias/site";

// Offers
export { default as AOffers } from "./Offers/admin";
export { default as AOffer } from "./Offers/admin/Offer";
export { default as SOffers } from "./Offers/site";
export { default as SNewOffer } from "./Offers/site/New";
export { default as SMyOffers } from "./Offers/site/MyOffers";

// Posts
export { default as APosts } from "./Posts/admin";
export { default as APost } from "./Posts/admin/Post";
export { default as SPosts } from "./Posts/site";

// Shop
export { default as AShop } from "./Shop/admin";
export { default as AOrder } from "./Shop/admin/Order";
export { default as AOrders } from "./Shop/admin/Orders";
export { default as AProduct } from "./Shop/admin/Product";
export { default as AProducts } from "./Shop/admin/Products";
export { default as SShop } from "./Shop/site";

// Subventions
export { default as ASubventions } from "./Subventions/admin";
export { default as ASubvention } from "./Subventions/admin/Subvention";
export { default as SSubventions } from "./Subventions/site";
