import { Operator, pipe, tryCatch } from "overmind";
import * as o from "./operators";
import * as oCommon from "store/operators";

const COMMON_ERROR_MESSAGE = "Une erreur est survenue.";

export const getPost: Operator<string> = tryCatch({
    try: o.getPost(),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const getPosts: Operator = pipe(
    oCommon.setIsLoading(true),
    o.getPosts(),
    oCommon.setIsLoading(false)
);

export const getPostsByCategory: Operator<string> = o.getPostsByCategory();

export const createPost: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.createPost(),
        o.getPosts(),
        oCommon.toast({ status: "success", text: "Article créé." }),
        oCommon.setIsLoading(false)
    ),
    catch: pipe(
        oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
        oCommon.setIsLoading(false)
    ),
});

export const updatePost: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.updatePost(),
        oCommon.destroyToast(),
        oCommon.toast({ status: "success", text: "Article mis à jour." }),
        oCommon.setIsLoading(false)
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const deletePost: Operator<string> = tryCatch({
    try: pipe(
        o.deletePost(),
        o.getPosts(),
        oCommon.toast({ status: "success", text: "Article supprimé." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const resetSelectedPost: Operator = o.resetSelectedPost();
