import { OnInitialize } from "overmind";
import Cookies from "js-cookie";

const onInitialize: OnInitialize = async ({ actions }) => {
    actions.setIsLoading2(true);

    if (Cookies.get("digitalcse")) {
        await actions.getAuthedUser(Cookies.get("digitalcse") || "");
        await actions.getSettings();
    } else {
        await actions.getBasicSettings();
    }

    actions.setIsLoading2(false);
};

export default onInitialize;
