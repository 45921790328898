import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Divider, Popconfirm, Row } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { useOvermind } from "store";

// Components
import OZInput from "components/OZInput";
import OZForm from "components/OZForm";
import OZSelect from "components/OZSelect";
import OZInputNumber from "../../../components/OZInputNumber";

type ParamsType = {
    id?: string;
};

const AccountSettings = ({ isProfileSite }: any) => {
    const { actions, state } = useOvermind();
    const history = useHistory();
    const { id = "" }: ParamsType = useParams();

    const user = state.users.selected;

    const initialValues =
        id === "new"
            ? {}
            : {
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                  phoneNumber: user.phoneNumber,
                  addressLine1: user.addressLine1,
                  addressLine2: user.addressLine2,
                  city: user.city,
                  country: user.country,
                  postcode: user.postcode,
                  role: user.role,
                  status: user.status,
                  wallet: user.wallet,
                  isVisible: {
                      profile: user.isVisible && user.isVisible.profile,
                      email: user.isVisible && user.isVisible.email,
                      phoneNumber: user.isVisible && user.isVisible.phoneNumber,
                  },
                  bankTransfer: {
                      accountDetails: {
                          accountName:
                              user.bankTransfer &&
                              user.bankTransfer.accountDetails.accountName,
                          iban:
                              user.bankTransfer && user.bankTransfer.accountDetails.iban,
                          bicSwift:
                              user.bankTransfer &&
                              user.bankTransfer.accountDetails.bicSwift,
                      },
                  },
              };

    const onSubmit = async (data: any) => {
        if (id === "new") {
            const link = window.location.origin;
            await actions.users.createUser({ ...data, link });
        } else {
            if (!isProfileSite) {
                await actions.users.updateUser({ id, data });
            } else {
                await actions.users.updateMyself({ data });
            }
        }

        if (!isProfileSite) history.push("/admin/users");
        else {
            await actions.getAuthedUser(state.user.token);
            history.push("/");
        }
    };

    return (
        <OZForm initialValues={initialValues} onSubmit={onSubmit}>
            <h3>Général</h3>
            <p>Réglages généraux.</p>
            <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <OZInput
                        label="Prénom"
                        name="firstName"
                        placeholder="Prénom"
                        rules={validationRules.firstName}
                    />
                    <OZInput
                        label="Adresse email"
                        name="email"
                        placeholder="Adresse email"
                        rules={validationRules.email}
                    />
                    {!isProfileSite && (
                        <OZInputNumber
                            label={`Portefeuille pour les subventions`}
                            name="wallet"
                        />
                    )}
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <OZInput
                        label="Nom"
                        name="lastName"
                        placeholder="Nom"
                        rules={validationRules.lastName}
                    />
                    <OZInput
                        label="N° de téléphone"
                        name="phoneNumber"
                        placeholder="N° de téléphone"
                    />
                </Col>
            </Row>

            <Divider dashed />
            <h3>Adresse de livraison / facturation</h3>
            <p>Informations utilisées pour la boutique.</p>
            <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <OZInput label="Adresse" name="addressLine1" placeholder="Adresse" />
                    <OZInput label="Ville" name="city" placeholder="Ville" />
                    <OZInput label="Pays" name="country" placeholder="Pays" />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <OZInput
                        label="Adresse 2"
                        name="addressLine2"
                        placeholder="Adresse 2"
                    />
                    <OZInput
                        label="Code postal"
                        name="postcode"
                        placeholder="Code postal"
                    />
                </Col>
            </Row>

            <Divider dashed />
            <h3>Coordonnées bancaires</h3>
            <p>
                Obligatoire si vous souhaitez obtenir un remboursement par virement
                bancaire sur les subventions.
            </p>
            <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <OZInput
                        label="Titulaire du compte"
                        name={["bankTransfer", "accountDetails", "accountName"]}
                        placeholder="Titulaire du compte"
                    />
                    <OZInput
                        label="IBAN"
                        name={["bankTransfer", "accountDetails", "iban"]}
                        placeholder="FRXX XXXX XXXX XXXX XXXX XXXX XXX"
                    />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <OZInput
                        label="BIC"
                        name={["bankTransfer", "accountDetails", "bicSwift"]}
                        placeholder="XXXXXXXXXXX"
                    />
                </Col>
            </Row>

            <Divider dashed />
            <h3>Confidentialité</h3>
            <p>Afficher ou non les informations aux autres membres.</p>
            <Row gutter={[16, 16]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <OZSelect
                        defaultValue={
                            initialValues.isVisible
                                ? initialValues.isVisible.profile
                                : true
                        }
                        label="Profil"
                        name={["isVisible", "profile"]}
                        values={[
                            { label: "Afficher son profil", value: true },
                            { label: "Cacher son profil", value: false },
                        ]}
                    />
                    <OZSelect
                        defaultValue={
                            initialValues.isVisible
                                ? initialValues.isVisible.phoneNumber
                                : false
                        }
                        label="N° de téléphone"
                        name={["isVisible", "phoneNumber"]}
                        values={[
                            { label: "Afficher son numéro de téléphone", value: true },
                            { label: "Cacher son numéro de téléphone", value: false },
                        ]}
                    />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <OZSelect
                        defaultValue={
                            initialValues.isVisible
                                ? initialValues.isVisible.email
                                : false
                        }
                        label="Adresse email"
                        name={["isVisible", "email"]}
                        values={[
                            { label: "Afficher son adresse email", value: true },
                            { label: "Cacher son adresse email", value: false },
                        ]}
                    />
                </Col>
            </Row>

            {!isProfileSite && (
                <>
                    <Divider dashed />
                    <h3>Accès / Permissions</h3>
                    <p>Modifier les accès et les permissions liées au compte.</p>
                    <Row gutter={[16, 16]}>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <OZSelect
                                defaultValue={initialValues.role || "MEMBER"}
                                disabled={id === state.user._id}
                                label="Rôle"
                                name="role"
                                values={[
                                    { label: "Administrateur", value: "ADMIN" },
                                    { label: "Membre", value: "MEMBER" },
                                ]}
                            />
                            {id !== state.user._id && (
                                <OZInput
                                    label={
                                        id === "new"
                                            ? "Mot de passe"
                                            : "Changer de mot de passe"
                                    }
                                    name="password"
                                    rules={[
                                        {
                                            required: id === "new",
                                            pattern: new RegExp(
                                                "^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
                                            ),
                                            message:
                                                "Le mot de passe doit faire plus de 8 caractères, contenir un chiffre et une majuscule.",
                                        },
                                    ]}
                                />
                            )}
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                            <OZSelect
                                defaultValue={initialValues.status || false}
                                disabled={id === state.user._id}
                                label="Statut"
                                name="status"
                                values={[
                                    { label: "Compte activé", value: true },
                                    { label: "Compte désactivé", value: false },
                                ]}
                            />
                        </Col>
                    </Row>
                </>
            )}
            <Row gutter={[16, 16]}>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        loading={state.isLoading}
                    >
                        {id !== "new" ? "Enregistrer" : "Créer le compte"}
                    </Button>
                </Col>
            </Row>
        </OZForm>
    );
};

const validationRules = {
    firstName: [{ required: true, message: "Veuillez indiquer un prénom." }],
    lastName: [{ required: true, message: "Veuillez indiquer un nom." }],
    email: [
        { type: "email", message: "Cette adresse email est invalide." },
        { required: true, message: "Veuillez indiquer une adresse email." },
    ],
};

export default AccountSettings;
