import React, { useEffect, useState } from "react";
import { useOvermind } from "store";
import { Button, Card, Col, List, Row, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";

// Components
import Post from "blocks/Post";

const { Option } = Select;

const Posts = () => {
    const { actions, state } = useOvermind();
    const { list } = state.posts;
    const { isLoading } = state;
    const categories = state.categories.post.list;

    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({ category: "" });
    const [results, setResults] = useState<any>([]);

    useEffect(() => {
        actions.posts.getPosts();
        actions.categories.getCategories("post");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setResults(list);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.length > 0]);

    const search = ({ category }: any) => {
        let newItems = [...list];

        if (category) {
            newItems = newItems.filter((i: any) => i.category._id.includes(category));
        }

        setResults(newItems);
        setCurrentPage(1);
    };

    const header = (
        <Row align="middle" gutter={[8, 8]} justify="space-around">
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                {results.length} articles trouvés
            </Col>
            <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                <Select
                    defaultValue=""
                    onChange={(e: any) => setFilters({ ...filters, category: e })}
                    placeholder="Catégories"
                    style={{ width: "100%" }}
                >
                    <Option value="">Toutes les catégories</Option>
                    {categories.map((c: any) => (
                        <Option value={c._id}>{c.name}</Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <Button
                    block
                    onClick={() => {
                        search({
                            category: filters.category,
                        });
                    }}
                    type="primary"
                    icon={<SearchOutlined />}
                >
                    Rechercher
                </Button>
            </Col>
        </Row>
    );

    return (
        <Card>
            <StyledList
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 3,
                }}
                pagination={{
                    current: currentPage,
                    onChange: (page: any) => setCurrentPage(page),
                    pageSize: 9,
                }}
                dataSource={results}
                header={header}
                loading={isLoading}
                renderItem={(item: any) => {
                    return (
                        <Item>
                            <Post content={item} />{" "}
                        </Item>
                    );
                }}
            />
        </Card>
    );
};

// Styles
const StyledList = styled(List)`
    .ant-list-header {
        margin-bottom: 13px;
    }
`;

const Item = styled(List.Item)`
    cursor: pointer;

    &:hover {
        background: #fafafa;
    }

    .ant-empty-image {
        display: grid;
        align-items: center;
        height: 100%;
    }

    .ant-empty-image span {
        height: 100%;
    }
`;

export default Posts;
