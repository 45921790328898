import { Operator, pipe, tryCatch } from "overmind";
import * as o from "./operators";
import * as oCommon from "store/operators";

const COMMON_ERROR_MESSAGE = "Une erreur est survenue.";

export const getCategories: Operator<string> = o.getCategories();

export const createCategory: Operator<any> = tryCatch({
    try: pipe(
        o.createCategory(),
        oCommon.toast({ status: "success", text: "Catégorie créée." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const updateCategory: Operator<any> = tryCatch({
    try: pipe(
        o.updateCategory(),
        oCommon.toast({ status: "success", text: "Catégorie mise à jour." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const deleteCategory: Operator<any> = tryCatch({
    try: pipe(
        o.deleteCategory(),
        oCommon.toast({ status: "success", text: "Catégorie supprimée." })
    ),
    catch: oCommon.toast({ status: "error" }),
});
