import React from "react";
import { Button } from "antd";
import styled from "styled-components";

import OZResult from "components/OZResult";

const AccountCreated = ({ setPanel }: { setPanel: any }) => {
    return (
        <StyledAccountCreated>
            <OZResult
                status={"success"}
                title={"Votre compte a été créé avec succès !"}
                subTitle={
                    "Un responsable va activer votre compte dans les plus brefs délais."
                }
                extra={[
                    <Button
                        key="home"
                        ghost
                        onClick={() => setPanel("lookup")}
                        type="primary"
                    >
                        Retourner sur la page de connexion
                    </Button>,
                ]}
            />
        </StyledAccountCreated>
    );
};

const StyledAccountCreated = styled.div`
    display: grid;
    grid: auto / 1fr;
    align-items: center;
    height: 100%;
`;

export default AccountCreated;
