import React, { useEffect } from "react";
import { Button, Divider, Form, Input, message } from "antd";
import { useOvermind } from "store";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

// Components
import OZInput from "components/OZInput";
import OZForm from "components/OZForm";

// Blocks
import Logo from "blocks/Logo";

const ResetPwd = ({ token, setPanel }: { token: string; setPanel: any }) => {
    const { actions, state } = useOvermind();
    const history = useHistory();

    useEffect(() => {
        const input: any = document.getElementById("digitalcse_oldPassword");
        input.focus();
    }, []);

    const onSubmit = async (data: any) => {
        try {
            await actions.resetPassword({ ...data, token });
            message.success(
                "Mot de passe modifié. Vous pouvez vous connecter avec votre nouveau mot de passe."
            );
            history.push("/login");
        } catch (e) {
            message.error(e);
        }
    };

    return (
        <OZForm onSubmit={onSubmit}>
            <Logo />

            <Top>
                <h2>Modifier votre mot de passe</h2>
                <p>Veuillez compléter le formulaire ci-dessous.</p>
            </Top>

            <OZInput
                icon={<i className="ri-lock-line" />}
                name="oldPassword"
                placeholder="Mot de passe actuel"
                size="large"
            />

            <OZInput
                icon={<i className="ri-lock-line" />}
                name="newPassword"
                placeholder="Mot de passe"
                rules={validationRules.password}
                size="large"
                type="password"
            />

            <Form.Item
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                rules={validationRules.passwordConfirmation}
            >
                <Input
                    placeholder="Confirmer le mot de passe"
                    prefix={<i className="ri-lock-line" />}
                    size="large"
                    type="password"
                />
            </Form.Item>

            <GroupButton>
                <Button
                    ghost
                    onClick={() => setPanel("login")}
                    size="large"
                    type="primary"
                >
                    Retour
                </Button>

                <Button
                    block
                    htmlType="submit"
                    loading={state.isLoading}
                    size="large"
                    type="primary"
                >
                    Enregistrer le mot de passe
                </Button>
            </GroupButton>
        </OZForm>
    );
};

const validationRules = {
    password: [
        {
            required: true,
            pattern: new RegExp("^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"),
            message:
                "Le mot de passe doit faire plus de 8 caractères, contenir un chiffre et une majuscule.",
        },
    ],
    passwordConfirmation: [
        {
            required: true,
            message: "Veuillez confirmer le mot de passe.",
        },
        ({ getFieldValue }: { getFieldValue: any }) => ({
            validator(rule: any, value: any) {
                if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                }
                return Promise.reject("Les deux mots de passe doivent correspondre.");
            },
        }),
    ],
};

const Top = styled.div`
    text-align: center;
    margin: 24px 0 !important;

    h2 {
        font-weight: 300;
        margin-bottom: 0;
    }

    p {
        font-size: 15px;
        font-weight: 200;
        margin-bottom: 0;
    }
`;

const GroupButton = styled.div`
    display: grid;
    grid: auto / auto 1fr;
    align-items: center;
    grid-gap: 8px;
`;

export default ResetPwd;
