import { Operator, mutate, run } from "overmind";

export const getPost: () => Operator<any> = () =>
    mutate(async function getPost({ effects, state }, id) {
        state.posts.selected = await effects.get(`posts/${id}`);
    });

export const getPosts: () => Operator = () =>
    mutate(async function getPosts({ state, effects }) {
        const res = await effects.get("posts");
        state.posts.length = res.length;
        state.posts.list = res.posts;
    });

export const getPostsByCategory: () => Operator<string> = () =>
    mutate(async function getPostsByCategory({ state, effects }, category) {
        const res = await effects.get(`/posts/category/${category}`);
        state.posts.length = res.length;
        state.posts.list = res.posts;
    });

export const createPost: () => Operator<any> = () =>
    run(async function createPost({ effects }, props) {
        await effects.post({ url: "/posts", data: props });
    });

export const updatePost: () => Operator<any> = () =>
    run(async function updatePost({ effects }, props) {
        await effects.put({ url: `/posts/${props.id}`, data: props.data });
    });

export const deletePost: () => Operator<any> = () =>
    run(async function deletePost({ effects }, id) {
        await effects.del(`posts/${id}`);
    });

export const resetSelectedPost: () => Operator = () =>
    mutate(async function resetSelectedPost({ state }) {
        state.posts.selected = {};
    });
