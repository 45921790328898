import React from "react";
import OZDrawer from "../components/OZDrawer";

const Documentation = ({ onClose, visible }: any) => {
    return (
        <OZDrawer
            onClose={onClose}
            visible={visible}
            title="Documentation"
            bodyStyle={{ height: "calc(100% - 60px)", padding: 0 }}
            width={294}
        >
            <div
                dangerouslySetInnerHTML={{
                    __html:
                        "<iframe frameBorder='0' style='height: 100%; width: 100%' src='https://digitalcse-doc.netlify.app/docs/connexion-inscription/' />",
                }}
                style={{ height: "100%" }}
            />
        </OZDrawer>
    );
};

export default Documentation;
