import React, { useState } from "react";
import { Button, message, Upload } from "antd";
import { useOvermind } from "store";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";

const OZUploadFiles = ({
    action,
    id,
    initialFiles = [],
    pathName = "medias",
    sizeLimit = 3,
}: any) => {
    const { actions } = useOvermind();
    const [files, setFiles] = useState(initialFiles || []);

    const handleAction = async (e: any) => {
        message.loading("Envoi en cours...");

        const formData = new FormData();
        formData.append("pathName", pathName);
        formData.append("files", e);

        const res: any = await actions.uploadManyFiles(formData);

        const hasPROTOCOL = res[0].location.includes("https://");
        const url = hasPROTOCOL ? res[0].location : `https://${res[0].location}`;
        const fileName = res[0].key;

        await action({ id, data: { pictures: [...files, { fileName, url }] } });
        setFiles((prevValue: any) => [...prevValue, { fileName, url }]);

        return " ";
    };

    const beforeUpload = (file: any) => {
        const isTooLarge = file.size / 1024 / 1024 > sizeLimit;
        if (isTooLarge) {
            message.error(`Fichier trop volumineux (max. ${sizeLimit}Mo).`);
            return false;
        }

        const isPic = file.type === "image/jpeg" || file.type === "image/png";
        if (!isPic) {
            message.error("Formats acceptés: JPG et PNG.");
            return false;
        }

        return true;
    };

    const handleDelete = async (f: any) => {
        await actions.deleteFiles({ fileName: f.fileName });
        const mutatedFiles = [...files];
        const ind = mutatedFiles.findIndex(({ fileName }) => fileName === f.fileName);
        mutatedFiles.splice(ind, 1);
        await action({ id, data: { pictures: mutatedFiles } });
        setFiles(mutatedFiles);
    };

    return (
        <>
            <div className="ant-form-item-label">
                <label>Photos - {files.length} sur 20</label>
            </div>

            <StyledGallery>
                {files.length < 20 && (
                    <div>
                        <StyledUpload
                            listType="picture-card"
                            showUploadList={false}
                            action={handleAction}
                            beforeUpload={beforeUpload}
                        >
                            <PlusOutlined />
                        </StyledUpload>
                    </div>
                )}
                {files.map((f: any) => (
                    <StyledImage>
                        <StyledActions>
                            <Button
                                onClick={() => handleDelete(f)}
                                icon={<DeleteOutlined />}
                                type="danger"
                            />
                        </StyledActions>
                        <img src={f.url} alt={f.fileName} />
                    </StyledImage>
                ))}
            </StyledGallery>
        </>
    );
};

// Styles
const StyledActions = styled.div`
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
`;

const StyledGallery = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
    grid-gap: 8px;
`;

const StyledImage = styled.div`
    position: relative;

    &:hover ${StyledActions} {
        display: grid;
        align-items: center;
        justify-content: center;
    }

    & img {
        width: 128px;
        height: 128px;
        border: 1px dashed #d9d9d9;
        object-fit: cover;
        padding: 2px;
        border-radius: 2px;
    }

    & img:hover {
        border: 1px dashed #424242;
        cursor: pointer;
    }
`;

const StyledUpload = styled(Upload)`
    & > .ant-upload {
        margin: 0;
        width: 128px;
        height: 128px;
    }

    & > .ant-upload.ant-upload-select-picture-card > .ant-upload {
        padding: 0;
    }

    & > .ant-upload.ant-upload-select-picture-card > .ant-upload img {
        width: 128px;
        height: 128px;
        object-fit: cover;
        border-radius: 2px;
    }
`;

export default OZUploadFiles;
