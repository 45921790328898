import React, { useEffect } from "react";
import { Tabs } from "antd";
import { useOvermind } from "store";
import styled from "styled-components";

import General from "./General";
import Subventions from "./Subventions";
import Design from "./Design";

const { TabPane } = Tabs;

const Settings = () => {
    const { actions, state } = useOvermind();
    const { subventions } = state.settings.modules;

    useEffect(() => {
        actions.getSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Général" key="1">
                    <General />
                </TabPane>
                <TabPane tab="Design" key="2">
                    <Design />
                </TabPane>
                {subventions.status && (
                    <TabPane tab="Subventions" key="3">
                        <Subventions />
                    </TabPane>
                )}
            </Tabs>
        </Container>
    );
};

const Container = styled.div`
    padding: 24px;
    background: #fff;
    min-height: calc(100% - 130px);
`;

export default Settings;
