import React from "react";
import { Form, Radio } from "antd";
import { OZRadioType } from "../helpers/types";

const OZRadio = ({ disabled, label, name, options, rules }: OZRadioType) => {
    const radioStyle = {
        display: "block",
        height: "30px",
        lineHeight: "30px",
    };

    return (
        <Form.Item label={label} name={name} rules={rules}>
            <Radio.Group disabled={disabled}>
                {options &&
                    options.map((o) => (
                        <Radio style={radioStyle} value={o}>
                            {o}
                        </Radio>
                    ))}
            </Radio.Group>
        </Form.Item>
    );
};

export default OZRadio;
