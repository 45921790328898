import { Operator, mutate, run } from "overmind";

export const getRequests: () => Operator = () =>
    mutate(async function getRequests({ state, effects }) {
        const res = await effects.get("requests");
        state.requests.length = res.length;
        state.requests.list = res.requests;
    });

export const createRequest: () => Operator<any> = () =>
    run(async function createRequest({ effects }, props) {
        await effects.post({ url: "/requests", data: props });
    });

export const deleteRequest: () => Operator<any> = () =>
    run(async function deleteRequest({ effects }, id) {
        await effects.del(`requests/${id}`);
    });
