import React, { useState } from "react";
import {
    Button,
    Card,
    Form,
    InputNumber,
    message,
    Statistic,
    Steps,
    Tooltip,
    Upload,
} from "antd";
import { FileOutlined, PlusOutlined } from "@ant-design/icons";
import { useOvermind } from "store";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

// Components
import OZForm from "components/OZForm";
import OZSelect from "components/OZSelect";
import OZAlert from "components/OZAlert";

const { Step } = Steps;

const AddSubvention = ({ setVisible, user }: any) => {
    const { actions, state } = useOvermind();
    const [step, setStep] = useState(0);
    const [file, setFile] = useState({ fileName: "", url: "" });
    const history = useHistory();

    const onSubmit = async (data: any) => {
        const mutatedData = {
            ...data,
            user: state.user._id,
            createdBy: state.user._id,
            ...file,
        };
        await actions.setIsFetching(true);
        await actions.subventions.createSubvention(mutatedData);
        await setVisible(false);
        await actions.setIsFetching(false);
    };

    const hasAddress = state.user.addressLine1 && state.user.city && state.user.postcode;

    const emptyWaller = state.user.wallet <= 0;

    const handleAction = async (e: any) => {
        message.loading("Envoi en cours...");

        const formData = new FormData();
        formData.append("pathName", "subventions");
        formData.append("upload", e);

        if (file.url) {
            await actions.deleteFiles({ fileName: file.fileName });
        }

        const res: any = await actions.uploadFiles(formData);
        message.destroy();
        message.success("Fichier envoyé.");

        setFile({ fileName: res[0].key, url: res[0].location });
        return "";
    };

    const beforeUpload = (file: any) => {
        const isTooLarge = file.size / 1024 / 1024 > 5;
        if (isTooLarge) {
            message.error(`Fichier trop volumineux (max. ${5}Mo).`);
            return false;
        }

        const isFile =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "application/pdf";

        if (!isFile) {
            message.error("Formats acceptés: JPG, PNG et PDF.");
            return false;
        }

        return true;
    };

    const renderAlertMessage = () => {
        if (state.user.wallet <= 0) {
            return `Vous ne pouvez pas faire de demande de subvention car votre portefeuille est à 0€. Veuillez vérifier si vous avez des subventions en attente sur votre profil.`;
        }

        if (!hasAddress) {
            return `Vous devez renseigner votre adresse de facturation/livraison sur votre profil afin de pouvoir faire une
                    demande de subvention.`;
        }
    };

    return (
        <Card bordered={false}>
            {emptyWaller || !hasAddress ? (
                <OZAlert
                    banner={false}
                    closable={false}
                    message={renderAlertMessage()}
                    style={{ marginBottom: 8, textAlign: "center" }}
                    type="error"
                />
            ) : (
                <>
                    <Steps progressDot size="small" current={step}>
                        <Step title="Justificatif" />
                        <Step title="Montant" />
                    </Steps>

                    {step === 0 && (
                        <>
                            <Top>
                                <Card>
                                    <Statistic
                                        title="Votre portefeuille"
                                        suffix="€"
                                        value={state.user.wallet}
                                    />
                                </Card>
                                <Card>
                                    Avant d'effectuer une demande de remboursement,
                                    veuillez lire les{" "}
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a
                                        onClick={async () => {
                                            await setVisible(false);
                                            history.push("/subventions");
                                        }}
                                    >
                                        conditions de subventionnement
                                    </a>
                                    .
                                </Card>
                            </Top>
                            <Tooltip
                                placement="right"
                                title={file.fileName}
                                style={{ display: !file.url ? "none" : "inherit" }}
                            >
                                <div>
                                    Ajouter un justificatif (JPG, PNG ou PDF) :
                                    <StyledUpload
                                        listType="picture-card"
                                        showUploadList={false}
                                        action={handleAction}
                                        beforeUpload={beforeUpload}
                                    >
                                        {file.url ? <FileOutlined /> : <PlusOutlined />}
                                    </StyledUpload>
                                </div>
                            </Tooltip>
                            <Button
                                block
                                disabled={
                                    state.user.wallet === 0 || !hasAddress || !file.url
                                }
                                onClick={() => setStep(1)}
                                type="primary"
                                size="large"
                            >
                                Suivant
                            </Button>
                        </>
                    )}

                    {step === 1 && (
                        <div style={{ marginTop: 24 }}>
                            <OZForm
                                initialValues={{
                                    amount: state.user.wallet,
                                    type: "CHECK",
                                }}
                                onSubmit={onSubmit}
                            >
                                <Form.Item label="Montant du remboursement" name="amount">
                                    <InputNumber
                                        defaultValue={state.user.wallet}
                                        disabled={state.user.wallet === 0 || !hasAddress}
                                        min={0}
                                        max={state.user.wallet}
                                        size="large"
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                                <OZSelect
                                    defaultValue="CHECK"
                                    disabled={state.user.wallet === 0 || !hasAddress}
                                    label="Type de remboursement"
                                    name="type"
                                    values={[
                                        { label: "Chèque", value: "CHECK" },
                                        {
                                            label: "Virement bancaire",
                                            value: "BANKTRANSFER",
                                        },
                                    ]}
                                />
                                <Button
                                    block
                                    disabled={state.user.wallet === 0 || !hasAddress}
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    loading={state.isLoading}
                                >
                                    Envoyer la demande
                                </Button>
                            </OZForm>
                        </div>
                    )}
                </>
            )}
        </Card>
    );
};

// Styles
const Top = styled.div`
    display: grid;
    grid-gap: 8px;
    margin-bottom: 24px;
    margin-top: 24px;
`;

const StyledUpload = styled(Upload)`
    margin-top: 12px;
    margin-bottom: 12px;

    & > .ant-upload {
        margin: 0;
        width: 100%;
        height: 96px;
    }

    & > .ant-upload.ant-upload-select-picture-card > .ant-upload {
        padding: 0;
    }

    & > .ant-upload.ant-upload-select-picture-card > .ant-upload img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2px;
    }
`;

export default AddSubvention;
