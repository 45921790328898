import { Operator, pipe, tryCatch } from "overmind";
import * as o from "./operators";
import * as oCommon from "store/operators";

const COMMON_ERROR_MESSAGE = "Une erreur est survenue.";

export const getDocument: Operator<string> = tryCatch({
    try: o.getDocument(),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const getDocuments: Operator = pipe(
    oCommon.setIsLoading(true),
    o.getDocuments(),
    oCommon.setIsLoading(false)
);

export const getDocumentsByCategory: Operator<string> = o.getDocumentsByCategory();

export const createDocument: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.createDocument(),
        o.getDocuments(),
        oCommon.toast({ status: "success", text: "Document créé." }),
        oCommon.setIsLoading(false)
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const updateDocument: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.updateDocument(),
        o.getDocuments(),
        oCommon.destroyToast(),
        oCommon.toast({ status: "success", text: "Document mis à jour." }),
        oCommon.setIsLoading(false)
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const deleteDocument: Operator<string> = tryCatch({
    try: pipe(
        o.deleteDocument(),
        o.getDocuments(),
        oCommon.toast({ status: "success", text: "Document supprimé." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const resetSelectedDocument: Operator = o.resetSelectedDocument();
