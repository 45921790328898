import React, { useEffect } from "react";
import { useOvermind } from "store";
import { useHistory, useParams } from "react-router-dom";
import format from "moment";

// Components
import OZInput from "components/OZInput";
import OZTextEditor from "components/OZTextEditor";

// Templates
import BasicForm from "templates/BasicForm";

type ParamsType = {
    id?: string;
};

const Offer = () => {
    const { actions, state } = useOvermind();
    const history = useHistory();
    const { id = "" }: ParamsType = useParams();
    const sideFields = useFields();
    // const { selected } = state.offers;

    /*
    useEffect(() => {
        fetchData();

        return () => {
            actions.offers.resetSelectedOffer();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const fetchData = async () => {
        actions.setIsFetching(true);
        if (id && id !== "new") await actions.offers.getOffer(id);
        actions.setIsFetching(false);
    };
    */

    const onSubmit = async (data: any) => {
        //    if (id === "new") await actions.offers.createOffer(data);
        //    else await actions.offers.updateOffer({ id, data });
        history.push("/admin/offers");
    };

    const onDelete = async () => {
        //    id && (await actions.offers.deleteOffer(id));
        history.push("/admin/offers");
    };

    const initialValues = {
        name: id !== "new" ? selected.name : "",
        description: id !== "new" ? selected.description : "",
        createdAt: id !== "new" ? format(selected.createdAt) : format(new Date()),
        type: id !== "new" && selected.type ? selected.type : "",
        picture: id !== "new" && selected.picture ? selected.picture : "",
    };

    const annonceInitialValues = {
        price: id !== "new" && selected.price ? selected.price : "",
    };

    const covoiturageInitialValues = {
        passengers: id !== "new" && selected.passengers ? selected.passengers : "",
        date: id !== "new" && selected.date ? format(selected.date) : format(new Date()),
        time: id !== "new" && selected.time ? format(selected.time) : format(new Date()),
        departure: id !== "new" && selected.departure ? selected.departure : "",
        arrival: id !== "new" && selected.arrival ? selected.arrival : "",
    };

    return (
        <BasicForm
            deleteText="cette annonce"
            id={id}
            initialValues={
                selected.type && selected.type === "ANNONCE"
                    ? { ...initialValues, ...annonceInitialValues }
                    : { ...initialValues, ...covoiturageInitialValues }
            }
            mainFields={
                <>
                    <OZInput
                        label="Titre de l'annonce"
                        name="name"
                        placeholder="Titre"
                        rules={validationRules.title}
                    />
                    <OZTextEditor label="Contenu de l'annonce" name="description" />
                </>
            }
            onDelete={onDelete}
            onSubmit={onSubmit}
            sideFields={sideFields}
        />
    );
};

const useFields = () => {
    // const { state } = useOvermind();
    // const { selected } = state.offers;
    // const { offer } = state.categories;

    const annonceSideFields = [
        {
            name: "price",
            label: "Prix",
            type: "number",
        },
    ];

    const covoiturageSideFields = [
        {
            name: "departure",
            label: "Départ",
            type: "input",
        },
        {
            name: "arrival",
            label: "Arrivée",
            type: "input",
        },
        {
            name: "date",
            label: "Date de départ",
            style: { width: "100%" },
            type: "date",
        },
        {
            name: "time",
            label: "Heure de départ",
            style: { width: "100%" },
            type: "time",
        },
        {
            name: "passengers",
            label: "Nombre de passagers",
            type: "number",
        },
    ];

    const sideFields = [
        {
            name: "type",
            defaultValue: selected.type && selected.type,
            disabled: true,
            label: "Type",
            values: [selected.type],
            type: "select",
        },
        {
            name: "createdAt",
            disabled: true,
            label: "Date de publication",
            style: { width: "100%" },
            type: "date",
        },
    ];

    if (selected.type && selected.type === "ANNONCE") {
        return [...annonceSideFields, ...sideFields];
    }

    if (selected.type && selected.type === "COVOITURAGE") {
        return [...covoiturageSideFields, ...sideFields];
    }

    return sideFields;
};

const validationRules = {
    title: [{ required: true, message: "Veuillez indiquer un titre." }],
};

const selected = {
    type: "COVOITURAGE",
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at placerat augue, id tempus orci. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse scelerisque elit nec augue convallis volutpat. Aliquam aliquam, ipsum sit amet tincidunt volutpat, felis augue venenatis augue, nec efficitur nibh felis consequat arcu. Vivamus vitae erat justo. Sed velit tortor, scelerisque sed nibh eu, semper bibendum diam. Aenean sed nisl sem. Aliquam ultrices convallis massa a eleifend. Aliquam congue sapien non commodo consectetur. Duis ac sem a nibh fringilla tincidunt et porttitor magna. Integer auctor velit lorem, et ultrices quam commodo non. Morbi mollis nunc eros, id malesuada mauris consectetur a. In vitae leo vitae ipsum faucibus scelerisque eget nec sapien.
Nulla ac nisl dui. Praesent eu mi ut nisl sollicitudin laoreet sit amet id libero. Duis quis ipsum at erat eleifend consectetur sit amet quis nisl. Curabitur egestas mattis ligula id malesuada. Pellentesque lacus dui, ullamcorper sit amet felis nec, consectetur convallis nunc. Sed feugiat ultricies quam, tincidunt rhoncus sapien pulvinar et. Maecenas ac lectus suscipit, elementum lectus eu, maximus velit. Duis eget magna varius justo ultricies facilisis in sed orci. Cras faucibus leo a enim fringilla, eget tincidunt metus mollis. Aenean hendrerit tristique sem ac pretium. In luctus, turpis quis accumsan iaculis, turpis libero rhoncus turpis, eget fermentum enim quam a mauris. Pellentesque non consectetur felis, vitae pharetra augue. Cras porta urna nec imperdiet finibus. `,
    picture: "",
    createdAt: "2020-10-12T11:15:09.956Z",
    name: "Aller-retour pour la web-conférence 2021 sur Nantes",
    price: 5,
    departure: "Paris",
    arrival: "Marseille",
    date: "2020-10-12T11:15:09.956Z",
    time: "2020-10-12T11:15:09.956Z",
    passengers: 3,
};
export default Offer;
