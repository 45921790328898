import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useOvermind } from "store";
import format from "moment";
import OZInput from "components/OZInput";
import OZUploadFiles from "components/OZUploadFiles";
import BasicForm from "templates/BasicForm";
import styled from "styled-components";

type ParamsType = {
    id?: string;
};

const Media = () => {
    const { actions, state } = useOvermind();
    const history = useHistory();
    const { id = "" }: ParamsType = useParams();
    const sideFields = useFields();
    const { selected } = state.medias;

    useEffect(() => {
        fetchData();

        return () => {
            actions.medias.resetSelectedMedia();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        actions.setIsFetching(true);
        if (id && id !== "new") await actions.medias.getMedia(id);
        await actions.categories.getCategories("media");
        actions.setIsFetching(false);
    };

    const onSubmit = async (data: any) => {
        if (id === "new") await actions.medias.createMedia(data);
        else await actions.medias.updateMedia({ id, data });
        history.push("/admin/medias");
    };

    const onDelete = async () => {
        id && (await actions.medias.deleteMedia(id));
        history.push("/admin/medias");
    };

    const initialValues = {
        name: id !== "new" ? selected.name : "",
        createdAt: id !== "new" ? format(selected.createdAt) : format(new Date()),
        category: id !== "new" && selected.category ? selected.category._id : "",
    };

    return (
        <BasicForm
            deleteText="cette galerie"
            id={id}
            initialValues={initialValues}
            mainFields={
                <>
                    <OZInput
                        label="Titre de la galerie"
                        name="name"
                        placeholder="Titre"
                        rules={validationRules.title}
                    />
                    {id === "new" ? (
                        <StyledInfo>
                            Veuillez créer la galerie avant d'ajouter des photos.
                        </StyledInfo>
                    ) : (
                        <OZUploadFiles
                            action={actions.medias.updateMedia}
                            id={id}
                            initialFiles={selected.pictures ? selected.pictures : []}
                        />
                    )}
                </>
            }
            onDelete={onDelete}
            onSubmit={onSubmit}
            sideFields={sideFields}
        />
    );
};

const useFields = () => {
    const { state } = useOvermind();
    const { selected } = state.medias;
    const { media } = state.categories;

    const selectCategories = (data: any) => {
        const categories: any[] = [];
        data && data.map((c: any) => categories.push({ label: c.name, value: c._id }));
        return categories;
    };

    return [
        {
            name: "category",
            defaultValue: selected.category && selected.category._id,
            label: "Catégorie",
            values: selectCategories(media.list),
            rules: validationRules.category,
            type: "select",
        },
        {
            name: "createdAt",
            disabled: true,
            label: "Date de publication",
            style: { width: "100%" },
            type: "date",
        },
    ];
};

const validationRules = {
    title: [{ required: true, message: "Veuillez indiquer un titre." }],
    category: [{ required: true, message: "Veuillez sélectionner une catégorie." }],
};

const StyledInfo = styled.div`
    border: 1px solid #f0f0f0;
    display: grid;
    align-items: center;
    justify-content: center;
    height: 120px;
`;

export default Media;
