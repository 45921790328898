import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "antd";

// Components
import OZResult from "components/OZResult";

class ErrorBoundary extends React.Component {
    state = {};

    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo,
        });
    }

    // GO BACK
    handleBack = () => {
        this.props.history.goBack();
        this.setState({ hasError: false });
    };

    render() {
        if (this.state.hasError) {
            return (
                <OZResult
                    status="500"
                    title="Oups, une erreur est survenue !"
                    subTitle="Nous n'avons pas pu traiter votre demande. Veuillez revenir sur la page précédente."
                    extra={
                        <Button onClick={this.handleBack} type="primary">
                            Revenir à la page précédente
                        </Button>
                    }
                />
            );
        } else return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
