import React from "react";

const DigitalLogo = ({ color, size = 56 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            className="icon"
            viewBox="0 0 1024 1024"
        >
            <path
                fill={color}
                d="M226.56 296.32c7.68 0 15.36-2.56 21.76-8.32 78.08-71.68 178.56-111.36 284.8-111.36 35.2 0 70.4 4.48 104.32 13.44 67.84 17.28 131.2 52.48 182.4 101.12 6.4 5.76 14.08 8.96 21.76 8.96 8.32 0 17.28-3.2 23.04-10.24 12.16-12.8 11.52-33.28-1.28-45.44C803.84 188.16 730.88 147.84 652.8 128c-39.04-10.24-80-15.36-120.32-15.36-121.6 0-238.08 45.44-327.68 128-12.8 12.16-13.44 32-1.92 45.44 7.04 7.04 15.36 10.24 23.68 10.24zm113.28 298.24c0-97.28 72.96-179.84 168.96-191.36 8.32-1.28 16-1.28 24.32-1.28 33.92 0 67.2 8.96 96.64 25.6 30.72 17.92 55.68 42.88 72.32 73.6 16 28.16 24.32 60.8 24.32 93.44 0 17.92 14.08 32 32 32s32-14.08 32-32c0-43.52-10.88-86.4-32-124.16-22.4-40.96-55.68-74.88-96.64-98.56-39.04-22.4-83.2-34.56-128.64-34.56-10.24 0-21.12.64-32 1.92-128.64 16-225.28 125.44-225.28 255.36 0 87.04 23.68 172.16 67.84 247.04 5.76 10.24 16.64 15.36 27.52 15.36 5.76 0 11.52-1.28 16.64-4.48 15.36-8.96 19.84-28.8 10.88-44.16-38.4-64-58.88-138.24-58.88-213.76z"
            ></path>
            <path
                fill={color + "90"}
                d="M810.24 350.08c-41.6-47.36-94.72-83.2-154.24-104.32-39.68-14.08-81.28-21.12-123.52-21.12-31.36 0-62.08 3.84-92.16 11.52-163.2 41.6-277.76 189.44-277.76 358.4 0 35.2 5.12 70.4 14.72 104.32 3.84 14.08 16.64 23.04 30.72 23.04 3.2 0 5.76-.64 8.96-1.28 16.64-5.12 26.88-23.04 21.76-39.68-8.32-28.16-12.16-56.96-12.16-86.4 0-139.52 94.72-261.76 229.76-296.32 24.96-6.4 50.56-9.6 76.16-9.6 34.56 0 69.12 5.76 101.76 17.28 48.64 17.28 92.8 46.72 127.36 86.4 49.28 55.68 76.8 128 76.8 202.24 0 44.16-35.84 80.64-80.64 80.64-6.4 0-13.44-.64-19.84-2.56-35.84-8.96-60.16-40.96-60.16-78.08 0-65.92-44.8-123.52-108.8-140.16-12.16-3.2-24.32-4.48-35.84-4.48-80 0-144.64 64.64-144.64 144.64 0 90.24 32.64 176.64 92.8 244.48 41.6 47.36 94.72 83.2 154.24 104.32 3.84 1.28 7.04 1.92 10.88 1.92 13.44 0 25.6-8.32 30.08-21.12 5.76-16.64-2.56-35.2-19.2-40.96-48.64-17.28-92.8-46.72-127.36-85.76-49.28-55.68-76.8-128-76.8-202.24 0-44.16 35.84-80.64 80.64-80.64 6.4 0 13.44.64 19.84 2.56 35.84 8.96 60.16 40.96 60.16 78.08 0 65.92 44.8 123.52 108.8 140.16 12.16 3.2 24.32 4.48 35.84 4.48 80 0 144.64-64.64 144.64-144.64-.64-90.24-33.28-177.28-92.8-245.12z"
            ></path>
            <path
                fill={color + "50"}
                d="M782.08 786.56c-8.32 1.28-16 1.28-24.32 1.28-33.92 0-67.2-8.96-96.64-25.6-30.72-17.92-55.68-42.88-72.32-73.6-16-28.16-24.32-60.8-24.32-93.44 0-17.92-14.08-32-32-32s-32 14.08-32 32c0 43.52 10.88 86.4 32 124.16 22.4 40.96 55.68 74.88 96.64 97.92 39.04 22.4 83.2 34.56 128.64 34.56 10.24 0 21.12-.64 32-1.92 17.28-1.92 30.08-17.92 27.52-35.84s-17.92-30.08-35.2-27.52z"
            ></path>
        </svg>
    );
};

export default DigitalLogo;
