import React, { useState } from "react";
import { CirclePicker } from "react-color";
import styled from "styled-components";

import { OZColorPickerType } from "helpers/types";

const OZColorPicker = ({ action, color, type }: OZColorPickerType) => {
    const [tempColor, setTempColor] = useState(color);

    const handleUpdate = (hex: string) => {
        setTempColor(hex);

        action({ [type]: hex });
    };

    return (
        <StyledCirclePicker>
            <CirclePicker
                color={tempColor}
                onChangeComplete={({ hex }) => handleUpdate(hex)}
                colors={type === "primaryColor" ? primaryColors : secondaryColors}
            />
        </StyledCirclePicker>
    );
};

const primaryColors = [
    "#eb2f96",
    "#722ed1",
    "#2f54eb",
    "#1890ff",
    "#13c2c2",
    "#52c41a",
    "#fa8c16",
    "#fa541c",
    "#f5222d",
    "#137CBD",
    "#0D8050",
    "#C23030",
    "#A854A8",
    "#7157D9",
    "#2965CC",
    "#008075",
    "#96622D",
];

const secondaryColors = [
    "#10161A",
    "#182026",
    "#202B33",
    "#293742",
    "#30404D",
    "#394B59",
];

const StyledCirclePicker = styled.div`
    .ant-form-item {
        margin-bottom: 0;
    }

    .circle-picker {
        width: 100% !important;
        margin-bottom: 20px !important;
    }
`;

export default OZColorPicker;
