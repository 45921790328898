type StateType = {
    files: any[];
    isFetching: boolean;
    isLoading: boolean;
    isLoading2: boolean;
    settings: any;
    user: any;
};

export const state: StateType = {
    files: [],
    isFetching: false,
    isLoading: false,
    isLoading2: false,
    settings: {
        general: {
            appName: "",
            backgroundImage: "",
            contact: {
                address: "",
                availabilities: "",
                email: "",
                phoneNumber: "",
            },
            limitations: {},
            logo: "",
            primaryColor: "",
            secondaryColor: "",
            status: true,
        },
        modules: {
            boxes: { order: 0, status: false },
            calendar: { order: 0, status: false },
            documents: { order: 0, status: false },
            medias: { order: 0, status: false },
            offers: { order: 0, status: false },
            posts: { order: 0, status: false },
            shop: { order: 0, status: false },
            subventions: { order: 0, status: false },
        },
    },
    user: {
        _id: "",
        email: "",
        firstname: "",
        lastname: "",
        role: "",
        token: "",
    },
};
