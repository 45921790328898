import React from "react";
import { Button, Card, Form } from "antd";
import OZInput from "../../../components/OZInput";
import OZSelect from "../../../components/OZSelect";
import OZTextEditor from "../../../components/OZTextEditor";
import OZInputNumber from "../../../components/OZInputNumber";
import OZDatePicker from "../../../components/OZDatePicker";
import OZTimePicker from "../../../components/OZTimePicker";

const New = () => {
    const [form] = Form.useForm();

    return (
        <Card style={{ maxWidth: 800, margin: "0 auto" }}>
            <Form
                form={form}
                hideRequiredMark
                initialValues={{ type: "ANNONCE" }}
                layout="vertical"
                name="digitalcse"
                //onFinish={onSubmit}
            >
                <OZInput label="Titre" name="title" />
                <OZSelect
                    label="Type"
                    name="type"
                    values={[
                        { label: "Annonce", value: "ANNONCE" },
                        { label: "Covoiturage", value: "COVOITURAGE" },
                    ]}
                />

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.type !== currentValues.type
                    }
                >
                    {({ getFieldValue }: any) => {
                        return getFieldValue("type") === "ANNONCE" ? (
                            <OZInputNumber label="Prix" name="price" />
                        ) : (
                            <></>
                        );
                    }}
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.type !== currentValues.type
                    }
                >
                    {({ getFieldValue }: any) => {
                        return getFieldValue("type") === "COVOITURAGE" ? (
                            <>
                                <OZInput label="Départ" name="departure" />
                                <OZInput label="Arrivée" name="arrival" />
                                <OZDatePicker
                                    label="Date de départ"
                                    name="date"
                                    style={{ width: "100%" }}
                                />
                                <OZTimePicker
                                    label="Heure de départ"
                                    name="time"
                                    style={{ width: "100%" }}
                                />
                                <OZInputNumber
                                    label="Nombre de passagers"
                                    name="passengers"
                                />
                            </>
                        ) : (
                            <></>
                        );
                    }}
                </Form.Item>

                <OZTextEditor label="Description" name="description" />
                <Button type="primary">Publier</Button>
            </Form>
        </Card>
    );
};

export default New;
