import React from "react";
import {
    Avatar,
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    PageHeader,
    Steps,
    Row,
} from "antd";
import styled from "styled-components";

import { OZTableColumnType } from "helpers/types";

import OZTable from "components/OZTable";
import OZStatus from "components/OZStatus";

// Assets
import AVATAR from "assets/images/avatar.png";

const { Step } = Steps;

const Order = () => {
    const columns = useColumns();
    const footer = useFooter();

    return (
        <Row gutter={[16, 16]}>
            <Col xs={{ span: 24, order: 2 }} lg={{ span: 17, order: 1 }}>
                <Card bordered={false}>
                    <StyledPageHeader
                        title="Commande #268"
                        subTitle={<OZStatus type="pending" />}
                    >
                        <Descriptions
                            colon={false}
                            column={3}
                            layout="vertical"
                            size="small"
                        >
                            <Descriptions.Item label="Date de la commande">
                                20/12/2019
                            </Descriptions.Item>
                            <Descriptions.Item label="Commandé par">
                                <StyledUser>
                                    <Avatar src={AVATAR} size={18} /> Steven Persia
                                </StyledUser>
                            </Descriptions.Item>
                            <Descriptions.Item label="Type de paiement">
                                PayPal
                            </Descriptions.Item>
                        </Descriptions>
                    </StyledPageHeader>

                    <Divider />

                    <Card bordered={false}>
                        <Steps progressDot current={1}>
                            <Step title="Reçue" description="21-12-2020" />
                            <Step title="Payée" description="30-12-2020" />
                            <Step title="Expédiée" description="En attente" />
                        </Steps>
                    </Card>

                    <Divider />

                    <OZTable
                        columns={columns}
                        data={data}
                        footer={footer}
                        pagination={false}
                    />
                </Card>
            </Col>

            <Col xs={{ span: 24, order: 1 }} lg={{ span: 7, order: 2 }}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Button>Imprimer la facture</Button>
                        <Button>Rembourser</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const data = [
    {
        key: "id01",
        product: "Place pour Disneyland Paris",
        quantity: 3,
        price: 23.3,
    },
    {
        key: "id02",
        product: "Place pour Disneyland Paris",
        quantity: 5,
        price: 10.5,
    },
];

const useColumns = () => {
    const columns: OZTableColumnType[] = [
        {
            title: "Produit",
            dataIndex: "product",
            key: "product",
        },
        {
            title: "Prix",
            key: "price",
            align: "right",
            render: (p: any) => `${p.price}€`,
        },
        {
            title: "Quantité",
            dataIndex: "quantity",
            key: "quantity",
            align: "right",
        },
        {
            title: "Total",
            key: "total",
            align: "right",
            render: (p: any) => `${p.quantity * p.price}€`,
        },
    ];

    return columns;
};

const useFooter = () => {
    const renderTotal = (data: any) => {
        let total = 0;

        for (let i = 0; i < data.length; i++) total += data[i].quantity * data[i].price;

        return <div style={{ textAlign: "right" }}>{`Total : ${total}€`}</div>;
    };

    return renderTotal;
};

const StyledPageHeader = styled(PageHeader)`
    padding: 0;
`;

const StyledUser = styled.div`
    display: grid;
    grid: auto / auto 1fr;
    grid-gap: 4px;
    align-items: center;
`;

export default Order;
