import { Operator, pipe, tryCatch } from "overmind";
import * as o from "./operators";
import * as oCommon from "store/operators";

const COMMON_ERROR_MESSAGE = "Une erreur est survenue.";

export const getBox: Operator<string> = tryCatch({
    try: o.getBox(),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const getBoxes: Operator = pipe(
    oCommon.setIsLoading(true),
    o.getBoxes(),
    oCommon.setIsLoading(false)
);

export const createBox: Operator<string> = tryCatch({
    try: pipe(
        o.createBox(),
        o.getBoxes(),
        oCommon.toast({ status: "success", text: "Boîte créée." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const updateBox: Operator<any> = tryCatch({
    try: pipe(
        o.updateBox(),
        o.getBoxes(),
        oCommon.toast({ status: "success", text: "Boîte mise à jour." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const answerBox: Operator<any> = tryCatch({
    try: pipe(
        o.answerBox(),
        o.getBoxes(),
        oCommon.toast({ status: "success", text: "Réponse envoyée." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const deleteBox: Operator<string> = tryCatch({
    try: pipe(
        o.deleteBox(),
        o.getBoxes(),
        oCommon.toast({ status: "success", text: "Boîte supprimée." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const resetSelectedBox: Operator = o.resetSelectedBox();
