import React from "react";
import { Form, Select } from "antd";
import { OZSelectType } from "helpers/types";

const { Option } = Select;

const OZSelect = ({
    defaultValue,
    disabled,
    label,
    name,
    placeholder,
    size = "large",
    rules,
    values = [],
}: OZSelectType) => {
    return (
        <Form.Item name={name} label={label} rules={rules}>
            <Select
                defaultValue={defaultValue}
                disabled={disabled}
                placeholder={placeholder}
                size={size}
            >
                {values.map((v: any, i: number) => (
                    <Option key={i} value={v.value}>
                        {v.label}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default OZSelect;
