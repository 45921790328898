import React, { useState } from "react";
import { Avatar, Button, Col, Typography, Row } from "antd";
import styled from "styled-components";
import { useOvermind } from "store";

const { Text, Title } = Typography;

const Event = ({ content }: any) => {
    const [readMore, setReadMore] = useState(false);
    const { state } = useOvermind();
    const { primaryColor } = state.settings.general;

    return (
        <StyledEvent>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={3} lg={3}>
                    <Row
                        gutter={[8, 8]}
                        justify="start"
                        align="middle"
                        style={{ textAlign: "center" }}
                    >
                        <Col md={24} lg={24}>
                            <Avatar
                                shape="square"
                                size={64}
                                style={{ backgroundColor: primaryColor }}
                            >
                                {content.startDate.slice(0, 5)}
                            </Avatar>
                        </Col>

                        <Col md={24} lg={24}>
                            <span>AU</span>
                        </Col>

                        <Col md={24} lg={24}>
                            <Avatar
                                shape="square"
                                size={64}
                                style={{ backgroundColor: primaryColor }}
                            >
                                {content.endDate.slice(0, 5)}
                            </Avatar>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={21} lg={21}>
                    <Head>
                        <StyledTitle level={4}>{content.name}</StyledTitle>
                        <Text type="secondary">
                            {`du ${content.startDate} à ${content.startTime} au ${content.endDate} à ${content.endTime}`}
                            {content.category && ` - ${content.category.name}`}
                        </Text>
                    </Head>
                    <Content
                        dangerouslySetInnerHTML={{
                            __html: readMore
                                ? content.description
                                : content.description.slice(0, 600),
                        }}
                    />
                    {content.description.length > 600 && (
                        <Button
                            onClick={() => setReadMore(!readMore)}
                            type="primary"
                            ghost
                        >
                            {readMore ? "Réduire le contenu" : "Lire la suite"}
                        </Button>
                    )}
                </Col>
            </Row>
        </StyledEvent>
    );
};

const StyledEvent = styled.div``;

const Head = styled.div`
    margin-bottom: 16px;
`;

const StyledTitle = styled(Title)`
    margin-bottom: 0 !important;
`;

const Content = styled.div`
    text-align: justify;
    padding-bottom: 8px;
`;

export default Event;
