import { Operator, mutate, run } from "overmind";

export const getCategories: () => Operator<string> = () =>
    mutate(async function getCategories({ effects, state }, type) {
        const res = await effects.get(`categories/${type}`);
        state.categories[type].length = res.length;
        state.categories[type].list = res.categories;
    });

export const createCategory: () => Operator<any> = () =>
    run(async function createCategory({ effects }, props) {
        await effects.post({ url: `/categories/${props.type}`, data: props.data });
    });

export const updateCategory: () => Operator<any> = () =>
    run(async function updateCategory({ effects }, props) {
        await effects.put({
            url: `/categories/${props.type}/${props.id}`,
            data: props.data,
        });
    });

export const deleteCategory: () => Operator<any> = () =>
    run(async function deleteCategory({ effects }, props) {
        await effects.del(`/categories/${props}`);
    });
