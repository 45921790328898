import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Badge, Button, Layout, Menu, Tooltip } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useOvermind } from "store";
import AnnounceKit from "announcekit-react";

// Modules
import useModules, {
    ABox,
    ABoxes,
    ACalendar,
    ADocument,
    ADocuments,
    AEvent,
    AMedia,
    AMedias,
    AOffer,
    AOffers,
    AOrder,
    AOrders,
    APost,
    APosts,
    AProduct,
    AShop,
    ASubvention,
    ASubventions,
} from "modules";

// Views
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import Users from "./Users";
import User from "./Users/User";
import Tenders from "./Tenders";
import Requests from "./Requests";

// Components
import OZResult from "components/OZResult";

// Blocks
import PageFooter from "blocks/PageFooter";

const { Content, Sider } = Layout;
const { SubMenu } = Menu;
const ButtonGroup = Button.Group;

const Admin = () => {
    const { actions, state } = useOvermind();
    const [collapsedMenu, setCollapsedMenu] = useState(false);
    const [notifications, setNotifications] = useState({
        requests: false,
        subventions: false,
    });
    const history = useHistory();
    const modules = useModules();
    let { pathname } = useLocation();

    const { user } = state;
    const { appName, logo } = state.settings.general;

    useEffect(() => {
        (window as any).$crisp = [];
        (window as any).CRISP_WEBSITE_ID = "b3d532ad-154c-4714-8e5d-19d03805dc6a";

        (function () {
            var d = document;
            var s = d.createElement("script");

            s.src = "https://client.crisp.chat/l.js";
            (s as any).async = 1;
            d.getElementsByTagName("head")[0].appendChild(s);
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getNotif();
    }, [pathname]);

    const getNotif = async () => {
        const res: any = await actions.getNotifications();
        return setNotifications(res);
    };

    return (
        <StyledLayout>
            <StyledSider
                breakpoint="lg"
                trigger={null}
                collapsible
                collapsed={collapsedMenu}
                onBreakpoint={(e) => setCollapsedMenu(e)}
            >
                <Logo>
                    {logo.url ? (
                        <>
                            <img alt={appName} src={logo.url} height={40} />{" "}
                            <span>{appName}</span>
                        </>
                    ) : (
                        <span>{appName}</span>
                    )}
                </Logo>
                <Menu theme="dark" defaultSelectedKeys={[pathname]}>
                    <Item key={`/admin/`} onClick={() => history.push(`/admin/`)}>
                        <i className="ri-dashboard-fill" />
                        <span>Accueil</span>
                    </Item>

                    {modules
                        .filter((m) => m.status)
                        .map((m, i) =>
                            m.subMenu ? (
                                <SubMenu
                                    key={`/admin${m.path}`}
                                    title={
                                        <span>
                                            {m.icon}
                                            <span>{m.label}</span>
                                        </span>
                                    }
                                >
                                    {m.subMenu.map((c, j) => (
                                        <Item
                                            key={`/admin${c.path}`}
                                            onClick={() =>
                                                history.push(`/admin${c.path}`)
                                            }
                                        >
                                            {c.label}
                                        </Item>
                                    ))}
                                </SubMenu>
                            ) : (
                                <Item
                                    key={`/admin${m.path}`}
                                    onClick={() => history.push(`/admin${m.path}`)}
                                >
                                    <Badge
                                        dot={
                                            m.label === "Subventions" &&
                                            notifications.subventions
                                        }
                                    >
                                        {m.icon}
                                    </Badge>
                                    <span>{m.label}</span>
                                </Item>
                            )
                        )}

                    {/*<Item
                        key={`/admin/tenders`}
                        onClick={() => history.push(`/admin/tenders`)}
                    >
                        <i className="ri-chat-upload-fill"/>
                        <span>Appel d'offres</span>
                    </Item>*/}

                    <Item
                        key={`/admin/users`}
                        onClick={() => history.push(`/admin/users`)}
                    >
                        <i className="ri-group-2-fill" />
                        <span>Utilisateurs</span>
                    </Item>

                    <Item
                        key={`/admin/requests`}
                        onClick={() => history.push(`/admin/requests`)}
                    >
                        <Badge dot={notifications.requests}>
                            <i className="ri-feedback-fill" />
                        </Badge>
                        <span>Requêtes</span>
                    </Item>

                    <Item
                        key={`/admin/settings`}
                        onClick={() => history.push(`/admin/settings`)}
                    >
                        <i className="ri-settings-4-fill" />
                        <span>Réglages</span>
                    </Item>

                    <Item>
                        <a
                            href="https://digitalcse.gitbook.io/documentation/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="ri-questionnaire-fill" />
                            <span>Besoin d'aide ?</span>
                        </a>
                    </Item>
                </Menu>
            </StyledSider>
            <Layout>
                <StyledHeader>
                    <Button className="headwayapp-trigger">
                        Nouveautés{" "}
                        <AnnounceKit
                            catchClick=".headwayapp-trigger"
                            widget="https://announcekit.app/widget/y5MWY"
                        />
                    </Button>

                    <ButtonGroup>
                        <Button onClick={() => history.push("/")}>Voir le site</Button>
                        <Tooltip placement="bottomRight" title="Mon profil">
                            <Button
                                onClick={() => history.push(`/admin/users/${user._id}`)}
                            >
                                <UserOutlined />
                            </Button>
                        </Tooltip>
                        <Tooltip placement="bottomRight" title="Se déconnecter">
                            <Button onClick={() => actions.logout()}>
                                <LogoutOutlined />
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </StyledHeader>

                <Content style={{ margin: "24px 16px 0" }}>
                    <Switch>
                        <Route exact path="/admin">
                            <Dashboard />
                        </Route>

                        <Route exact path="/admin/posts">
                            <APosts />
                        </Route>

                        <Route path="/admin/posts/:id">
                            <APost />
                        </Route>

                        <Route exact path="/admin/boxes">
                            <ABoxes />
                        </Route>

                        <Route exact path="/admin/boxes/:id">
                            <ABox />
                        </Route>

                        <Route exact path="/admin/calendar">
                            <ACalendar />
                        </Route>

                        <Route path="/admin/calendar/:id">
                            <AEvent />
                        </Route>

                        <Route exact path="/admin/documents">
                            <ADocuments />
                        </Route>

                        <Route path="/admin/documents/:id">
                            <ADocument />
                        </Route>

                        <Route exact path="/admin/medias">
                            <AMedias />
                        </Route>

                        <Route path="/admin/medias/:id">
                            <AMedia />
                        </Route>

                        <Route exact path="/admin/offers">
                            <AOffers />
                        </Route>

                        <Route path="/admin/offers/:id">
                            <AOffer />
                        </Route>

                        <Route exact path="/admin/subventions">
                            <ASubventions />
                        </Route>

                        <Route path="/admin/subventions/:id">
                            <ASubvention />
                        </Route>

                        <Route exact path="/admin/shop/products">
                            <AShop />
                        </Route>

                        <Route path="/admin/shop/products/:id">
                            <AProduct />
                        </Route>

                        <Route exact path="/admin/shop/orders">
                            <AOrders />
                        </Route>

                        <Route path="/admin/shop/orders/:id">
                            <AOrder />
                        </Route>

                        <Route exact path="/admin/tenders">
                            <Tenders />
                        </Route>

                        <Route exact path="/admin/users">
                            <Users />
                        </Route>

                        <Route path="/admin/users/:id">
                            <User />
                        </Route>

                        <Route exact path="/admin/requests">
                            <Requests />
                        </Route>

                        <Route exact path="/admin/settings">
                            <Settings />
                        </Route>

                        <Route>
                            <OZResult status="500" />
                        </Route>
                    </Switch>
                    <PageFooter />
                </Content>
            </Layout>
        </StyledLayout>
    );
};

const StyledLayout = styled(Layout)`
    height: 100%;
`;

const StyledHeader = styled.div`
    display: grid;
    grid: auto / auto auto;
    grid-gap: 8px;
    align-items: center;
    justify-content: flex-end;
    background: #fff;
    padding: 9px 16px;

    .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }

    .trigger:hover {
        color: #1890ff;
    }

    .headwayapp-trigger a {
        padding-left: 14px;
        top: -2px;
    }
`;

const Logo = styled.div`
    color: rgb(255, 255, 255);
    font-size: 15px;
    background: rgba(255, 255, 255, 0.1);
    padding: 14px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: -1px;
    font-style: italic;
    align-items: center;
    grid-gap: 10px;
    display: grid;
`;

const StyledSider = styled(Sider)<{ collapsed: boolean }>`
    ${Logo} {
        grid: ${(p) => (p.collapsed ? "auto / auto" : "auto / auto 1fr")};
        justify-content: ${(p) => (p.collapsed ? "center" : "flex-start")};

        span {
            display: ${(p) => (p.collapsed ? "none" : "inherit")};
        }
    }

    .ant-menu-inline-collapsed > .ant-menu-item {
        padding: 0 29px !important;
    }

    .ant-menu-inline-collapsed > .ant-menu-item span {
        display: none;
    }
`;

const Item = styled(Menu.Item)`
    display: grid;
    grid: auto / auto 1fr;
    grid-gap: 8px;
    align-items: center;

    a {
        display: grid;
        grid: auto / auto 1fr;
        grid-gap: 8px;
        align-items: center;
    }

    i {
        font-size: 24px;
    }
`;

export default Admin;
