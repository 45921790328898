import React, { useEffect, useState } from "react";
import { Button, Card, Col, List, Row, Select } from "antd";
import styled from "styled-components";
import OZLoadMore from "components/OZLoadMore";
import OZCarousel from "../../../components/OZCarousel";
import OZModal from "../../../components/OZModal";
import { useOvermind } from "../../../store";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

type ImageType = {
    src?: string;
};

const Medias = () => {
    const { actions, state } = useOvermind();
    const { list } = state.medias;
    const { isLoading } = state;
    const categories = state.categories.media.list;

    const [visible, setVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({ category: "" });
    const [results, setResults] = useState<any>([]);
    const [selected, setSelected] = useState({ name: "", pictures: [] });

    const gridStyle: any = {
        width: "100%",
        textAlign: "center",
    };

    useEffect(() => {
        actions.medias.getMedias();
        actions.categories.getCategories("media");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setResults(list);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.length > 0]);

    const search = ({ category }: any) => {
        let newItems = [...list];

        if (category) {
            newItems = newItems.filter((i: any) => i.category._id.includes(category));
        }

        setResults(newItems);
        setCurrentPage(1);
    };

    const header = (
        <Row align="middle" gutter={[8, 8]} justify="space-around">
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                {results.length} galeries trouvées
            </Col>
            <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
                <Select
                    defaultValue=""
                    onChange={(e: any) => setFilters({ ...filters, category: e })}
                    placeholder="Catégories"
                    style={{ width: "100%" }}
                >
                    <Option value="">Toutes les catégories</Option>
                    {categories.map((c: any) => (
                        <Option value={c._id}>{c.name}</Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <Button
                    block
                    onClick={() => {
                        search({
                            category: filters.category,
                        });
                    }}
                    type="primary"
                    icon={<SearchOutlined />}
                >
                    Rechercher
                </Button>
            </Col>
        </Row>
    );

    return (
        <Card>
            <StyledList
                grid={{
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 3,
                    xxl: 3,
                }}
                pagination={{
                    current: currentPage,
                    onChange: (page: any) => setCurrentPage(page),
                    pageSize: 12,
                }}
                header={header}
                dataSource={results}
                loading={isLoading}
                renderItem={(m: any) => {
                    return (
                        <Item
                            onClick={() => {
                                setSelected(m);
                                setVisible(true);
                            }}
                        >
                            <Card.Grid style={gridStyle}>
                                <Image src={m.pictures.length > 0 && m.pictures[0].url}>
                                    <Content>
                                        <Title>{m.name}</Title>
                                    </Content>
                                </Image>
                            </Card.Grid>
                        </Item>
                    );
                }}
            />
            <OZModal
                title={selected.name}
                visible={visible}
                setVisible={setVisible}
                width="70%"
            >
                <OZCarousel>
                    {selected.pictures.map((img: any) => (
                        <ImageCarousel src={img.url} />
                    ))}
                </OZCarousel>
            </OZModal>
        </Card>
    );
};

const Image = styled.div<ImageType>`
    display: grid;
    grid: auto / 1fr;
    grid-gap: 8px;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 160px;
    background: #364d79;
    overflow: hidden;
    color: #fff;
    padding: 8px;
    background-image: url(${(p) => p.src});
    background-position: center;
    background-size: cover;
`;

const ImageCarousel = styled.div<ImageType>`
    background: #10161a;
    min-height: 300px;
    height: 600px;
    background-image: url(${(p) => p.src});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`;

const Content = styled.div`
    background: rgba(0, 0, 0, 0.3);
    display: grid;
    align-items: center;
    height: 100%;
`;

const Title = styled.div`
    font-size: 16px;
`;

const StyledList = styled(List)`
    .ant-list-header {
        margin-bottom: 13px;
    }
`;

const Item = styled(List.Item)`
    cursor: pointer;

    &:hover {
        background: #fafafa;
    }

    .ant-empty-image {
        display: grid;
        align-items: center;
        height: 100%;
    }

    .ant-empty-image span {
        height: 100%;
    }
`;

export default Medias;
