import React, { useEffect } from "react";
import { useOvermind } from "store";
import { useHistory, useParams } from "react-router-dom";
import format from "moment";

// Components
import OZInput from "components/OZInput";
import OZTextEditor from "components/OZTextEditor";

// Templates
import BasicForm from "templates/BasicForm";

type ParamsType = {
    id?: string;
};

const Document = () => {
    const { actions, state } = useOvermind();
    const history = useHistory();
    const params: ParamsType = useParams();
    const { id = "" } = params;
    const sideFields = useFields(id);
    const { selected } = state.documents;

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        await actions.setIsFetching(true);
        if (id && id !== "new") await actions.documents.getDocument(id);
        await actions.categories.getCategories("document");
        actions.setIsFetching(false);
    };

    const onSubmit = async (data: any) => {
        if (id === "new") await actions.documents.createDocument(data);
        else await actions.documents.updateDocument({ id, data });
        history.push("/admin/documents");
    };

    const onDelete = async () => {
        id && (await actions.documents.deleteDocument(id));
        history.push("/admin/documents");
    };

    const initialValues = {
        name: id !== "new" ? selected.name : "",
        description: id !== "new" ? selected.description : "",
        createdAt: id !== "new" ? format(selected.createdAt) : format(new Date()),
        category: id !== "new" && selected.category ? selected.category._id : "",
    };

    return (
        <BasicForm
            deleteText="ce document"
            id={id}
            initialValues={initialValues}
            mainFields={
                <>
                    <OZInput
                        label="Nom du document"
                        name="name"
                        placeholder="Nom"
                        rules={validationRules.name}
                    />
                    <OZTextEditor label="Description du document" name="description" />
                </>
            }
            onDelete={onDelete}
            onSubmit={onSubmit}
            sideFields={sideFields}
        />
    );
};

const useFields = (id: string) => {
    const { state } = useOvermind();
    const { selected } = state.documents;
    const { document } = state.categories;

    const selectCategories = (data: any) => {
        const categories: any[] = [];
        data && data.map((c: any) => categories.push({ label: c.name, value: c._id }));
        return categories;
    };

    const sideFields = [
        {
            name: "category",
            defaultValue: selected.category && selected.category._id,
            label: "Catégorie",
            values: selectCategories(document.list),
            rules: validationRules.category,
            type: "select",
        },
        {
            name: "createdAt",
            disabled: true,
            label: "Date de publication",
            style: { width: "100%" },
            type: "date",
        },
    ];

    return sideFields;
};

const validationRules = {
    name: [{ required: true, message: "Veuillez indiquer un nom." }],
    category: [{ required: true, message: "Veuillez sélectionner une catégorie." }],
};

export default Document;
