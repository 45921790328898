import React, { useState } from "react";
import { Badge, Button, Col, List } from "antd";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";

const Sidebar = () => {
    const history = useHistory();
    const location = useLocation();
    const [selected, setSelected] = useState(
        (location.state && location.state.id) || (location.hash === "messages" && "inbox")
    );

    const leftList = [
        { id: "inbox", text: "Boîte de réception", newCount: 2 },
        { id: "draft", text: "Brouillons" },
        { id: "archive", text: "Archivés" },
    ];

    return (
        <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
            <List
                bordered
                header={
                    <StyledHeader>
                        <Button
                            onClick={() => {
                                history.push({
                                    pathname: `/messages/new`,
                                    state: { id: "" },
                                });
                            }}
                            icon={<PlusOutlined />}
                            type="primary"
                        >
                            Message
                        </Button>
                        <Button icon={<SyncOutlined />} />
                    </StyledHeader>
                }
                dataSource={leftList}
                size="small"
                renderItem={({ id, text, newCount }: any) => (
                    <StyledItem
                        isSelected={selected === id}
                        onClick={async () => {
                            history.push({ pathname: `/messages`, state: { id } });
                            await setSelected(id);
                        }}
                    >
                        {text} <Badge count={newCount} />
                    </StyledItem>
                )}
            />
        </Col>
    );
};

// Styles
const StyledHeader = styled.div`
    display: grid;
    grid: auto / 1fr auto;
    grid-gap: 8px;
`;

const StyledItem = styled(List.Item)<{ isSelected: boolean }>`
    display: grid;
    grid: auto / 1fr auto;
    grid-gap: 8px;
    align-items: center;
    font-weight: ${({ isSelected }) => (isSelected ? "bold" : "normal")};
    cursor: pointer;
`;

export default Sidebar;
