import React from "react";
import { Alert } from "antd";

const OZAlert = ({ banner = true, closable = true, message, type, ...props }: any) => {
    return (
        <Alert
            banner={banner}
            closable={closable}
            message={message}
            type={type}
            {...props}
        />
    );
};

export default OZAlert;
