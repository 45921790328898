import { AsyncAction, Operator, pipe, tryCatch } from "overmind";
import * as o from "./operators";
import * as oCommon from "store/operators";

const COMMON_ERROR_MESSAGE = "Une erreur est survenue.";

export const getRequests: Operator = pipe(
    oCommon.setIsLoading(true),
    o.getRequests(),
    oCommon.setIsLoading(false)
);

export const createRequest: AsyncAction<any> = async ({ effects }, data) => {
    return await effects.post({ url: "/requests", data });
};

export const deleteRequest: Operator<string> = tryCatch({
    try: pipe(
        o.deleteRequest(),
        o.getRequests(),
        oCommon.toast({ status: "success", text: "Requête traitée." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});
