import React, { useEffect, useState } from "react";
import { Button, Divider, Popconfirm } from "antd";
import { EditOutlined, DeleteTwoTone, InfoCircleTwoTone } from "@ant-design/icons";
import { useOvermind } from "store";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";

// Components
import OZPageHeader from "components/OZPageHeader";
import OZTable from "components/OZTable";
import OZDrawer from "components/OZDrawer";
import OZUploadFile from "components/OZUploadFile";

import { pluralNames, truncateText } from "helpers";
import { OZTableColumnType } from "helpers/types";
import useSearch from "helpers/useSearch";

import BasicCategories from "templates/BasicCategories";

const Posts = () => {
    const { actions, state } = useOvermind();
    const [visible, setVisible] = useState(false);
    const history = useHistory();
    const columns = useColumns();
    const { posts } = state;
    const { post } = state.categories;

    useEffect(() => {
        actions.posts.getPosts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const quantity = pluralNames({ length: posts.list.length, text: "article" });

    return (
        <Container>
            <OZPageHeader
                title="Articles"
                quantity={quantity}
                primaryAction={() => history.push("/admin/posts/new")}
                primaryText="Ajouter un article"
                secondaryAction={() => setVisible(true)}
            />
            <OZTable columns={columns} data={posts.list} />
            <OZDrawer
                title="Catégories des articles"
                onClose={() => setVisible(false)}
                visible={visible}
                width={400}
            >
                <BasicCategories
                    categories={post.list}
                    getElements={actions.posts.getPosts}
                    type="post"
                />
            </OZDrawer>
        </Container>
    );
};

const useColumns = () => {
    const { actions } = useOvermind();
    const history = useHistory();

    const columns: OZTableColumnType[] = [
        {
            title: "Image",
            width: 80,
            key: "picture",
            render: (p: any) => (
                <OZUploadFile
                    action={actions.posts.updatePost}
                    id={p._id}
                    initialFile={p.picture}
                    pathName="posts"
                    sizeLimit={3}
                    type="picture"
                />
            ),
        },
        {
            title: "Date",
            width: 120,
            key: "date",
            render: (p: any) => format(new Date(p.createdAt), "dd/MM/yyyy"),
        },
        {
            title: "Titre",
            dataIndex: "name",
            render: (t: any) => truncateText(t, 70),
            ...useSearch("name"),
        },
        {
            title: "Catégorie",
            key: "category",
            render: (p: any) => p.category && p.category.name,
        },
        {
            title: "Actions",
            key: "actions",
            align: "right",
            fixed: "right",
            render: (p: any, i: any) => (
                <span key={i}>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => history.push(`/admin/posts/${p._id}`)}
                    />
                    <Divider type="vertical" />
                    <Popconfirm
                        title="Êtes-vous sûr(e) de vouloir supprimer cet article ?"
                        onConfirm={async () => {
                            await actions.posts.deletePost(p._id);
                            p.picture &&
                                p.picture.fileName &&
                                (await actions.deleteFiles({
                                    fileName: p.picture.fileName,
                                }));
                        }}
                        icon={<InfoCircleTwoTone twoToneColor="red" />}
                        placement="left"
                        okText="Supprimer"
                        okType="danger"
                        cancelText="Annuler"
                    >
                        <Button
                            ghost
                            icon={<DeleteTwoTone twoToneColor="red" />}
                            type="danger"
                        />
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return columns;
};

const Container = styled.div`
    padding: 24px;
    background: #fff;
`;

export default Posts;
