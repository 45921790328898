import React, { useState } from "react";
import { message, Tooltip, Upload } from "antd";
import { useOvermind } from "store";
import { OZUploadFileType } from "helpers/types";
import { FileOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";

const OZUploadFile = ({
    action,
    id,
    initialFile,
    pathName = "",
    sizeLimit = 1,
    type = "picture",
}: OZUploadFileType) => {
    const { actions } = useOvermind();
    const [file, setFile] = useState(initialFile || { fileName: "", url: "" });

    const handleAction = async (e: any) => {
        message.loading("Envoi en cours...");

        const formData = new FormData();
        formData.append("pathName", pathName);
        formData.append("upload", e);

        if (file.url) await actions.deleteFiles({ fileName: file.fileName });
        const res: any = await actions.uploadFiles(formData);

        const hasPROTOCOL = res[0].location.includes("https://");
        const url = hasPROTOCOL ? res[0].location : `https://${res[0].location}`;
        const fileName = res[0].key;

        if (pathName === "settings") await action({ [type]: { fileName, url } });
        else await action({ id, data: { [type]: { fileName, url } } });

        setFile({ fileName, url });

        return " ";
    };

    const beforeUpload = (file: any) => {
        const isTooLarge = file.size / 1024 / 1024 > sizeLimit;
        if (isTooLarge) {
            message.error(`Fichier trop volumineux (max. ${sizeLimit}Mo).`);
            return false;
        }

        if (type === "picture" || type === "logo" || type === "backgroundImage") {
            const isPic = file.type === "image/jpeg" || file.type === "image/png";
            if (!isPic) {
                message.error("Formats acceptés: JPG et PNG.");
                return false;
            }
        } else {
            const isDoc =
                file.type === "image/jpeg" ||
                file.type === "image/png" ||
                file.type === "text/csv" ||
                file.type === "application/msword" ||
                file.type === "application/pdf" ||
                file.type === "application/vnd.oasis.opendocument.presentation" ||
                file.type === "application/vnd.oasis.opendocument.spreadsheet" ||
                file.type === "application/vnd.oasis.opendocument.text" ||
                file.type === "application/vnd.ms-powerpoint" ||
                file.type === "application/vnd.rar" ||
                file.type === "application/rtf" ||
                file.type === "application/x-tar" ||
                file.type === "application/vnd.ms-powerpoint" ||
                file.type === "text/plain" ||
                file.type === "application/vnd.ms-excel" ||
                file.type === "application/zip" ||
                file.type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                file.type ===
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                file.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

            if (!isDoc) {
                message.error("Format non accepté.");
                return false;
            }
        }

        return true;
    };

    return (
        <Tooltip
            placement="right"
            title={file.fileName}
            style={{ display: !file.url ? "none" : "inherit" }}
        >
            <div>
                <StyledUpload
                    listType="picture-card"
                    showUploadList={false}
                    action={handleAction}
                    beforeUpload={beforeUpload}
                >
                    {(type === "picture" ||
                        type === "logo" ||
                        type === "backgroundImage") &&
                        file.url && <img src={file.url} alt={file.fileName} />}
                    {type === "file" && file.url && <FileOutlined />}
                    {!file.url && <PlusOutlined />}
                </StyledUpload>
            </div>
        </Tooltip>
    );
};

const StyledUpload = styled(Upload)`
    & > .ant-upload {
        margin: 0;
        width: 48px;
        height: 48px;
    }

    & > .ant-upload.ant-upload-select-picture-card > .ant-upload {
        padding: 0;
    }

    & > .ant-upload.ant-upload-select-picture-card > .ant-upload img {
        width: 48px;
        height: 48px;
        object-fit: cover;
        border-radius: 2px;
    }
`;

export default OZUploadFile;
