import React from "react";
import { Card, Col, Divider, Row } from "antd";
import { useOvermind } from "store";

import OZForm from "components/OZForm";
import OZActions from "components/OZActions";
import { renderFields } from "helpers";

const BasicForm = ({
    deleteText,
    form,
    id,
    initialValues,
    mainFields,
    onDelete,
    onSubmit,
    sideFields,
}: any) => {
    const { state } = useOvermind();

    return state.isFetching ? (
        <div />
    ) : (
        <OZForm form={form} initialValues={initialValues} onSubmit={onSubmit}>
            <Card>
                <Row gutter={[32, 0]}>
                    <Col xs={{ span: 24, order: 2 }} lg={{ span: 17, order: 1 }}>
                        {mainFields}
                    </Col>
                    <Col xs={{ span: 24, order: 1 }} lg={{ span: 7, order: 2 }}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <OZActions action={onDelete} id={id} text={deleteText} />
                                {sideFields && <Divider />}
                                {sideFields &&
                                    sideFields.map((f: any, i: number) =>
                                        renderFields({
                                            f,
                                            i,
                                        })
                                    )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </OZForm>
    );
};

export default BasicForm;
