import React, { useEffect } from "react";
import { Avatar, Button, Form, List, Divider, Radio } from "antd";
import { useOvermind } from "store";
import styled from "styled-components";

// Modules
import useModules from "modules";

// Components
import OZInput from "components/OZInput";
import OZForm from "components/OZForm";
import OZSwitch from "components/OZSwitch";

const General = () => {
    const { actions, state } = useOvermind();
    const modules = useModules();
    const {
        boxes,
        calendar,
        documents,
        medias,
        offers,
        posts,
        shop,
        subventions,
    } = state.settings.modules;

    const {
        appName,
        logo,
        backgroundImage,
        primaryColor,
        secondaryColor,
    } = state.settings.general;

    const {
        phoneNumber,
        email,
        address,
        availabilities,
    } = state.settings.general.contact;

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        actions.setIsFetching(true);
        await actions.getSettings();
        actions.setIsFetching(false);
    };

    const onSubmit = (data: any) => {
        actions.updateSettings(data);
    };

    const fieldsInfo = [
        {
            name: ["general", "contact", "phoneNumber"],
            icon: <i className="ri-phone-line" />,
            label: "Numero de telephone",
            placeholder: "Numero de telephone",
        },
        {
            name: ["general", "contact", "email"],
            icon: <i className="ri-mail-line" />,
            label: "Adresse email",
            placeholder: "Email",
        },
        {
            name: ["general", "contact", "address"],
            icon: <i className="ri-map-pin-line" />,
            label: "Adresse postale",
            placeholder: "Adresse postale",
        },
        {
            name: ["general", "contact", "availabilities"],
            icon: <i className="ri-time-line" />,
            label: "Heures d'ouvertures",
            placeholder: "Heures d'ouvertures",
        },
    ];

    const fieldsSite = [
        {
            name: ["general", "appName"],
            icon: <i className="ri-window-line" />,
            label: "Nom du site",
            placeholder: "Nom du site",
            rules: validationRules.appName,
        },
    ];

    const initialValues = {
        general: {
            appName: appName,
            logo: logo,
            backgroundImage: backgroundImage,
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
            contact: {
                phoneNumber: phoneNumber,
                email: email,
                address: address,
                availabilities: availabilities,
            },
        },
        modules: {
            boxes: {
                order: boxes.order,
                status: boxes.status,
            },
            calendar: {
                order: calendar.order,
                status: calendar.status,
            },
            documents: {
                order: documents.order,
                status: documents.status,
            },
            medias: {
                order: medias.order,
                status: medias.status,
            },
            offers: {
                order: offers.order,
                status: offers.status,
            },
            posts: {
                order: posts.order,
                status: posts.status,
            },
            shop: {
                order: shop.order,
                status: shop.status,
            },
            subventions: {
                order: subventions.order,
                status: subventions.status,
            },
        },
    };

    return state.isFetching ? (
        <div />
    ) : (
        <OZForm initialValues={initialValues} onSubmit={onSubmit}>
            <h3>Site internet</h3>

            {fieldsSite.map((f, i) => (
                <OZInput
                    key={i}
                    icon={f.icon}
                    label={f.label}
                    name={f.name}
                    placeholder={f.placeholder}
                    rules={f.rules}
                />
            ))}

            <Divider dashed />

            <h3>Informations de contact</h3>

            {fieldsInfo.map((f, i) => (
                <OZInput
                    key={i}
                    icon={f.icon}
                    label={f.label}
                    name={f.name}
                    placeholder={f.placeholder}
                />
            ))}

            <Divider dashed />

            <h3>Modules</h3>
            <List
                itemLayout="horizontal"
                dataSource={modules}
                renderItem={(m) => (
                    <StyledListItem
                        actions={[
                            <OZSwitch
                                defaultChecked={
                                    state.settings.modules[m.name] &&
                                    state.settings.modules[m.name].status
                                }
                                disabled={m.disabled}
                                name={["modules", m.name, "status"]}
                            />,
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Avatar
                                    style={{
                                        backgroundColor: state.settings.modules[m.name]
                                            .status
                                            ? state.settings.general.primaryColor
                                            : "",
                                    }}
                                    icon={m.icon}
                                />
                            }
                            title={m.label}
                            description={m.description}
                        />
                    </StyledListItem>
                )}
            />

            <div style={{ textAlign: "right", marginTop: 30 }}>
                <Button type="primary" size="large" htmlType="submit">
                    Enregistrer
                </Button>
            </div>
        </OZForm>
    );
};

const validationRules = {
    appName: [{ required: true, message: "Veuillez indiquer un nom." }],
};

const StyledListItem = styled(List.Item)`
    .ant-form-item {
        margin-bottom: 0;
    }
`;

export default General;
