import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { useOvermind } from "store";

import OZTable from "components/OZTable";
import OZStatus from "components/OZStatus";

import { OZTableColumnType } from "helpers/types";
import useSearch from "helpers/useSearch";

type ParamsType = {
    id?: string;
};

const AccountSubventions = () => {
    const { actions, state } = useOvermind();
    const columns = useColumns();
    const { id = "" }: ParamsType = useParams();
    const { subventions } = state;

    useEffect(() => {
        actions.subventions.getSubventionsByUser(id);

        return () => {
            actions.subventions.resetSubventions();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <OZTable columns={columns} data={subventions.list} size="small" />;
};

const useColumns = () => {
    const columns: OZTableColumnType[] = [
        {
            title: "Date",
            key: "createdAt",
            render: (s: any) => format(new Date(s.createdAt), "dd/MM/yyyy"),
            ...useSearch("createdAt"),
        },
        {
            title: "Montant",
            key: "amount",
            render: (s: any) => `${s.amount}€`,
        },
        {
            title: "Statut",
            key: "status",
            filters: [
                { text: "Remboursement accepté", value: "ACCEPTED" },
                { text: "Non subventionnable", value: "REJECTED" },
                { text: "En attente", value: "PENDING" },
            ],
            onFilter: (value: any, s: any) => s.status === value,
            render: (s: any) => <OZStatus type={s.status.toLowerCase()} />,
        },
        {
            title: "Type",
            key: "type",
            filters: [
                { text: "Chèque", value: "CHECK" },
                { text: "Virement bancaire", value: "BANKTRANSFER" },
            ],
            onFilter: (value: any, s: any) => s.type === value,
            render: (s: any) => (s.type === "CHECK" ? "Chèque" : "Virement bancaire"),
        },
        {
            title: "Géré par",
            key: "managedBy",
            render: (s: any) =>
                s.managedBy && `${s.managedBy.firstName} ${s.managedBy.lastName}`,
            ...useSearch("managedBy"),
        },
    ];

    return columns;
};

export default AccountSubventions;
