import { Operator, pipe, tryCatch } from "overmind";
import * as o from "./operators";
import * as oCommon from "store/operators";

const COMMON_ERROR_MESSAGE = "Une erreur est survenue.";

export const getUser: Operator<string> = tryCatch({
    try: o.getUser(),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const getUsers: Operator = pipe(
    oCommon.setIsLoading(true),
    o.getUsers(),
    oCommon.setIsLoading(false)
);

export const findUsers: Operator<any> = tryCatch({
    try: pipe(oCommon.setIsLoading(true), o.findUsers(), oCommon.setIsLoading(false)),
    catch: pipe(oCommon.toast({ status: "error" }), oCommon.setIsLoading(false)),
});

export const createUser: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.createUser(),
        o.getUsers(),
        oCommon.toast({ status: "success", text: "Utilisateur créé." }),
        oCommon.setIsLoading(false)
    ),
    catch: pipe(oCommon.toast({ status: "error" }), oCommon.setIsLoading(false)),
});

export const updateUser: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.updateUser(),
        oCommon.toast({ status: "success", text: "Utilisateur mis à jour." }),
        oCommon.setIsLoading(false)
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const updateMyself: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.updateMyself(),
        oCommon.destroyToast(),
        oCommon.toast({ status: "success", text: "Profil mis à jour." }),
        oCommon.setIsLoading(false)
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const deleteUser: Operator<string> = tryCatch({
    try: pipe(
        o.deleteUser(),
        o.getUsers(),
        oCommon.toast({ status: "success", text: "Utilisateur supprimé." })
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const changePassword: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.changePassword(),
        oCommon.toast({
            status: "success",
            text: "Changement de mot de passe pris en compte.",
        }),
        oCommon.setIsLoading(false)
    ),
    catch: pipe(oCommon.toast({ status: "error" }), oCommon.setIsLoading(false)),
});

export const changePicture: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.changePicture(),
        oCommon.toast({ status: "success", text: "Avatar mis à jour." }),
        oCommon.setIsLoading(false)
    ),
    catch: oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
});

export const resetSelectedUser: Operator = o.resetSelectedUser();

export const createMultipleUsers: Operator<any> = tryCatch({
    try: pipe(
        oCommon.setIsLoading(true),
        o.createMultipleUsers(),
        o.getUsers(),
        oCommon.toast({ status: "success", text: "Utilisateurs importés" }),
        oCommon.setIsLoading(false)
    ),
    catch: pipe(
        oCommon.toast({ status: "error", text: COMMON_ERROR_MESSAGE }),
        oCommon.setIsLoading(false)
    ),
});
