import React from "react";
import styled from "styled-components";

// Components
import OZResult from "components/OZResult";

const Tenders = () => {
    return (
        <Container>
            <OZResult status="info" title="En cours de construction" />
        </Container>
    );
};

const Container = styled.div`
    padding: 24px;
    background: #fff;
`;

export default Tenders;
