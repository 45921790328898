import React, { useEffect } from "react";
import { useOvermind } from "store";
import { useHistory, useParams } from "react-router-dom";
import format from "moment";

// Components
import OZInput from "components/OZInput";
import OZTextEditor from "components/OZTextEditor";

// Templates
import BasicForm from "templates/BasicForm";

type ParamsType = {
    id?: string;
};

const Post = () => {
    const { actions, state } = useOvermind();
    const history = useHistory();
    const { id = "" }: ParamsType = useParams();
    const sideFields = useFields();
    const { selected } = state.posts;

    useEffect(() => {
        fetchData();

        return () => {
            actions.posts.resetSelectedPost();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = async () => {
        actions.setIsFetching(true);
        if (id && id !== "new") await actions.posts.getPost(id);
        await actions.categories.getCategories("post");
        actions.setIsFetching(false);
    };

    const onSubmit = async (data: any) => {
        if (id === "new") await actions.posts.createPost(data);
        else await actions.posts.updatePost({ id, data });
        history.push("/admin/posts");
    };

    const onDelete = async () => {
        id && (await actions.posts.deletePost(id));
        selected.picture &&
            selected.picture.fileName &&
            (await actions.deleteFiles({
                fileName: selected.picture.fileName,
            }));
        history.push("/admin/posts");
    };

    const initialValues = {
        name: id !== "new" ? selected.name : "",
        description: id !== "new" ? selected.description : "",
        createdAt: id !== "new" ? format(selected.createdAt) : format(new Date()),
        category: id !== "new" && selected.category ? selected.category._id : "",
        picture: id !== "new" && selected.picture ? selected.picture : "",
    };

    return (
        <BasicForm
            deleteText="cet article"
            id={id}
            initialValues={initialValues}
            mainFields={
                <>
                    <OZInput
                        label="Titre de l'article"
                        name="name"
                        placeholder="Titre"
                        rules={validationRules.title}
                    />
                    <OZTextEditor label="Contenu de l'article" name="description" />
                </>
            }
            onDelete={onDelete}
            onSubmit={onSubmit}
            sideFields={sideFields}
        />
    );
};

const useFields = () => {
    const { state } = useOvermind();
    const { selected } = state.posts;
    const { post } = state.categories;

    const selectCategories = (data: any) => {
        const categories: any[] = [];
        data && data.map((c: any) => categories.push({ label: c.name, value: c._id }));
        return categories;
    };

    const sideFields = [
        {
            name: "category",
            defaultValue: selected.category && selected.category._id,
            label: "Catégorie",
            values: selectCategories(post.list),
            rules: validationRules.category,
            type: "select",
        },
        {
            name: "createdAt",
            disabled: true,
            label: "Date de publication",
            style: { width: "100%" },
            type: "date",
        },
    ];

    return sideFields;
};

const validationRules = {
    title: [{ required: true, message: "Veuillez indiquer un titre." }],
    category: [{ required: true, message: "Veuillez sélectionner une catégorie." }],
};

export default Post;
