import { Operator, mutate, run } from "overmind";

export const getDocument: () => Operator<any> = () =>
    mutate(async function getDocument({ effects, state }, id) {
        state.documents.selected = await effects.get(`documents/${id}`);
    });

export const getDocuments: () => Operator = () =>
    mutate(async function getDocuments({ state, effects }) {
        const res = await effects.get("documents");
        state.documents.length = res.length;
        state.documents.list = res.documents;
    });

export const getDocumentsByCategory: () => Operator<string> = () =>
    mutate(async function getDocumentsByCategory({ state, effects }, category) {
        const res = await effects.get(`/documents/category/${category}`);
        state.documents.length = res.length;
        state.documents.list = res.documents;
    });

export const createDocument: () => Operator<any> = () =>
    run(async function createDocument({ effects }, props) {
        await effects.post({ url: "/documents", data: props });
    });

export const updateDocument: () => Operator<any> = () =>
    run(async function updateDocument({ effects }, props) {
        await effects.put({ url: `/documents/${props.id}`, data: props.data });
    });

export const deleteDocument: () => Operator<any> = () =>
    run(async function deleteDocument({ effects }, id) {
        await effects.del(`documents/${id}`);
    });

export const resetSelectedDocument: () => Operator = () =>
    mutate(async function resetSelectedDocument({ state }) {
        state.documents.selected = {};
    });
