import React, { useState } from "react";
import { Button, Layout, Modal } from "antd";
import styled from "styled-components";
import { useOvermind } from "store";

// Assets
import DigitalLogo from "assets/DigitalLogo";
import Cgu from "./Cgu";

const { Footer } = Layout;

const PageFooter = () => {
    const { state } = useOvermind();
    const [isOpen, setIsOpen] = useState(false);
    const { secondaryColor } = state.settings.general;

    return (
        <StyledFooter>
            <DigitalLogo color={secondaryColor} size={48} />
            <P color={secondaryColor}>
                Réalisé par{" "}
                <a href="https://digitalcse.fr" target="_blank" rel="noopener noreferrer">
                    DigitalCSE
                </a>
            </P>
            <Button type="link" onClick={() => setIsOpen(true)}>
                Conditions Générales d'Utilisation
            </Button>
            <Modal footer={null} onCancel={() => setIsOpen(false)} visible={isOpen}>
                <Cgu />
            </Modal>
        </StyledFooter>
    );
};

const StyledFooter = styled(Footer)`
    padding: 30px 30px;
    text-align: center;
`;

const P = styled.p`
    color: ${(p) => p.color};
    margin: 0;

    a {
        font-weight: 600;
    }
`;

export default PageFooter;
