import React, { useState } from "react";
import { Button, Card, Col, Modal, Row } from "antd";
import styled from "styled-components";
import { useOvermind } from "store";

// Panels
import Lookup from "./Lookup";
import Login from "./Login";
import Register from "./Register";
import ForgotPwd from "./ForgotPwd";
import ResetPwd from "./ResetPwd";
import AccountCreated from "./AccountCreated";

// Assets
import DigitalLogo from "assets/DigitalLogo";
import Cgu from "blocks/Cgu";
import Documentation from "blocks/Documentation";

type BackgroundType = {
    image: string;
};

const Access = () => {
    const { state } = useOvermind();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDocumentation, setIsOpenDocumentation] = useState(false);
    const panels = usePanels();

    const { backgroundImage, secondaryColor } = state.settings.general;

    return (
        <StyledContainer image={backgroundImage.url}>
            <StyledRow align="middle" justify="center">
                <Col xs={24} sm={18} md={10}>
                    <Button
                        block
                        onClick={() => setIsOpenDocumentation(true)}
                        size="large"
                        type="primary"
                        style={{ marginBottom: -3 }}
                    >
                        Besoin d'aide ? Cliquez ici !
                    </Button>
                    <Card bordered={false}>
                        {panels}
                        <Copyright>
                            <DigitalLogo color={secondaryColor} size={24} />
                            <P color={secondaryColor}>
                                Réalisé par{" "}
                                <a
                                    href="https://digitalcse.fr"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    DigitalCSE.fr
                                </a>
                            </P>
                            <Button type="link" onClick={() => setIsOpen(true)}>
                                Conditions Générales d'Utilisation
                            </Button>
                        </Copyright>

                        <Documentation
                            onClose={() => setIsOpenDocumentation(false)}
                            visible={isOpenDocumentation}
                        />

                        <Modal
                            footer={null}
                            onCancel={() => setIsOpen(false)}
                            visible={isOpen}
                        >
                            <Cgu />
                        </Modal>
                    </Card>
                </Col>
            </StyledRow>
        </StyledContainer>
    );
};

const usePanels = () => {
    const [panel, setPanel] = useState("lookup");
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");

    if (panel === "lookup") return <Lookup setPanel={setPanel} setEmail={setEmail} />;
    if (panel === "login") return <Login setToken={setToken} setPanel={setPanel} />;
    if (panel === "register") return <Register email={email} setPanel={setPanel} />;
    if (panel === "forgot-pwd") return <ForgotPwd email={email} setPanel={setPanel} />;
    if (panel === "reset-pwd") return <ResetPwd token={token} setPanel={setPanel} />;
    if (panel === "account-created") return <AccountCreated setPanel={setPanel} />;
};

const StyledRow = styled(Row)`
    height: 100vh;
`;

const StyledContainer = styled.div<BackgroundType>`
    background-image: url(${(p) => p.image});
    background-size: cover;
    background-position-y: bottom;
    background-position-x: center;
    background-repeat: no-repeat;
    background-color: #202b33;
`;

const Copyright = styled.div`
    margin-top: 20px;
    text-align: center;
`;

const P = styled.div`
    color: ${(p) => p.color};

    a {
        font-weight: 600;
    }
`;

export default Access;
