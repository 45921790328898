import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Row } from "antd";
import { useOvermind } from "store";

import AccountSettings from "./AccountSettings";
import AccountSubventions from "./AccountSubventions";
import AccountPassword from "./AccountPassword";

import UserCard from "blocks/UserCard";

type ParamsType = {
    id?: string;
};

const User = ({ isProfileSite }: any) => {
    const { actions, state } = useOvermind();
    const [activeTab, setActiveTab] = useState("account");
    const { id = "" }: ParamsType = useParams();

    const user = state.users.selected;
    const canChange = id === state.user._id;

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const fetchData = async () => {
        actions.setIsFetching(true);
        if (id && id !== "new") await actions.users.getUser(id);
        actions.setIsFetching(false);
    };

    const accountTab = { key: "account", tab: "Paramètres du compte" };
    const passwordTab = { key: "password", tab: "Mot de passe" };
    const subventionsTab = { key: "subventions", tab: "Mes subventions" };

    const adminTabs = [
        accountTab,
        state.settings.modules.subventions.status && subventionsTab,
    ];

    const meAdminTabs = [
        accountTab,
        passwordTab,
        state.settings.modules.subventions.status && subventionsTab,
    ];

    const profileSiteTabs = [
        accountTab,
        passwordTab,
        state.settings.modules.subventions.status && subventionsTab,
    ];

    return state.isFetching ? (
        <div />
    ) : (
        <Row gutter={[16, 0]}>
            {id !== "new" && (
                <Col xs={{ span: 24 }} lg={{ span: 7 }}>
                    <UserCard canChange={canChange} user={user} />
                </Col>
            )}
            <Col xs={{ span: 24 }} lg={id === "new" ? { span: 24 } : { span: 17 }}>
                {id === "new" ? (
                    <Card>
                        <AccountSettings />
                    </Card>
                ) : (
                    <Card
                        bordered={false}
                        tabList={isProfileSite ? profileSiteTabs : adminTabs}
                        activeTabKey={activeTab}
                        onTabChange={(key) => setActiveTab(key)}
                    >
                        {activeTab === "account" && (
                            <AccountSettings isProfileSite={isProfileSite} />
                        )}

                        {activeTab === "password" && <AccountPassword />}
                        {activeTab === "subventions" && <AccountSubventions />}
                    </Card>
                )}
            </Col>
        </Row>
    );
};

export default User;
