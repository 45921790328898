import React from "react";
import { Form, Select } from "antd";
import { OZSelectTagType } from "../helpers/types";

const OZSelectTag = ({ children, label, name, placeholder }: OZSelectTagType) => {
    return (
        <Form.Item label={label} name={name}>
            <Select
                mode="tags"
                style={{ width: "100%" }}
                size="large"
                placeholder={placeholder}
            >
                {children}
            </Select>
        </Form.Item>
    );
};

export default OZSelectTag;
